import clsx from 'clsx'
import Avatar from 'components/Avatar'
import DealTypeChip from 'components/DealTypeChip/DealTypeChip'
import { DEAL_STAGE_CONFIG } from 'constants/deal'
import numeral from 'numeral'
import { Link, useNavigate } from 'react-router-dom'
import { DEAL_STAGE, LeanDeal } from 'types'
import { getCurrentDealStage } from 'utils/deal'

interface IProps {
  deal: LeanDeal
}

export default function DealCard({ deal }: IProps) {
  const navigate = useNavigate()

  const currentStage = getCurrentDealStage(deal)
  const isClosed = currentStage!.stage === DEAL_STAGE.saleClosed

  return (
    <div
      className={clsx(
        'bg-white rounded-2xl p-4 shadow-md cursor-pointer',
        isClosed && 'border border-steel/25 shadow-none'
      )}
      onClick={() => navigate(`/agent/${deal.userId}/deal/${deal.id}`)}
    >
      <div className="flex items-center justify-between mb-2">
        <Link
          className="no-underline"
          onClick={e => e.stopPropagation()}
          to={`/agent/${deal.userId}/contact/${deal.contactId}`}
        >
          <Avatar user={deal.contact!} />
        </Link>
        <DealTypeChip dealType={deal.type} />
      </div>
      <div className="p-1 mb-2 text-xs text-center rounded bg-tan">
        {DEAL_STAGE_CONFIG[currentStage!.stage].name}
      </div>
      <div className="mb-2 text-xs text-off-black/60">
        {currentStage!.address}
      </div>
      <div className="text-xs">
        {numeral(currentStage!.price).format('$0,0')}
      </div>
    </div>
  )
}
