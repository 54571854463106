import clsx from 'clsx'
import numeral from 'numeral'
import { FieldError } from 'react-hook-form'

type Props = {
  value?: number
  onChange: (value: number | null) => void
  error?: FieldError
}

const PriceInput = ({ value, onChange, error }: Props) => (
  <div className="relative">
    <div className="font-medium text-steel absolute left-[16px] top-[50%] transform translate-y-[-50%]">
      $
    </div>
    <input
      aria-invalid={error ? 'true' : 'false'}
      className={clsx(
        'h-10 py-2 pr-4 pl-8 font-normal border rounded-lg outline-none border-steel/35 focus:shadow-sm',
        error && 'border-red'
      )}
      value={value ? numeral(value).format('0,0') : ''}
      onChange={e => onChange(numeral(e.target.value).value())}
    />
  </div>
)

export default PriceInput
