import { useState } from 'react'
import numeral from 'numeral'

import { DEAL_STAGE, User } from 'types'
import { MetricDateFilter } from 'types/metrics'
import { getDealStageCount } from 'utils/deal'
import MetricsFilters from 'components/MetricsFilter/MetricsFilter'
import { useAgentMetrics } from 'hooks/queries/useAgentMetrics'

interface IProps {
  user: User
}

export default function MetricsCard({ user }: IProps) {
  const [dateFilter, setDateFilter] = useState<MetricDateFilter>('YTD')
  const { data } = useAgentMetrics({ userId: user.id, dateFilter })

  return (
    <div className="flex flex-col justify-between px-6 py-5 mb-6 shadow-md rounded-2xl h-36">
      <div className="flex items-center justify-between">
        <div className="font-medium">
          {user.firstName || user.name}s Metrics
        </div>
        <MetricsFilters dateFilter={dateFilter} setDateFilter={setDateFilter} />
      </div>
      <div className="flex items-center justify-between">
        <div className="basis-1/5">
          <div className="text-sm text-steel">Total volume closed</div>
          <div className="text-2xl font-medium">
            {numeral(data?.data.volume[0]?.value).format('$0,0')}
          </div>
        </div>
        <div className="basis-1/5">
          <div className="text-sm text-steel">Total commission</div>
          <div className="text-2xl font-medium">
            {numeral(data?.data.commission[0]?.value).format('$0,0')}
          </div>
        </div>
        <div className="basis-1/5">
          <div className="text-sm text-steel">Deals closed</div>
          <div className="text-2xl font-medium">
            {getDealStageCount(
              DEAL_STAGE.saleClosed,
              data?.data.dealStageCount
            )}
          </div>
        </div>
        <div className="basis-1/5">
          <div className="text-sm text-steel">Appointments set</div>
          <div className="text-2xl font-medium">
            {getDealStageCount(
              DEAL_STAGE.appointmentSet,
              data?.data.dealStageCount
            )}
          </div>
        </div>
        <div className="basis-1/5">
          <div className="text-sm text-steel">Avg commission %</div>
          <div className="text-2xl font-medium">
            {numeral(data?.data.avgCommissionPercentage[0]?.value).format(
              '0.[00]'
            )}
            %
          </div>
        </div>
      </div>
    </div>
  )
}
