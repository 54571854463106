import { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'

interface PanelProps {
  className?: string
}

const Panel: FC<PropsWithChildren<PanelProps>> = ({ children, className }) => (
  <div
    className={clsx('p-10 bg-white rounded-2xl shadow-md w-[426px]', className)}
  >
    {children}
  </div>
)

export default Panel
