import axios, { AxiosRequestConfig } from 'axios'
import { authToken } from '../token'

const instance = async (auth: boolean = true) => {
  let headers = {
    'Content-Type': 'application/json',
  } as any

  if (auth) {
    const token = await authToken()
    headers = {
      ...headers,
      Authorization: token,
    }
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers,
    maxRedirects: 0,
  })
}

export const post = async <T>(
  path: string,
  data: unknown = {},
  config: AxiosRequestConfig = {},
  auth: boolean = true
) => (await instance(auth)).post<T>(path, data, config)

export const get = async <T>(
  path: string,
  data: unknown = {},
  auth: boolean = true
) => (await instance(auth)).get<T>(path, { params: data })

export const put = async (
  path: string,
  data: unknown = {},
  config: AxiosRequestConfig = {},
  auth: boolean = true
) => (await instance(auth)).put(path, data, config)

export const patch = async (
  path: string,
  data: unknown = {},
  auth: boolean = true
) => (await instance(auth)).patch(path, data)

export const del = async (
  path: string,
  data: unknown = {},
  auth: boolean = true
) => (await instance(auth)).delete(path, { params: data })
