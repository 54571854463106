import { yupResolver } from '@hookform/resolvers/yup'
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import 'yup-phone-lite'

import Button from 'components/Button/Button'
import PhoneInput from 'components/Input/PhoneInput'
import TextInput from 'components/Input/TextInput'
import Panel from 'components/Panel/Panel'
import { useAuth } from 'hooks'
import { SignUpForm } from 'types'
import Screen from 'components/Screen/Screen'

export const SignUpFormSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required').email('Invalid email'),
  phone: yup
    .string()
    .phone('US', 'Phone number is invalid')
    .required('Phone number is required'),
})

const defaultValues: SignUpForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

export default function SignUp() {
  const { signUp } = useAuth()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<SignUpForm>({
    resolver: yupResolver(SignUpFormSchema),
    defaultValues,
  })

  const onSubmit: SubmitHandler<SignUpForm> = async (data: SignUpForm) => {
    await signUp(data)
  }

  return (
    <Screen>
      <div className="absolute top-4 right-4">
        Already have an account?{' '}
        <Link
          className="no-underline cursor-pointer text-green visited:text-green"
          to="/signin"
        >
          Sign in
        </Link>
      </div>
      <Panel>
        <h2 className="mb-6 text-[2rem] font-medium">Sign up</h2>

        <form
          noValidate
          className="grid grid-cols-2 gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            control={control as unknown as Control<FieldValues>}
            name="firstName"
            label="First name"
            placeholder="First name"
            required
          />

          <TextInput
            control={control as unknown as Control<FieldValues>}
            name="lastName"
            label="Last name"
            placeholder="Last name"
            required
          />

          <div className="col-span-2">
            <TextInput
              control={control as unknown as Control<FieldValues>}
              name="email"
              label="Email"
              placeholder="Email"
              required
            />
          </div>

          <div className="col-span-2">
            <PhoneInput
              control={control as unknown as Control<FieldValues>}
              name="phone"
              label="Mobile phone"
              placeholder="Mobile phone"
              required
            />
            <div className="mt-2 text-xs text-off-black/70">
              We'll text you a one time sign in code to this number. Message and
              data rates may apply.
            </div>
          </div>

          <div className="col-span-2 text-xs text-off-black/70">
            By clicking "Sign up" below, you are agreeing to our{' '}
            <a
              href="https://assets.getsymba.com/terms-of-service.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>{' '}
            &amp;{' '}
            <a
              href="https://assets.getsymba.com/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy notice
            </a>
            .
          </div>

          <Button type="submit" loading={isSubmitting} className="col-start-2">
            Sign up
          </Button>
        </form>
      </Panel>
    </Screen>
  )
}
