import AcceptInviteModal from 'components/Modal/AcceptInviteModal'
import ManageBillingModal from 'components/Modal/ManageBillingModal'
import { useProfile, useTeam } from 'hooks'
import { Navigate, Outlet, useMatch } from 'react-router-dom'
import {
  getPendingInviteTeams,
  subscriptionIsActive,
  userIsTeamAgent,
} from 'utils/team'

function ProtectedLayout() {
  const { me } = useProfile()
  const { team, teams } = useTeam()
  const onboarding = useMatch('/onboarding/*')

  if (!me) {
    return <Navigate to="/signin" />
  }

  if (!onboarding) {
    // Not on a team yet
    if (!team) {
      // Has active agent subscribtion, show QR code
      if (me.subscription && subscriptionIsActive(me.subscription)) {
        return <Navigate to="/onboarding/agent/complete" />
      }
      // No agent subscription, show account type
      return <Navigate to="/onboarding/account-type" />
    }

    const pendingInviteTeams = getPendingInviteTeams(me, teams)

    // Needs to accept team invite
    if (pendingInviteTeams.length) {
      return (
        <AcceptInviteModal me={me} team={pendingInviteTeams[0]} teams={teams} />
      )
    }

    // On team as an agent, show QR code
    if (userIsTeamAgent(me, team)) {
      return <Navigate to="/onboarding/agent/complete" />
    }

    // Team has not subscribed yet
    if (!team.subscription) {
      return <Navigate to="/onboarding/team/pricing" />
    }

    // Team subscription is inactive
    if (!subscriptionIsActive(team.subscription)) {
      return <ManageBillingModal team={team} me={me} />
    }

    // User has not onboarded yet
    if (!me.isSignedUp) {
      return <Navigate to="/onboarding/profile" />
    }
  }

  return <Outlet />
}

export default ProtectedLayout
