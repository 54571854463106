import TeamLogoIcon from 'assets/icons/TeamLogo'
import clsx from 'clsx'
import { Team } from 'types'

interface ITeamAvatarProps {
  team: Partial<Team>
  size?: number
}

export default function TeamAvatar({ team, size = 32 }: ITeamAvatarProps) {
  const hasLogo = !!team.logoUrl
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full',
        hasLogo ? 'bg-cover bg-center' : 'shadow-md'
      )}
      style={{
        width: size,
        height: size,
        ...(hasLogo
          ? { backgroundImage: `url(${team.logoUrl})` }
          : { backgroundColor: team.color }),
      }}
    >
      {!hasLogo && <TeamLogoIcon size={size * 0.75} />}
    </div>
  )
}
