import { Link } from 'react-router-dom'

import PlusButton from 'components/Button/PlusButton'

export default function GetStartedWidget() {
  return (
    <Link
      className="flex flex-col justify-between p-4 rounded-lg bg-[url('assets/images/bg-get-started.svg')] bg-cover basis-0 grow-[2]"
      to="/invite-team-member"
    >
      <div>
        <div className="text-xs text-green-light">Get started here</div>
        <div className="font-medium text-white">Invite your team</div>
      </div>
      <div className="flex flex-row items-center justify-end">
        <PlusButton />
      </div>
    </Link>
  )
}
