export default function PriceIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.514 7.666L7.57 7.68V5.342C7.122 5.37933 6.77667 5.50067 6.534 5.706C6.30067 5.91133 6.184 6.20067 6.184 6.574C6.184 6.91933 6.30533 7.17133 6.548 7.33C6.79067 7.47933 7.11267 7.59133 7.514 7.666ZM8.564 9.472L8.508 9.458V11.81C8.96533 11.7727 9.32467 11.6513 9.586 11.446C9.85667 11.2407 9.992 10.9607 9.992 10.606C9.992 10.27 9.87067 10.018 9.628 9.85C9.38533 9.682 9.03067 9.556 8.564 9.472ZM8.508 14.414H7.57V13.14C6.618 13.0373 5.834 12.706 5.218 12.146C4.61133 11.5767 4.308 10.816 4.308 9.864L5.876 9.794C5.91333 10.354 6.09067 10.802 6.408 11.138C6.72533 11.4647 7.11267 11.6747 7.57 11.768V9.29L6.842 9.164C6.17 9.04267 5.61933 8.786 5.19 8.394C4.76067 7.99267 4.546 7.428 4.546 6.7C4.546 5.916 4.81667 5.286 5.358 4.81C5.89933 4.32467 6.63667 4.06333 7.57 4.026V2.878H8.508V4.04C9.45067 4.14267 10.1787 4.46933 10.692 5.02C11.2053 5.57067 11.4667 6.28467 11.476 7.162L9.936 7.218C9.91733 6.742 9.77733 6.35 9.516 6.042C9.264 5.72467 8.928 5.51467 8.508 5.412V7.862L9.124 7.974C9.97333 8.12333 10.608 8.40333 11.028 8.814C11.448 9.22467 11.658 9.78467 11.658 10.494C11.658 11.306 11.3547 11.9453 10.748 12.412C10.1507 12.8693 9.404 13.1167 8.508 13.154V14.414Z"
        fill="#A2BFB5"
      />
    </svg>
  )
}
