import { DEAL_TYPE } from './deal'

export type Metric =
  | 'volume'
  | 'volumePerUser'
  | 'dealStageCount'
  | 'leadSourceCount'
  | 'avgCommissionPercentage'
  | 'avgCommissionPercentagePerUser'
  | 'closedDealCountPerUser'
  | 'commission'

export const ALL_METRIC_DATE_FILTERS = ['YTD', 'MTD'] as const

export type MetricDateFilter = typeof ALL_METRIC_DATE_FILTERS[number]

export interface MetricDateRange {
  startDate: string
  endDate: string
}

export interface MetricsRequest {
  metrics: Metric[]
  startDate: string
  endDate: string
  dealType?: DEAL_TYPE
}

export interface MetricValue {
  _id: string | null
  value: number
}

export type MetricsResponse = {
  [key in Metric]: MetricValue[]
}

export const ALL_LEADERBOARD_METRICS = [
  'volumePerUser',
  'closedDealCountPerUser',
  'avgCommissionPercentagePerUser',
] as const

export type LeaderboardMetric = typeof ALL_LEADERBOARD_METRICS[number]
