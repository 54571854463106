import clsx from 'clsx'

interface IOption {
  key: string
  label: string
}

interface IProps {
  value: string
  options: IOption[]
  onChange: (value: string) => void
}

function OptionSelect({ value, options, onChange }: IProps) {
  return (
    <div className="flex">
      {options.map((option, index) => (
        <span
          key={option.key}
          className={clsx(
            'text-sm rounded-full cursor-pointer',
            'px-2 pt-1 pb-1.5',
            {
              'bg-green-light': value === option.key,
              'opacity-70': value !== option.key,
              'ml-2': index > 0,
            }
          )}
          onClick={() => onChange(option.key)}
        >
          {option.label}
        </span>
      ))}
    </div>
  )
}

export default OptionSelect
