import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Team, User } from 'types'

interface IState {
  me: User | null
}

export const { reducer, actions } = createSlice({
  name: 'profile',
  initialState: { me: null } as IState,
  reducers: {
    set: (state, action: PayloadAction<User>) => {
      state.me = action.payload
    },
    clear: state => {
      state.me = null
    },
    updateTeam: (state, action: PayloadAction<Team>) => {
      if (!state.me) {
        return
      }

      const team = action.payload
      state.me.teams = state.me.teams?.map(item =>
        item.id === team.id ? team : item
      )
    },
  },
})

export default reducer
