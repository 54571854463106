import clsx from 'clsx'
import { NavLink, useMatch } from 'react-router-dom'

import DashboardIcon from 'assets/icons/Dashboard'
import LeaderboardIcon from 'assets/icons/Leaderboard'
import logo from 'assets/images/logo.svg'
import { Team, User } from 'types'
import ProfileMenu from './ProfileMenu'
import TeamMembers from './TeamMembers'
import LivePipelineIcon from 'assets/icons/LivePipeline'

interface IProps {
  profile: User | null
  team?: Team
  teams: Team[]
  activeUsers: User[]
  onSetTeam: (id: string) => Promise<void>
  onManageBilling: () => void
  onLogOut: () => void
}

interface NavItem {
  icon: React.FC<{ className: string }>
  title: string
  to: string
}

const navItems: NavItem[] = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    to: '/dashboard',
  },
  {
    icon: LeaderboardIcon,
    title: 'Leaderboard',
    to: '/leaderboard',
  },
  {
    icon: LivePipelineIcon,
    title: 'Live pipeline',
    to: '/pipeline',
  },
]

export default function Header({
  profile,
  team,
  teams,
  activeUsers,
  onSetTeam,
  onManageBilling,
  onLogOut,
}: IProps) {
  const match = useMatch('/onboarding/*')
  const hasProfile = Boolean(profile) && !Boolean(match)

  return (
    <div className="flex items-center h-12 px-6 py-2 bg-white border-0">
      <NavLink to="/">
        <img alt="logo" src={logo} />
      </NavLink>

      {hasProfile && (
        <>
          <div className="items-center hidden gap-6 mr-auto md:flex md:ml-10 lg:ml-20">
            {navItems.map(({ to, title, icon: Icon }) => (
              <NavLink
                key={to}
                to={to}
                className="flex items-center gap-2 no-underline cursor-pointer group"
              >
                {({ isActive }) => (
                  <>
                    <Icon
                      className={
                        isActive
                          ? 'fill-green'
                          : 'fill-steel group-hover:fill-green'
                      }
                    />
                    <span
                      className={clsx(
                        'text-sm',
                        isActive
                          ? 'text-green'
                          : 'text-steel group-hover:text-green'
                      )}
                    >
                      {title}
                    </span>
                  </>
                )}
              </NavLink>
            ))}
          </div>

          {!!team && <TeamMembers activeUsers={activeUsers} />}

          <ProfileMenu
            profile={profile!}
            team={team}
            teams={teams}
            onSetTeam={onSetTeam}
            onManageBilling={onManageBilling}
            onLogOut={onLogOut}
          />
        </>
      )}
    </div>
  )
}
