import {
  AcceptInviteResponse,
  Team,
  TeamBillingResponse,
  ITeamMember,
  TeamRequest,
  InviteTeamMemberRequest,
  UpdateTeamMemberRequest,
  TeamPipelineResponse,
  Deal,
} from 'types'
import { MetricsRequest, MetricsResponse } from 'types/metrics'
import { StripeCheckoutResponse } from 'types/stripe'
import { del, get, patch, post } from './index'

export const getTeam = async (id: string) => get<Team>(`/team/${id}`)

export const createTeam = async (data: TeamRequest) => post<Team>('/team', data)

export const updateTeam = async (id: string, data: TeamRequest) =>
  patch(`/team/${id}`, data)

export const getTeamMetrics = async (id: string, data: MetricsRequest) =>
  post<MetricsResponse>(`/team/${id}/metrics`, data)

export const checkout = async (id: string, priceId: string) =>
  get<StripeCheckoutResponse>(`/team/${id}/checkout`, {
    priceId,
    successUrl: `${process.env.REACT_APP_BASE_URL}/onboarding/team/complete`,
    cancelUrl: `${process.env.REACT_APP_BASE_URL}/onboarding/team/pricing`,
    trialPeriodDays: process.env.REACT_APP_TRIAL_PERIOD_DAYS,
  })

export const billing = (teamId: string) =>
  get<TeamBillingResponse>(`/team/${teamId}/billing`, {
    returnUrl: process.env.REACT_APP_BASE_URL,
  })

export const acceptInvite = async (invitationId: string, token: string) =>
  post<AcceptInviteResponse>(
    '/team/invitation/accept',
    { invitationId, token },
    {},
    false
  )

export const inviteTeamMember = async (
  teamId: string,
  data: InviteTeamMemberRequest
) => post<ITeamMember>(`/team/${teamId}/member`, data)

export const updateTeamMember = async (
  teamId: string,
  memberId: string,
  data: UpdateTeamMemberRequest
) => patch(`/team/${teamId}/member/${memberId}`, data)

export const deleteTeamMember = async (teamId: string, memberId: string) =>
  del(`/team/${teamId}/member/${memberId}`)

export const getTeamPipelineMetrics = async (teamId: string) =>
  get<TeamPipelineResponse[]>(`/team/${teamId}/pipeline/metrics`)

export const getTeamPipelineStage = async (
  teamId: string,
  dealType: string,
  stage: string
) => get<Deal[]>(`/team/${teamId}/pipeline`, { dealType, stage })
