import React from 'react'

interface IChipProps {
  children?: React.ReactNode
}

const Chip: React.FC<IChipProps> = ({ children }: IChipProps) => (
  <div className="px-2 py-1 text-xs font-normal rounded bg-tan text-steel">
    {children}
  </div>
)

export default Chip
