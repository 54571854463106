import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import AsyncSelectInput from 'components/Input/AsyncSelectInput'
import TextInput from 'components/Input/TextInput'
import {
  Control,
  FieldValues,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form'
import { SelectOption, UpdateUserRequest, User } from 'types'
import { loadOptions, makeSelectOption } from 'utils/association'
import * as yup from 'yup'
import UserAviForm from './UserAviForm'

interface IProfileForm {
  avi?: string
  color: string
  firstName: string
  lastName: string
  email: string
  associations: readonly SelectOption[]
}

interface IProfileFormProps {
  me: User | null
  updateMe: (data: UpdateUserRequest) => Promise<void>
  onSuccess: () => void
}

const ProfileFormSchema = yup.object().shape({
  color: yup.string().required('Color is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required').email('Invalid email'),
})

export default function ProfileForm({
  me,
  updateMe,
  onSuccess,
}: IProfileFormProps) {
  const {
    control,
    formState: { isSubmitting },
    getValues,
    setValue,
    handleSubmit,
  } = useForm<IProfileForm>({
    resolver: yupResolver(ProfileFormSchema),
    defaultValues: {
      avi: me?.avi,
      color: me?.color ?? '#99D4FF',
      firstName: me?.firstName,
      lastName: me?.lastName,
      email: me?.email,
      associations: me?.associations?.map(makeSelectOption) ?? [],
    },
  })

  const avi = useWatch({ control, name: 'avi' })
  const color = useWatch({ control, name: 'color' })
  const firstName = useWatch({ control, name: 'firstName' })
  const lastName = useWatch({ control, name: 'lastName' })

  const onSubmit: SubmitHandler<IProfileForm> = async (data: IProfileForm) => {
    await updateMe({
      ...data,
      associations: data.associations.map(option => option.value),
    })
    onSuccess()
  }

  return (
    <form
      noValidate
      className="grid grid-cols-2 gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-span-2">
        <UserAviForm
          user={{
            avi,
            color,
            initials: `${!!firstName ? firstName[0] : ''}${
              !!lastName ? lastName[0] : ''
            }`,
          }}
          onUpload={url => setValue('avi', url)}
          onDelete={() => setValue('avi', undefined)}
        />
      </div>

      {!avi && (
        <div className="col-span-2 mb-2">
          <ColorPicker
            selected={color}
            onSelect={color => setValue('color', color)}
            className="ml-32"
          />
        </div>
      )}

      <TextInput
        control={control as unknown as Control<FieldValues>}
        name="firstName"
        label="First name"
        placeholder="First name"
        required
      />

      <TextInput
        control={control as unknown as Control<FieldValues>}
        name="lastName"
        label="Last name"
        placeholder="Last name"
        required
      />

      <div className="col-span-2">
        <TextInput
          control={control as unknown as Control<FieldValues>}
          name="email"
          label="Email"
          placeholder="Email"
          required
        />
      </div>

      <div className="col-span-2">
        <AsyncSelectInput<SelectOption, true>
          control={control as unknown as Control<FieldValues>}
          name="associations"
          label="Associations"
          placeholder="Search associations..."
          isMulti
          loadOptions={loadOptions}
          defaultValue={getValues('associations')}
        />
      </div>

      <Button
        type="submit"
        loading={isSubmitting}
        className="col-span-1 col-start-2 mt-8"
      >
        Save
      </Button>
    </form>
  )
}
