interface IProps {
  className?: string
}

const AccountIcon = ({ className }: IProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.6924 11.9753C17.5306 11.7253 17.3007 11.4753 17.0026 11.317C16.7641 11.2003 16.6109 11.0087 16.4746 10.7837C16.0403 10.067 16.2957 9.12532 17.0196 8.70032C17.8713 8.22532 18.1438 7.16699 17.6498 6.34199L17.0792 5.35866C16.5938 4.53366 15.5293 4.24199 14.6862 4.72532C13.9367 5.12532 12.9744 4.85866 12.5401 4.15032C12.4038 3.91699 12.3271 3.66699 12.3442 3.41699C12.3697 3.09199 12.2675 2.78366 12.1142 2.53366C11.7991 2.01699 11.2285 1.66699 10.5983 1.66699H9.39754C8.77585 1.68366 8.20525 2.01699 7.89015 2.53366C7.72834 2.78366 7.63466 3.09199 7.65169 3.41699C7.66873 3.66699 7.59208 3.91699 7.45582 4.15032C7.02149 4.85866 6.05914 5.12532 5.31823 4.72532C4.46659 4.24199 3.41057 4.53366 2.91663 5.35866L2.34603 6.34199C1.8606 7.16699 2.13313 8.22532 2.97624 8.70032C3.70013 9.12532 3.95562 10.067 3.5298 10.7837C3.38502 11.0087 3.23173 11.2003 2.99327 11.317C2.70372 11.4753 2.44823 11.7253 2.31197 11.9753C1.99686 12.492 2.0139 13.142 2.329 13.6837L2.91663 14.6837C3.23173 15.217 3.81935 15.5503 4.43253 15.5503C4.72208 15.5503 5.06274 15.467 5.33526 15.3003C5.54817 15.1587 5.80366 15.1087 6.08469 15.1087C6.92781 15.1087 7.63466 15.8003 7.65169 16.6253C7.65169 17.5837 8.43519 18.3337 9.42309 18.3337H10.5813C11.5607 18.3337 12.3442 17.5837 12.3442 16.6253C12.3697 15.8003 13.0766 15.1087 13.9197 15.1087C14.1922 15.1087 14.4477 15.1587 14.6691 15.3003C14.9417 15.467 15.2738 15.5503 15.5719 15.5503C16.1765 15.5503 16.7641 15.217 17.0792 14.6837L17.6754 13.6837C17.982 13.1253 18.0075 12.492 17.6924 11.9753"
      fillOpacity="0.4"
      stroke="transparent"
    />
    <path d="M10.0104 12.3587C8.67337 12.3587 7.5918 11.3171 7.5918 10.0087C7.5918 8.70039 8.67337 7.65039 10.0104 7.65039C11.3475 7.65039 12.4035 8.70039 12.4035 10.0087C12.4035 11.3171 11.3475 12.3587 10.0104 12.3587" />
  </svg>
)

export default AccountIcon
