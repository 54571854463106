import DealIcon from 'assets/icons/Deal'
import { BUYER_DEAL_STAGES, SELLER_DEAL_STAGES } from 'constants/deal'
import { useGetDeal } from 'hooks/queries/useGetDeal'
import numeral from 'numeral'
import { Link, useParams } from 'react-router-dom'
import { DEAL_TYPE } from 'types'
import {
  getCommissionPercentage,
  getCurrentDealStage,
  getGrossCommission,
  getReferralFee,
  getStartDate,
} from 'utils/deal'
import Contact from './Contact'
import DealButton from './DealButton'
import DealStage from './DealStage'
import AgentLink from './AgentLink'
import { useTeam } from 'hooks'
import Button from 'components/Button/Button'
import { getReferralSource } from 'routes/DealCommission/utils'

export default function AgentDeal() {
  const { agentId, dealId } = useParams()
  const { activeUsers } = useTeam()
  const { data, isLoading } = useGetDeal(dealId!, agentId!)

  if (isLoading) {
    return null
  }

  const deal = data?.data!
  const currentStage = getCurrentDealStage(deal)
  const isBuyer = deal.type === DEAL_TYPE.buyer
  const stageNames = isBuyer ? BUYER_DEAL_STAGES : SELLER_DEAL_STAGES
  const agent = activeUsers.find(user => user.id === agentId)

  return (
    <div className="px-[12.15%]">
      <div className="max-w-[800px] flex flex-col mx-auto gap-y-10">
        <div className="flex flex-col p-6 pb-2 bg-tan rounded-3xl gap-y-4">
          <div className="flex items-center">
            <DealIcon />
            <h1 className="ml-4 text-2xl font-medium leading-6">
              Deal details
            </h1>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="mb-1 text-sm text-steel">Agent</div>
              <AgentLink agent={agent!} />
            </div>

            <div>
              <div className="mb-1 text-sm text-steel">Deal</div>
              <DealButton deal={deal} isBuyer={isBuyer} />
            </div>

            <div>
              <div className="mb-1 text-sm text-steel">Client</div>
              <Contact contact={deal.contact!} />
            </div>

            <div>
              <div className="mb-1 text-sm text-steel">Deal partners</div>
              <div className="flex">
                {deal.partners?.map(partner => (
                  <div key={partner.id} className="p-1 rounded-lg bg-steel/10">
                    <Contact contact={partner} />
                  </div>
                ))}
              </div>
            </div>

            <div>
              <div className="mb-1 text-sm text-steel">Start date</div>
              <div>{getStartDate(deal)}</div>
            </div>

            <div>
              <div className="mb-1 text-sm text-steel">Sale price</div>
              <div>
                {currentStage?.price
                  ? numeral(currentStage.price).format('$0,0')
                  : '\u2014'}
              </div>
            </div>
          </div>

          <div className="flex flex-col p-4 -mx-4 bg-white rounded-2xl gap-y-3">
            <div className="flex items-center justify-between">
              <h2 className="font-medium">Commission</h2>
              <Link to={`/agent/${deal.userId}/deal/${deal.id}/commission`}>
                <Button theme="secondary" className="rounded w-36" height={24}>
                  Manage commission
                </Button>
              </Link>
            </div>

            <div className="grid grid-cols-4">
              <div>
                <div className="mb-1 text-sm text-steel">Commission %</div>
                <div>{getCommissionPercentage(deal)}</div>
              </div>

              <div>
                <div className="mb-1 text-sm text-steel">Gross commission</div>
                <div>{numeral(getGrossCommission(deal)).format('$0,0')}</div>
              </div>

              <div>
                <div className="mb-1 text-sm text-steel">Referral fee</div>
                <div>{getReferralFee(deal)}</div>
              </div>

              <div>
                <div className="mb-1 text-sm text-steel">Referral source</div>
                <div className="truncate">
                  {getReferralSource(
                    {
                      lead: deal.commission?.referralSource?.lead,
                      contact: deal.commission?.referralSource?.contact,
                    },
                    '\u2014'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          {stageNames.map(stageName => {
            const stage = deal.stages.find(stage => stage.stage === stageName)
            return (
              <DealStage
                key={stageName}
                agentId={agentId!}
                dealId={dealId!}
                stageName={stageName}
                stage={stage}
                isCurrentStage={stage?.stage === currentStage?.stage}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
