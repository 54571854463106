import { Progress } from '@material-tailwind/react'

interface IProgressBarProps {
  value?: number
}

export default function ProgressBar({ value }: IProgressBarProps) {
  return (
    <Progress
      value={value}
      barProps={{
        className: 'bg-gradient-to-r from-green-light to-green rounded',
      }}
      className="mb-16 rounded bg-tan px-0.5 py-[1px]"
    />
  )
}
