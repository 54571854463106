import clsx from 'clsx'
import UploadFileInput from 'components/Input/UploadFileInput'
import TeamAvatar from 'components/TeamAvatar/TeamAvatar'
import { Team } from 'types'

interface IProps {
  team: Partial<Team>
  onUpload: (url: string) => void
  onDelete: () => void
}

export default function TeamLogoForm({ team, onUpload, onDelete }: IProps) {
  const hasLogo = Boolean(team.logoUrl)

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-center w-20 h-20 rounded-full bg-tan">
        <TeamAvatar team={team} size={64} />
      </div>
      <UploadFileInput
        onUpload={onUpload}
        buttonText={hasLogo ? 'Replace logo' : 'Upload logo'}
        inputProps={{ accept: 'image/png, image/jpeg' }}
      />
      <div
        className={clsx(
          hasLogo ? 'text-steel cursor-pointer' : 'text-steel/35'
        )}
        {...(hasLogo && { onClick: onDelete })}
      >
        Delete
      </div>
    </div>
  )
}
