import clsx from 'clsx'

const COLORS = ['#99D4FF', '#F7C77C', '#F2AABB', '#B19BDE', '#FCE54D']

interface IProps {
  selected: string
  onSelect: (color: string) => void
  className?: string
}

export default function ColorPicker({ selected, onSelect, className }: IProps) {
  return (
    <div className={clsx('flex', className)}>
      {COLORS.map(color => (
        <div
          key={color}
          className={clsx(
            'w-8 h-8 bg-transparent rounded-full mr-3 last:mr-0 flex justify-center items-center',
            color === selected && 'border-2 border-green'
          )}
        >
          <button
            type="button"
            className="w-6 h-6 rounded-full"
            style={{ backgroundColor: color }}
            onClick={() => onSelect(color)}
          />
        </div>
      ))}
    </div>
  )
}
