import React, { useState, useMemo } from 'react'

import {
  ALL_LEADERBOARD_METRICS,
  LeaderboardMetric,
  MetricDateFilter,
} from 'types/metrics'
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from 'utils/formatters'
import { useTeam } from 'hooks'

import Table from 'components/Table/Table'
import MetricsFilter from 'components/MetricsFilter'

import LeaderboardWidget from './LeaderboardWidget'
import { makeColumns } from './utils'
import { useLeaderboard } from 'hooks/queries/useLeaderboard'

interface LeaderboardConfig {
  title: string
  metricName: string
  formatter: (value?: number) => string
}

const leaderboardConfig: Record<LeaderboardMetric, LeaderboardConfig> = {
  volumePerUser: {
    title: 'Volume leader',
    metricName: 'Volume',
    formatter: formatCurrency,
  },
  closedDealCountPerUser: {
    title: 'Deal leader',
    metricName: 'Deals',
    formatter: formatNumber,
  },
  avgCommissionPercentagePerUser: {
    title: 'Commission percentage leader',
    metricName: 'Avg commission %',
    formatter: formatPercentage,
  },
}

const Leaderboard: React.FC<{}> = () => {
  const { team, members } = useTeam()
  const [dateFilter, setDateFilter] = useState<MetricDateFilter>('YTD')
  const [sort, setSort] = useState<LeaderboardMetric>('volumePerUser')

  const { leaderboardByMetric, membersOrderedByMetric } = useLeaderboard({
    teamId: team?.id,
    dateFilter,
    members,
  })
  const columns = useMemo(() => makeColumns(sort, setSort), [sort])

  return (
    <div
      className="px-[12.15%] py-10"
      style={{
        background: 'linear-gradient(180deg, #F5F7EB 6.2%, #FFFFFF 52.61%)',
      }}
    >
      <div className="flex flex-col mx-auto max-w-[1440px] gap-y-6">
        <div className="flex items-center justify-between gap-x-4">
          <h2 className="font-medium text-[2rem]">Leaderboard</h2>
          <MetricsFilter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        </div>

        <div className="grid grid-cols-3 gap-x-4">
          {ALL_LEADERBOARD_METRICS.map(metric => {
            const config = leaderboardConfig[metric]

            return (
              <LeaderboardWidget
                key={metric}
                metric={metric}
                leaderboard={leaderboardByMetric[metric]}
                {...config}
              />
            )
          })}
        </div>

        <Table columns={columns} rows={membersOrderedByMetric[sort]} />
      </div>
    </div>
  )
}

export default Leaderboard
