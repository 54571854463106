import { capitalize, get } from 'lodash'
import { formatPhoneNumber } from 'react-phone-number-input'

import {
  ITeamMember,
  TeamMemberRole,
  UpdateTeamMemberRequest,
  User,
} from 'types'
import { TableCol } from 'components/Table/Table'
import TeamMember from 'components/TeamMember'

import RoleSelect from './RoleSelect'

type MemberChangeHandler = (
  memberId: string,
  data: UpdateTeamMemberRequest
) => Promise<void>

type MemberRemoveHandler = (memberId: string) => void

export const makeColumns = (
  currentUser: User | null,
  onMemberChange: MemberChangeHandler,
  onMemberRemove: MemberRemoveHandler
): TableCol[] => [
  {
    name: 'name',
    title: 'Name',
    cellClassName: 'font-medium',
    renderCell: ({ row }) => (
      <TeamMember member={row as unknown as ITeamMember} />
    ),
  },
  {
    name: 'role',
    title: 'Role',
    renderCell: ({ row, value }) =>
      value === 'OWNER' ||
      (currentUser && currentUser._id === (row.user as User)._id) ? (
        <span>{capitalize(value as string)}</span>
      ) : (
        <RoleSelect
          value={value as TeamMemberRole}
          onChange={(role: TeamMemberRole) =>
            onMemberChange(row._id as string, { role })
          }
        />
      ),
  },
  {
    name: 'contact',
    title: 'Contact info',
    renderCell: ({ row }) => (
      <>
        {get(row, 'user.email')}
        <br />
        {formatPhoneNumber(get(row, 'user.phone') as string)}
      </>
    ),
  },
  {
    name: 'action',
    title: '',
    renderCell: ({ row }) =>
      row.role !== 'OWNER' && (
        <button
          className="text-steel"
          onClick={() => onMemberRemove(row._id as string)}
        >
          Remove
        </button>
      ),
  },
]
