import CloseIcon from 'assets/icons/Close'
import clsx from 'clsx'
import { ButtonHTMLAttributes } from 'react'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export default function CloseButton({ className, ...props }: IProps) {
  return (
    <button
      type="button"
      className={clsx(
        'w-8 h-8 bg-tan rounded-full flex justify-center items-center',
        className
      )}
      {...props}
    >
      <CloseIcon />
    </button>
  )
}
