import { useQuery } from '@tanstack/react-query'
import { getContact } from 'utils/api/contact'

export const useGetContact = (contactId: string, userId: string) => {
  return useQuery({
    queryKey: ['contacts', userId, { contactId }],
    queryFn: () => getContact(contactId, userId),
    enabled: !!contactId && !!userId,
  })
}
