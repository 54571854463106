import { useState } from 'react'
import { isEmpty } from 'lodash'

import MetricsFilter from 'components/MetricsFilter'
import TeamAvatar from 'components/TeamAvatar/TeamAvatar'
import { useTeam } from 'hooks'
import { MetricDateFilter } from 'types/metrics'

import AverageCommissionWidget from './Widgets/AverageCommissionWidget'
import DealTypeSummaryWidget from './Widgets/DealTypeSummaryWidget'
import GetStartedWidget from './Widgets/GetStartedWidget'
import PerformanceWidget from './Widgets/PerformanceWidget'
import TopLeadSourcesWidget from './Widgets/TopLeadSourcesWidget'
import TotalVolumeWidget from './Widgets/TotalVolumeWidget'
import { useDashboard } from 'hooks/queries/useDashboard'

export default function Dashboard() {
  const { team, activeUsers } = useTeam()
  const [dateFilter, setDateFilter] = useState<MetricDateFilter>('YTD')

  const { allMetrics, buyerMetrics, sellerMetrics } = useDashboard({
    teamId: team?.id,
    dateFilter,
  })

  if (!team) {
    return null
  }

  const disabled = isEmpty(team.members)

  return (
    <div
      className="px-[12.15%] py-10"
      style={{
        background: 'linear-gradient(180deg, #F5F7EB 6.2%, #FFFFFF 52.61%)',
      }}
    >
      <div className="max-w-[1440px] mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-x-4">
            <TeamAvatar team={team} />
            <h2 className="font-medium text-[2rem] leading-8">
              {team.name} metrics
            </h2>
          </div>
          <MetricsFilter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        </div>

        <div className="flex justify-between mb-10 gap-x-4 h-44">
          {disabled && <GetStartedWidget />}

          <TotalVolumeWidget
            volume={allMetrics?.data.volume[0]?.value}
            volumePerUser={allMetrics?.data.volumePerUser}
            users={activeUsers}
            disabled={disabled}
          />
          <PerformanceWidget
            dealStageCount={allMetrics?.data.dealStageCount}
            disabled={disabled}
          />
          <AverageCommissionWidget
            avgCommissionPercentage={
              allMetrics?.data.avgCommissionPercentage[0]?.value
            }
            disabled={disabled}
          />
          <TopLeadSourcesWidget
            leadSourceCount={allMetrics?.data.leadSourceCount}
            disabled={disabled}
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex flex-col mr-4 basis-1/2">
            <h3 className="mb-4 text-2xl font-medium">Buyer</h3>
            <DealTypeSummaryWidget
              metrics={buyerMetrics?.data}
              dealType="BUYER"
              users={activeUsers}
              disabled={disabled}
            />
          </div>
          <div className="flex flex-col basis-1/2">
            <h3 className="mb-4 text-2xl font-medium">Seller</h3>
            <DealTypeSummaryWidget
              metrics={sellerMetrics?.data}
              dealType="SELLER"
              users={activeUsers}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
