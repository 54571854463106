import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import ArrowLeft from 'assets/icons/ArrowLeft'
import ArrowRight from 'assets/icons/ArrowRight'
import CaretDown from 'assets/icons/CaretDown'
import CaretUp from 'assets/icons/CaretUp'
import CheckIcon from 'assets/icons/Check'
import clsx from 'clsx'
import {
  BUYER_DEAL_STAGES,
  DEAL_STAGE_CONFIG,
  SELLER_DEAL_STAGES,
} from 'constants/deal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Deal, DEAL_STAGE } from 'types'
import { getCurrentDealStageName } from 'utils/deal'

interface IProps {
  deal: Deal
  isBuyer: boolean
}

const DealButton = ({ deal, isBuyer }: IProps) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const navigate = useNavigate()

  const currentStageName = getCurrentDealStageName(deal)
  const stageNames = isBuyer ? BUYER_DEAL_STAGES : SELLER_DEAL_STAGES

  const handleEdit = (stageName: DEAL_STAGE) => {
    setMenuOpened(false)
    if (stageName !== currentStageName) {
      navigate(`/agent/${deal.userId}/deal/${deal.id}/stage/${stageName}/set`)
    }
  }

  return (
    <>
      <Menu open={menuOpened} handler={e => setMenuOpened(e)}>
        <MenuHandler>
          <div className="flex items-center p-1 rounded-lg shadow-lg cursor-pointer bg-green-light">
            <div className="bg-green text-white text-[10px] rounded p-1 mr-2 flex items-center">
              <div className="mr-2">{isBuyer ? 'Buying' : 'Selling'}</div>
              <div className="bg-white p-0.5 rounded">
                {isBuyer ? <ArrowLeft /> : <ArrowRight />}
              </div>
            </div>
            <div className="flex-1">
              {DEAL_STAGE_CONFIG[currentStageName!].name}
            </div>
            <div className="flex mr-3">
              {menuOpened ? <CaretUp /> : <CaretDown />}
            </div>
          </div>
        </MenuHandler>
        <MenuList className="w-[280px] p-2 shadow-md">
          {stageNames.map(stageName => (
            <MenuItem
              key={stageName}
              className={clsx(
                'flex items-center justify-between h-10 p-2 rounded cursor-pointer text-off-black hover:bg-tan',
                stageName === currentStageName && 'text-green hover:text-green'
              )}
              onClick={() => handleEdit(stageName)}
            >
              {DEAL_STAGE_CONFIG[stageName].name}
              {deal.stages.find(stage => stage.stage === stageName) && (
                <CheckIcon />
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}

export default DealButton
