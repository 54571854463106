const Calendar = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 14 15" fill="none">
    <path
      d="M1 6H13V12C13 13.1046 12.1046 14 11 14H3C1.89543 14 1 13.1046 1 12V6Z"
      fill="#A2BFB5"
    />
    <path
      opacity="0.4"
      d="M1 4C1 2.89543 1.89543 2 3 2H11C12.1046 2 13 2.89543 13 4V6H1V4Z"
      fill="#A2BFB5"
    />
    <rect x="9" y="1" width="1" height="3" rx="0.5" fill="#A2BFB5" />
    <rect x="4" y="1" width="1" height="3" rx="0.5" fill="#A2BFB5" />
  </svg>
)

export default Calendar
