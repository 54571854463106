import ChevronLeft from 'assets/icons/ChevronLeft'
import { useNavigate } from 'react-router-dom'

export default function BackButton() {
  const navigate = useNavigate()
  return (
    <button
      className="flex items-end leading-none"
      onClick={() => navigate(-1)}
    >
      <ChevronLeft />
      <div className="ml-4">Back</div>
    </button>
  )
}
