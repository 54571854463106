interface IProps {
  className?: string
}

const TeamIcon = ({ className }: IProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.92253 11.7949C7.08956 11.7949 4.69922 12.3336 4.69922 14.4127C4.69922 16.4927 7.105 17.0125 9.92253 17.0125C12.7555 17.0125 15.1458 16.4737 15.1458 14.3947C15.1458 12.3147 12.7401 11.7949 9.92253 11.7949"
      fillOpacity="0.4"
      stroke="transparent"
    />
    <path d="M9.92327 10.7525C11.8416 10.7525 13.3797 8.84593 13.3797 6.69167C13.3797 4.53658 11.8416 2.98926 9.92327 2.98926C8.00497 2.98926 6.4668 4.53658 6.4668 6.69167C6.4668 8.84593 8.00497 10.7525 9.92327 10.7525Z" />
    <path d="M17.4286 7.76325C17.9249 5.56299 16.4698 3.58691 14.6169 3.58691C14.4155 3.58691 14.2228 3.61192 14.0346 3.65442C14.0096 3.66109 13.9817 3.67526 13.967 3.70026C13.9501 3.73193 13.9626 3.77444 13.9809 3.80194C14.5375 4.68705 14.8574 5.53475 14.8574 6.69156C14.8574 7.80002 14.564 9.06341 14.0493 9.92101C13.9964 10.0094 14.0434 10.1285 14.1368 10.1469C14.2662 10.1727 14.3986 10.186 14.5339 10.1902C15.8831 10.2302 17.094 9.24593 17.4286 7.76325Z" />
    <path
      d="M18.8424 12.7416C18.5953 12.1961 17.999 11.822 17.0925 11.6384C16.6645 11.5302 15.5065 11.3778 14.4293 11.3984C14.4131 11.4007 14.4043 11.4121 14.4029 11.4197C14.4006 11.4304 14.4051 11.4487 14.4264 11.4601C15.5018 11.7958 16.9587 12.6964 16.6064 15.1667C16.5961 15.2681 16.6748 15.3557 16.7726 15.3405C17.2461 15.2704 18.4645 14.9991 18.8424 14.1542C19.0512 13.7077 19.0512 13.1888 18.8424 12.7416Z"
      fillOpacity="0.4"
      stroke="transparent"
    />
    <path
      d="M1.15567 12.7416C1.40272 12.1961 1.99901 11.822 2.9056 11.6384C3.33352 11.5302 4.49156 11.3778 5.56872 11.3984C5.5849 11.4007 5.59372 11.4121 5.59519 11.4197C5.5974 11.4304 5.59299 11.4487 5.57166 11.4601C4.49622 11.7958 3.03935 12.6964 3.39161 15.1667C3.4019 15.2681 3.32323 15.3557 3.22544 15.3405C2.75193 15.2704 1.53359 14.9991 1.15567 14.1542C0.946852 13.7077 0.946852 13.1888 1.15567 12.7416Z"
      fillOpacity="0.4"
      stroke="transparent"
    />
    <path
      d="M5.89789 3.65442C5.71039 3.61108 5.51702 3.58691 5.31556 3.58691C3.46269 3.58691 2.00761 5.56299 2.50464 7.76325C2.83845 9.24593 4.04943 10.2302 5.39864 10.1902C5.53393 10.186 5.66701 10.1719 5.79568 10.1469C5.88906 10.1285 5.93612 10.0094 5.88318 9.92101C5.3685 9.06257 5.07513 7.79973 5.07513 6.69126C5.07513 5.53362 5.3957 4.68621 5.9523 3.80194C5.96994 3.77444 5.98318 3.73193 5.96553 3.70026C5.95082 3.67442 5.92362 3.66109 5.89789 3.65442Z"
      stroke="transparent"
    />
  </svg>
)

export default TeamIcon
