import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { get } from 'lodash'

interface RenderCellProps {
  row: Record<string, unknown>
  value: unknown
}

export interface TableCol {
  name: string
  title: ReactNode
  headerClassName?: string
  cellClassName?: string
  renderCell?: (props: RenderCellProps) => ReactNode
}

export interface TableProps {
  loading?: boolean
  columns: TableCol[]
  rows: Array<Record<string, any>>
}

const defaultRenderCell = ({ value }: RenderCellProps) => value

const Table: FC<TableProps> = ({ loading, columns, rows }) => (
  <div className="relative overflow-x-auto">
    <table className="w-full text-sm text-left">
      <thead className="border-b border-steel/15 text-steel">
        <tr>
          {columns.map(column => (
            <th
              key={column.name}
              scope="col"
              className={clsx('px-6 py-3 font-normal', column.headerClassName)}
            >
              {column.title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map(row => (
          <tr key={row._id} className="border-b border-steel/15">
            {columns.map(column => {
              const renderCell = column.renderCell || defaultRenderCell
              const value = get(row, column.name)

              return (
                <td
                  key={column.name}
                  className={clsx('px-6 py-4', column.cellClassName)}
                >
                  {renderCell({ row, value }) as ReactNode}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default Table
