import { Switch } from '@material-tailwind/react'
import clsx from 'clsx'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { StripePrice } from 'types/stripe'
import { getPricing } from 'utils/api/billing'
import Button from 'components/Button/Button'
import CheckIcon from 'assets/icons/Check'
import * as teamApi from 'utils/api/team'
import Screen from 'components/Screen/Screen'
import AccountTypesModal from 'components/Modal/AccountTypesModal'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { useTeam } from 'hooks'

const TEAM_SIZES = ['sm', 'md', 'lg']

export default function TeamPricing() {
  const { team } = useTeam()
  const [billingInterval, setBillingInterval] = useState('yearly')
  const [teamSize, setTeamSize] = useState<string | null>(null)
  const [prices, setPrices] = useState<StripePrice[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const getPrices = async () => {
      const lookupKeys = TEAM_SIZES.flatMap(size => [
        `team_${size}_monthly`,
        `team_${size}_yearly`,
      ])
      const res = await getPricing(lookupKeys)
      setPrices(res.data.data)
    }
    getPrices()
  }, [])

  const isAnnual = billingInterval === 'yearly'

  const getDisplayPrice = (price: StripePrice) => {
    return isAnnual ? price.unit_amount / 100 / 12 : price.unit_amount / 100
  }

  const handleSubmit = async () => {
    const price = prices.find(
      price => price.lookup_key === `team_${teamSize}_${billingInterval}`
    )
    if (price && team) {
      setIsSubmitting(true)
      const res = await teamApi.checkout(team.id, price.id)
      window.location.href = res.data.url
    }
  }

  if (modalOpen) {
    return <AccountTypesModal onClose={() => setModalOpen(false)} />
  }

  return (
    <Screen backButton>
      <div className="flex flex-col items-center">
        <div className="w-[647px]">
          <ProgressBar value={75} />
          <h1 className="font-medium text-[2rem] mb-4">
            Built for teams of all sizes
          </h1>
          <div className="mb-12">
            We're built to grow with you. You can switch plans at any time.
          </div>

          <div className="flex items-center justify-between mb-4">
            <div className="text-sm font-medium">Team size</div>
            <div className="flex items-center space-x-2">
              <span className={clsx('text-sm', !isAnnual && 'opacity-35')}>
                Annual billing
              </span>
              <div
                className={clsx(
                  'px-2 text-sm text-white bg-blue rounded-2xl',
                  !isAnnual && 'opacity-50'
                )}
              >
                SAVE 33%
              </div>
              <Switch
                color="green"
                defaultChecked
                onChange={e => {
                  setBillingInterval(e.target.checked ? 'yearly' : 'monthly')
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-4 mb-4 gap-x-2">
            {TEAM_SIZES.map(size => {
              const price = prices.find(
                price => price.lookup_key === `team_${size}_${billingInterval}`
              )
              if (!price) {
                return null
              }
              const isSelected = size === teamSize
              return (
                <div
                  key={size}
                  className={clsx(
                    'flex flex-col justify-between h-[135px] p-4 border border-steel/25 rounded-2xl',
                    isSelected ? 'shadow-md' : 'cursor-pointer hover:bg-tan/50'
                  )}
                  onClick={() => setTeamSize(size)}
                >
                  <div className="flex items-center justify-between">
                    <div className="text-2xl font-medium">
                      Up to {price?.metadata.user_cap}
                    </div>
                    {isSelected && <CheckIcon />}
                  </div>
                  <div>
                    <div className="font-medium">
                      {numeral(getDisplayPrice(price)).format('$0,0')}{' '}
                      <span className="text-sm font-normal text-off-black/70">
                        / mo
                      </span>
                    </div>
                    <div className="text-xs text-off-black/70">
                      Billed {billingInterval}
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="flex flex-col justify-between h-[135px] p-4 border border-steel/25 rounded-2xl">
              <div>
                <div className="text-2xl font-medium">Enterprise</div>
                <div className="text-xs text-steel">Unlimited seats</div>
              </div>
              <a
                className="no-underline"
                href="https://calendly.com/evanknowles-symba/symba-intro-30min"
              >
                <Button theme="secondary" className="w-[124px]" height={32}>
                  Get custom quote
                </Button>
              </a>
            </div>
          </div>

          <div className="mb-4 text-sm text-off-black/70">
            An admin recieves acces to the web app and mobile app, but only
            counts as one account. An agent only has access to the mobile app.{' '}
            <span
              className="cursor-pointer text-green"
              onClick={() => setModalOpen(true)}
            >
              Learn more
            </span>
          </div>

          <div className="mb-10 text-sm text-off-black/70">
            No commitments, cancel any time.
          </div>

          <div className="flex justify-end">
            <Button
              loading={isSubmitting}
              disabled={!teamSize}
              onClick={handleSubmit}
              className="w-56"
            >
              Start {process.env.REACT_APP_TRIAL_PERIOD_DAYS} day free trial
            </Button>
          </div>
        </div>
      </div>
    </Screen>
  )
}
