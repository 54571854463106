import TeamForm from 'components/Forms/TeamForm'
import { useTeam } from 'hooks'
import { toast } from 'react-hot-toast'

function EditAccount() {
  const { team, updateTeam } = useTeam()

  const handleSuccess = () => toast.success('Team updated!')

  return (
    <div className="max-w-[426px] mx-auto">
      <h2 className="text-[32px] font-medium mb-4">Edit account</h2>
      <TeamForm team={team} onSubmit={updateTeam} onSuccess={handleSuccess} />
    </div>
  )
}

export default EditAccount
