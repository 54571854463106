import React, { ComponentType } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import AccountIcon from 'assets/icons/Account'
import BillingIcon from 'assets/icons/Billing'
import LogoutIcon from 'assets/icons/Logout'
import ProfileIcon from 'assets/icons/Profile'
import TeamIcon from 'assets/icons/Team'

import SideMenuItem from './SideMenuItem'

interface ISideMenuProps {
  onManageBilling: () => void
  onLogOut: () => void
}

interface NavMenuItemConfig {
  label: string
  Icon: ComponentType
  link: string
}

const NAV_MENU_ITEMS: NavMenuItemConfig[] = [
  {
    label: 'Edit profile',
    Icon: ProfileIcon,
    link: '/settings/profile',
  },
  {
    label: 'Edit account',
    Icon: AccountIcon,
    link: '/settings/account',
  },
  {
    label: 'Manage team',
    Icon: TeamIcon,
    link: '/settings/team',
  },
]

const SettingsSideMenu: React.FC<ISideMenuProps> = ({
  onManageBilling,
  onLogOut,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div className="flex flex-col w-48 p-2 border-0 gap-y-2 rounded-2xl bg-tan">
      {NAV_MENU_ITEMS.map(item => (
        <SideMenuItem
          key={item.label}
          label={item.label}
          Icon={item.Icon}
          active={Boolean(matchPath(item.link, pathname))}
          onClick={() => navigate(item.link)}
        />
      ))}
      <SideMenuItem
        label="Billing"
        Icon={BillingIcon}
        onClick={onManageBilling}
      />
      <div className="h-px bg-steel/15" />
      <SideMenuItem label="Log out" Icon={LogoutIcon} onClick={onLogOut} />
    </div>
  )
}

export default SettingsSideMenu
