import AddressIcon from 'assets/icons/Address'
import CheckIcon from 'assets/icons/Check'
import DateIcon from 'assets/icons/Date'
import EditIcon from 'assets/icons/Edit'
import PriceIcon from 'assets/icons/Price'
import { String } from 'aws-sdk/clients/cloudtrail'
import clsx from 'clsx'
import { DEAL_STAGE_CONFIG } from 'constants/deal'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { IDealStage, DEAL_STAGE } from 'types'

interface IProps {
  agentId: string
  dealId: String
  stageName: DEAL_STAGE
  stage?: IDealStage
  isCurrentStage: boolean
}

export default function DealStage({
  agentId,
  dealId,
  stageName,
  stage,
  isCurrentStage,
}: IProps) {
  const stageConfig = DEAL_STAGE_CONFIG[stageName]

  return (
    <div
      className={clsx(
        'py-6 mb-4 last:mb-0',
        isCurrentStage && 'bg-tan rounded-2xl -mx-6 px-6'
      )}
    >
      {!stage && (
        <div className="text-2xl font-medium text-off-black/50">
          {stageConfig.name}
        </div>
      )}

      {!!stage && (
        <>
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <div className="mr-4 text-2xl font-medium">
                {stageConfig.name}
              </div>
              {stageConfig.isEditable && (
                <Link
                  to={`/agent/${agentId}/deal/${dealId}/stage/${stageName}/edit`}
                >
                  <EditIcon />
                </Link>
              )}
            </div>
            <CheckIcon />
          </div>

          <div className="flex items-center mb-1 text-sm">
            <DateIcon />
            <div className="ml-1">
              {DateTime.fromMillis(stage.timestamp).toLocaleString(
                DateTime.DATE_MED
              )}
            </div>
          </div>

          {!!stage.address && (
            <div className="flex items-center mb-1 text-sm">
              <AddressIcon />
              <div className="ml-1">{stage.address}</div>
            </div>
          )}

          {!!stage.price && (
            <div className="flex items-center justify-between">
              <div className="flex items-center text-sm">
                <PriceIcon />
                <div className="ml-1">
                  {numeral(stage.price).format('$0,0')}
                </div>
              </div>
              {isCurrentStage && (
                <Link
                  className="no-underline"
                  to={`/agent/${agentId}/deal/${dealId}/commission`}
                >
                  Manage commission
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
