import { MenuItem } from '@material-tailwind/react'
import CheckIcon from 'assets/icons/Check'

interface IProps {
  Icon?: any
  imageUrl?: any
  text: string
  checked?: boolean
  onClick?: () => void
}

const ProfileMenuItem = ({
  Icon,
  imageUrl,
  text,
  checked,
  onClick,
}: IProps) => (
  <MenuItem
    className="flex items-center w-full h-10 px-2 group text-off-black/70 hover:bg-tan hover:text-green"
    onClick={onClick}
  >
    {Icon && (
      <Icon className="stroke-steel fill-steel group-hover:stroke-green group-hover:fill-green" />
    )}
    {!Icon && imageUrl && (
      <div
        className="w-5 h-5 bg-center bg-cover rounded-full"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    )}
    {!Icon && !imageUrl && <div className="w-5 h-5" />}
    <span className="flex-1 ml-2 overflow-hidden text-ellipsis whitespace-nowrap">
      {text}
    </span>
    {checked && <CheckIcon />}
  </MenuItem>
)

export default ProfileMenuItem
