import clsx from 'clsx'
import { UseFormRegister } from 'react-hook-form'

type IProps = {
  label: string
  value: string
  name: string
  register: UseFormRegister<any>
  isSelected: boolean
}

export default function Radio({
  label,
  value,
  name,
  register,
  isSelected,
}: IProps) {
  return (
    <label
      className={clsx(
        'flex items-center justify-between py-2 pl-3 pr-2 rounded-lg cursor-pointer border',
        isSelected
          ? 'border-green-light bg-green-light'
          : 'border-steel/35 text-off-black/70'
      )}
    >
      {label}
      <input
        className="bg-clip-content w-4 h-4 checked:p-0.5 border border-steel/35 rounded-full bg-steel/35 appearance-none checked:bg-green checked:border-green"
        type="radio"
        value={value}
        {...register(name)}
      />
    </label>
  )
}
