import { useCallback } from 'react'
import { orderBy, range } from 'lodash'

import { LEAD_SOURCES } from 'constants/deal'
import { MetricValue } from 'types/metrics'
import Widget from 'components/Widget'

interface IProps {
  leadSourceCount?: MetricValue[]
  disabled?: boolean
}

function EmptyRow(idx: number) {
  return (
    <div key={idx} className="text-sm text-off-black/35">
      &mdash;
    </div>
  )
}

function SourceRow(source: MetricValue) {
  return (
    <div key={source._id} className="flex justify-between">
      <div className="text-sm">{LEAD_SOURCES[source._id!]}</div>
      <div className="font-medium">{source.value}</div>
    </div>
  )
}

export default function TopLeadSourcesWidget({
  leadSourceCount,
  disabled = false,
}: IProps) {
  const renderSourceRows = useCallback(() => {
    const numRows = 5
    const sourceRows = orderBy(
      leadSourceCount,
      ['value', '_id'],
      ['desc', 'asc']
    )
      .slice(0, numRows)
      .map(SourceRow)

    return [...sourceRows, ...range(numRows - sourceRows.length).map(EmptyRow)]
  }, [leadSourceCount])

  return (
    <Widget
      title="Top lead sources"
      disabled={disabled}
      className="basis-0 grow-[3]"
    >
      {renderSourceRows()}
    </Widget>
  )
}
