import React, { useState } from 'react'
import { Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react'
import { capitalize } from 'lodash'

import CaretDownIcon from 'assets/icons/CaretDown'
import CaretUpIcon from 'assets/icons/CaretUp'
import CheckIcon from 'assets/icons/Check'

import { ALL_ROLES, TeamMemberRole } from 'types'

interface IRoleSelectProps {
  value: TeamMemberRole
  onChange: (value: TeamMemberRole) => void
}

const RoleSelect: React.FC<IRoleSelectProps> = ({ value, onChange }) => {
  const [open, setOpen] = useState(false)

  return (
    <Menu
      open={open}
      placement="bottom-end"
      handler={currentOpen => setOpen(currentOpen)}
    >
      <MenuHandler>
        <div className="flex items-center gap-2 cursor-pointer text-green">
          <span>{capitalize(value)}</span>
          {open ? <CaretUpIcon /> : <CaretDownIcon />}
        </div>
      </MenuHandler>

      <MenuList className="min-w-[10rem] p-2 shadow-md">
        {ALL_ROLES.map((role: TeamMemberRole) => {
          const isSelected = role === value

          return (
            <MenuItem
              key={role}
              className="flex items-center justify-between text-off-black hover:text-green hover:bg-tan"
              onClick={() => {
                if (!isSelected) {
                  onChange(role)
                  setOpen(false)
                }
              }}
            >
              {capitalize(role)}
              {isSelected && <CheckIcon />}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default RoleSelect
