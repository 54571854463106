import Avatar from 'components/Avatar'
import PlusButton from 'components/Button/PlusButton'
import { Link } from 'react-router-dom'
import { User } from 'types'

interface IProps {
  activeUsers: User[]
}

const TeamMembers = ({ activeUsers }: IProps) => (
  <div className="items-center hidden mr-20 lg:flex">
    <Link className="mr-4 text-sm no-underline" to="/settings/team">
      Manage team
    </Link>
    {activeUsers.map(user => (
      <Link
        key={user.id}
        to={`/agent/${user.id}`}
        className="w-[22px] hover:z-50 no-underline"
      >
        <Avatar user={user} size={28} border />
      </Link>
    ))}
    <div className="ml-4">
      <Link to="/invite-team-member">
        <PlusButton />
      </Link>
    </div>
  </div>
)

export default TeamMembers
