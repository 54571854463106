import Button from 'components/Button/Button'
import Modal from './Modal'
import { useTeam } from 'hooks'
import manageBillingImage from 'assets/images/manage-billing.png'
import { useState } from 'react'
import { Team, User } from 'types'

interface IProps {
  team: Team
  me: User
}

export default function ManageBillingModal({ team, me }: IProps) {
  const { manageBilling, setActiveTeam } = useTeam()
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    manageBilling()
  }

  const handleDismiss = async () => {
    const newTeam = me.teams?.find(t => t.id !== team.id)
    if (newTeam) {
      setActiveTeam(newTeam.id)
    }
  }

  return (
    <Modal>
      <div className="w-[425px] mt-32">
        <div className="flex flex-col items-center justify-center mb-10">
          <img
            src={manageBillingImage}
            className="w-[106px]"
            alt="manage billing"
          />
        </div>
        <div className="text-sm text-steel">
          Your team subscription is inactive
        </div>
        <div className="font-medium text-[2rem] leading-8">
          Looks like you need to update your billing info
        </div>
        <div className="flex items-center justify-end mt-10 space-x-4">
          {me.teams && me.teams?.length > 1 && (
            <Button onClick={handleDismiss} theme="tertiary" className="w-1/2">
              Use another team
            </Button>
          )}
          <Button loading={loading} onClick={handleClick} className="w-1/2">
            Manage billing
          </Button>
        </div>
      </div>
    </Modal>
  )
}
