import { createBrowserRouter, Navigate } from 'react-router-dom'

import AgentLayout from 'layouts/AgentLayout/AgentLayout'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import OnboardingLayout from 'layouts/OnboardingLayout/OnboardingLayout'
import ProtectedLayout from 'layouts/ProtectedLayout/ProtectedLayout'
import RootLayout from 'layouts/RootLayout/RootLayout'
import SettingsLayout from 'layouts/SettingsLayout'

import AcceptInvite from './AcceptInvite/AcceptInvite'
import AgentContact from './AgentContact/AgentContact'
import AgentDeal from './AgentDeal'
import AgentProfile from './AgentProfile/AgentProfile'
import Dashboard from './Dashboard/Dashboard'
import EditAccount from './EditAccount'
import EditProfile from './EditProfile'
import InviteTeamMember from './InviteTeamMember'
import { ManageTeam } from './ManageTeam'
import Leaderboard from './Leaderboard'

import AccountType from './Onboarding/AccountType'
import AgentComplete from './Onboarding/AgentComplete'
import AgentPricing from './Onboarding/AgentPricing'
import OnboardingProfile from './Onboarding/OnboardingProfile'
import OnboardingTeam from './Onboarding/OnboardingTeam'
import TeamComplete from './Onboarding/TeamComplete'
import TeamPricing from './Onboarding/TeamPricing'

import EnterCode from './SignIn/EnterCode'
import SignIn from './SignIn/SignIn'
import SignUp from './SignUp/SignUp'
import EditDealStage from './EditDealStage/EditDealStage'
import SetDealStage from './SetDealStage/SetDealStage'
import Pipeline from './Pipeline/Pipeline'
import PipelineStage from './PipelineStage/PipelineStage'
import DealCommission from './DealCommission/DealCommission'

export default createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="signin" />,
          },
          {
            path: 'signup',
            element: <SignUp />,
          },
          {
            path: 'signin',
            element: <SignIn />,
          },
          {
            path: 'signin/code',
            element: <EnterCode />,
          },
          {
            path: 'accept-invite',
            element: <AcceptInvite />,
          },
        ],
      },
      {
        element: <ProtectedLayout />,
        children: [
          {
            path: 'onboarding',
            element: <OnboardingLayout />,
            children: [
              { path: 'account-type', element: <AccountType /> },
              { path: 'profile', element: <OnboardingProfile /> },
              { path: 'team', element: <OnboardingTeam /> },
              { path: 'team/pricing', element: <TeamPricing /> },
              { path: 'team/complete', element: <TeamComplete /> },
              { path: 'agent/pricing', element: <AgentPricing /> },
              { path: 'agent/complete', element: <AgentComplete /> },
            ],
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },
          {
            path: 'leaderboard',
            element: <Leaderboard />,
          },
          {
            path: 'pipeline',
            element: <Pipeline />,
          },
          {
            path: 'pipeline/:dealType/:stage',
            element: <PipelineStage />,
          },
          {
            path: 'agent/:agentId',
            element: <AgentLayout />,
            children: [
              { path: '', element: <AgentProfile /> },
              { path: 'deal/:dealId', element: <AgentDeal /> },
              { path: 'deal/:dealId/commission', element: <DealCommission /> },
              {
                path: 'deal/:dealId/stage/:stageId/edit',
                element: <EditDealStage />,
              },
              {
                path: 'deal/:dealId/stage/:stageId/set',
                element: <SetDealStage />,
              },
              { path: 'contact/:contactId', element: <AgentContact /> },
            ],
          },
          {
            path: 'settings',
            element: <SettingsLayout />,
            children: [
              { path: 'team', element: <ManageTeam /> },
              { path: 'profile', element: <EditProfile /> },
              { path: 'account', element: <EditAccount /> },
            ],
          },
          {
            path: 'invite-team-member',
            element: <InviteTeamMember />,
          },
        ],
      },
    ],
  },
])
