interface IProps {
  className?: string
}

const LeaderboardIcon = ({ className = 'fill-steel stroke-steel' }: IProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle opacity="0.4" cx="8" cy="8" r="8" stroke="transparent" />
    <path
      d="M7.0705 4.35078C7.40392 3.50789 8.59686 3.50789 8.93028 4.35078L9.55784 5.93725L11.2992 6.08404C12.1717 6.15759 12.5351 7.23755 11.8845 7.82355L10.5204 9.05214L10.9207 10.8167C11.1187 11.6896 10.1582 12.3631 9.40511 11.8794L8.00039 10.9773L6.59567 11.8794C5.84256 12.3631 4.88205 11.6896 5.08007 10.8167L5.48039 9.05214L4.11633 7.82355C3.46572 7.23755 3.82906 6.15759 4.70158 6.08404L6.44294 5.93725L7.0705 4.35078Z"
      stroke="transparent"
    />
  </svg>
)

export default LeaderboardIcon
