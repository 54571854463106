import { yupResolver } from '@hookform/resolvers/yup'
import ArrowRight from 'assets/icons/ArrowRight'
import Button from 'components/Button/Button'
import OptionSelect from 'components/Input/OptionSelect'
import Radio from 'components/Input/Radio'
import { COMMISSION_TYPE_OPTIONS, LEAD_SOURCE_OPTIONS } from 'constants/deal'
import { sortBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useMemo } from 'react'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form'
import {
  Commission,
  COMMISSION_TYPE,
  DealRequest,
  IDealStage,
  REFERRAL_SOURCE_TYPE,
  Contact,
  User,
  Team,
  IPostSplitAdjustment,
  SelectOption,
  TeamRequest,
} from 'types'
import * as yup from 'yup'
import numeral from 'numeral'
import AgentBreakdown from './AgentBreakdown'
import TeamBreakdown from './TeamBreakdown'
import {
  calculatePostSplitAdjustment,
  getAdjustmentPercentage,
  getReferralSource,
} from './utils'
import FormControl from 'components/Forms/FormControl'
import FormLabel from 'components/Forms/FormLabel'
import FormError from 'components/Forms/FormError'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { getReactSelectStyles } from 'components/Input/styles'
import PriceInput from 'components/Input/PriceInput'
import PercentageInput from 'components/Input/PercentageInput'
import DollarAmount from './DollarAmount'
import FormControlRow from 'components/Forms/FormControlRow'
import Adjustment from './Adjustment'
import AddAdjustmentButton from './AddAdjustmentButton'
import AdjustmentBreakdown from './AdjustmentBreakdown'

interface IDealCommissionForm {
  price?: number
  commissionType: COMMISSION_TYPE
  rate?: number
  flatFee?: number
  mySplit: number | string
  brokerageSplit: number | string
  hasReferralAdjustment: boolean
  referralFeeType: COMMISSION_TYPE
  referralFeeRate?: number
  referralFeeFlatFee?: number
  referralSourceType: REFERRAL_SOURCE_TYPE
  referralSourceLead?: string
  referralSourceContactId?: string
  postSplitAdjustments?: IPostSplitAdjustment[]
}

interface IProps {
  activeUsers: User[]
  agent: User
  team: Team
  updateTeam: (team: TeamRequest) => Promise<void>
  contacts: Contact[]
  currentStage: IDealStage
  commission: Commission
  onSubmit: (data: DealRequest) => void
  isSubmitting?: boolean
}

export type PayeeOption = {
  label: string
  value: {
    id: string
    type: string
  }
}

const DealCommissionFormSchema = yup.object().shape({
  price: yup
    .number()
    .required('Price is required')
    .positive('Price must be greater than 0'),
})

export default function DealCommissionForm({
  activeUsers,
  agent,
  team,
  updateTeam,
  contacts,
  currentStage,
  commission,
  onSubmit,
  isSubmitting,
}: IProps) {
  const defaultValues = {
    price: currentStage.price ?? 0,
    commissionType: commission.type ?? COMMISSION_TYPE.percentage,
    rate: commission.rate ?? 3,
    flatFee: commission.flatFee,
    mySplit: commission.mySplit ?? 100,
    brokerageSplit: commission.brokerageSplit ?? 0,
    hasReferralAdjustment: Boolean(commission.referralFee),
    referralFeeType: commission.referralFee?.type ?? COMMISSION_TYPE.percentage,
    referralFeeRate: commission.referralFee?.rate,
    referralFeeFlatFee: commission.referralFee?.flatFee,
    referralSourceType:
      commission.referralSource?.type ?? REFERRAL_SOURCE_TYPE.leadSource,
    referralSourceLead: commission.referralSource?.lead,
    referralSourceContactId: commission.referralSource?.contactId,
    postSplitAdjustments: commission.postSplitAdjustments ?? [],
  }

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<IDealCommissionForm>({
    resolver: yupResolver(DealCommissionFormSchema),
    defaultValues,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'postSplitAdjustments',
  })

  const {
    price,
    commissionType,
    rate,
    flatFee,
    mySplit,
    brokerageSplit,
    hasReferralAdjustment,
    referralFeeType,
    referralFeeRate,
    referralFeeFlatFee,
    referralSourceLead,
    referralSourceContactId,
    postSplitAdjustments,
  } = useWatch({ control })

  const gci =
    commissionType === COMMISSION_TYPE.percentage
      ? (price! * rate!) / 100
      : flatFee

  const referralFee =
    referralFeeType === COMMISSION_TYPE.percentage
      ? (gci! * (referralFeeRate ?? 0)) / 100
      : referralFeeFlatFee ?? 0

  const preSplitGci = gci! - referralFee
  const agentSplit = preSplitGci * (parseInt(mySplit as string) / 100)
  const teamSplit = preSplitGci * (parseInt(brokerageSplit as string) / 100)

  const sortedContacts = useMemo(() => {
    const cleaned = contacts
      .map(c => ({
        ...c,
        firstName: c.firstName.trim(),
        lastName: c.lastName.trim(),
        name: c.name.trim(),
      }))
      .filter(c => !!c.name)
    return sortBy(cleaned, ['lastName', 'firstName'])
  }, [contacts])

  const referralSource = useMemo(() => {
    return {
      lead: referralSourceLead,
      contact: sortedContacts.find(
        contact => contact.id === referralSourceContactId
      ),
    }
  }, [sortedContacts, referralSourceLead, referralSourceContactId])

  const save: SubmitHandler<IDealCommissionForm> = async (
    data: IDealCommissionForm
  ) => {
    const payload = {
      stage: {
        ...currentStage,
        price: data.price,
      },
      commission: {
        type: data.commissionType,
        ...(data.commissionType === COMMISSION_TYPE.percentage
          ? { rate: data.rate }
          : { flatFee: data.flatFee }),
        mySplit: parseInt(data.mySplit as string),
        brokerageSplit: parseInt(data.brokerageSplit as string),
        referralFee: !data.hasReferralAdjustment
          ? undefined
          : {
              type: data.referralFeeType,
              ...(data.referralFeeType === COMMISSION_TYPE.percentage
                ? { rate: data.referralFeeRate }
                : { flatFee: data.referralFeeFlatFee }),
            },
        referralSource: !data.hasReferralAdjustment
          ? undefined
          : {
              type: data.referralSourceType,
              lead: data.referralSourceLead,
              contactId: data.referralSourceContactId,
            },
        postSplitAdjustments: data.postSplitAdjustments,
      },
    }

    onSubmit(payload)
  }

  const payeeOptions = activeUsers
    .map(user => ({
      label: user.name,
      value: { id: user.id, type: 'USER' },
    }))
    .concat(
      team.payees?.map(payee => ({
        label: payee.name,
        value: { id: payee.id, type: 'CUSTOM' },
      })) ?? []
    )

  const contactOptions = sortedContacts.map(contact => ({
    value: contact.id,
    label: contact.name,
  }))

  const calculatedPostSplitAdjustments =
    postSplitAdjustments?.map(adjustment => ({
      payer: adjustment.payer,
      payee:
        payeeOptions.find(
          op =>
            op.value.id === adjustment.payee?.id &&
            op.value.type === adjustment.payee?.type
        )?.label ?? 'Adjustment',
      feeType: adjustment.feeType,
      amount: calculatePostSplitAdjustment(
        adjustment as IPostSplitAdjustment,
        preSplitGci,
        adjustment.payer === 'AGENT' ? agentSplit : teamSplit
      ),
      percentage:
        adjustment.feeType === COMMISSION_TYPE.percentage
          ? getAdjustmentPercentage(
              adjustment as IPostSplitAdjustment,
              preSplitGci,
              adjustment.payer === 'AGENT' ? agentSplit : teamSplit
            )
          : undefined,
    })) ?? []

  return (
    <div className="grid grid-cols-2 mt-6 gap-x-4">
      <form
        className="flex flex-col gap-y-4"
        noValidate
        onSubmit={handleSubmit(save)}
      >
        <h2 className="text-steel">Pre-split</h2>

        <Controller
          name="price"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl>
              <FormLabel>Price</FormLabel>
              <PriceInput value={value} onChange={onChange} error={error} />
              {error && <FormError>{error.message}</FormError>}
            </FormControl>
          )}
        />

        <FormControl>
          <FormLabel>GCI</FormLabel>
          <Controller
            name="commissionType"
            control={control}
            render={({ field: { value, onChange } }) => (
              <OptionSelect
                value={value}
                onChange={onChange}
                options={COMMISSION_TYPE_OPTIONS}
              />
            )}
          />

          {commissionType === COMMISSION_TYPE.percentage && (
            <FormControlRow>
              <Controller
                name="rate"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PercentageInput value={value} onChange={onChange} />
                )}
              />
              <div>
                <ArrowRight />
              </div>
              <DollarAmount>{numeral(gci).format('0,0[.]00')}</DollarAmount>
            </FormControlRow>
          )}

          {commissionType === COMMISSION_TYPE.flatFee && (
            <Controller
              name="flatFee"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PriceInput value={value} onChange={onChange} />
              )}
            />
          )}
        </FormControl>

        {!hasReferralAdjustment && (
          <AddAdjustmentButton
            onClick={() =>
              setValue('hasReferralAdjustment', true, { shouldDirty: true })
            }
          >
            Add referral adjustment
          </AddAdjustmentButton>
        )}

        {hasReferralAdjustment && (
          <Adjustment
            title="Referral adjustment"
            onDelete={() =>
              setValue('hasReferralAdjustment', false, {
                shouldDirty: true,
              })
            }
          >
            <FormControl>
              <FormLabel>Lead source</FormLabel>
              <Controller
                name="referralSourceLead"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Select<SelectOption>
                      value={LEAD_SOURCE_OPTIONS.find(op => op.value === value)}
                      onChange={option => onChange(option?.value ?? '')}
                      options={LEAD_SOURCE_OPTIONS}
                      isClearable
                      isSearchable={false}
                      placeholder="Select lead source"
                      styles={getReactSelectStyles()}
                    />
                  )
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Referral contact</FormLabel>
              <Controller
                name="referralSourceContactId"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Select<SelectOption>
                      value={contactOptions.find(op => op.value === value)}
                      onChange={option => onChange(option?.value ?? '')}
                      options={contactOptions}
                      isClearable
                      placeholder="Search contacts"
                      styles={getReactSelectStyles()}
                    />
                  )
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Referral fee</FormLabel>
              <Controller
                name="referralFeeType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <OptionSelect
                    value={value}
                    onChange={onChange}
                    options={COMMISSION_TYPE_OPTIONS}
                  />
                )}
              />

              {referralFeeType === COMMISSION_TYPE.percentage && (
                <FormControlRow>
                  <Controller
                    name="referralFeeRate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <PercentageInput value={value} onChange={onChange} />
                    )}
                  />
                  <div>
                    <ArrowRight />
                  </div>
                  <DollarAmount>
                    {numeral(referralFee).format('0,0[.]00')}
                  </DollarAmount>
                </FormControlRow>
              )}

              {referralFeeType === COMMISSION_TYPE.flatFee && (
                <Controller
                  name="referralFeeFlatFee"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <PriceInput value={value} onChange={onChange} />
                  )}
                />
              )}
            </FormControl>
          </Adjustment>
        )}

        <h2 className="text-steel">Post-split</h2>

        {/** Agent/Team split */}
        <FormControlRow>
          <FormControl>
            <FormLabel>Agent split</FormLabel>
            <Controller
              name="mySplit"
              control={control}
              render={({ field: { value } }) => (
                <PercentageInput
                  value={value}
                  onChange={value => {
                    setValue('mySplit', value ?? '', { shouldDirty: true })
                    setValue('brokerageSplit', 100 - (value ?? 0))
                  }}
                />
              )}
            />
          </FormControl>

          <div className="mt-[30px]">
            <ArrowRight />
          </div>

          <FormControl>
            <FormLabel>Team split</FormLabel>
            <Controller
              name="brokerageSplit"
              control={control}
              render={({ field: { value } }) => (
                <PercentageInput
                  value={value}
                  onChange={value => {
                    setValue('brokerageSplit', value ?? '', {
                      shouldDirty: true,
                    })
                    setValue('mySplit', 100 - (value ?? 0))
                  }}
                />
              )}
            />
          </FormControl>
        </FormControlRow>

        {/** Post-split adjustments */}
        {fields.map((field, index) => (
          <Adjustment
            key={field.id}
            title="Post-split adjustment"
            onDelete={() => remove(index)}
          >
            <div className="grid grid-cols-2 gap-x-2">
              <Radio
                label={agent.name}
                value="AGENT"
                isSelected={postSplitAdjustments?.[index]?.payer === 'AGENT'}
                name={`postSplitAdjustments.${index}.payer`}
                register={register}
              />
              <Radio
                label={team.name}
                value="TEAM"
                isSelected={postSplitAdjustments?.[index]?.payer === 'TEAM'}
                name={`postSplitAdjustments.${index}.payer`}
                register={register}
              />
            </div>

            <Controller
              name={`postSplitAdjustments.${index}.payee`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormControl>
                  <FormLabel>Payee</FormLabel>
                  <CreatableSelect<PayeeOption>
                    value={payeeOptions.find(
                      op =>
                        op.value.id === value?.id &&
                        op.value.type === value?.type
                    )}
                    onChange={op => onChange(op?.value ?? null)}
                    onCreateOption={async inputValue => {
                      const payee = { id: uuid(), name: inputValue }
                      await updateTeam({
                        payees: [...(team.payees ?? []), payee],
                      })
                      onChange({ id: payee.id, type: 'CUSTOM' })
                    }}
                    options={payeeOptions}
                    placeholder="Select payee"
                    styles={getReactSelectStyles()}
                  />
                  {error && <FormError>{error.message}</FormError>}
                </FormControl>
              )}
            />

            <FormControl>
              <FormLabel>Fee</FormLabel>
              <Controller
                name={`postSplitAdjustments.${index}.feeType`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <OptionSelect
                    value={value}
                    onChange={onChange}
                    options={COMMISSION_TYPE_OPTIONS}
                  />
                )}
              />

              {postSplitAdjustments?.[index]?.feeType ===
                COMMISSION_TYPE.percentage && (
                <FormControlRow>
                  <Controller
                    name={`postSplitAdjustments.${index}.rate`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <PercentageInput value={value} onChange={onChange} />
                    )}
                  />
                  <div>
                    <ArrowRight />
                  </div>
                  <DollarAmount>
                    {numeral(
                      calculatedPostSplitAdjustments[index]?.amount
                    ).format('0,0[.]00')}
                  </DollarAmount>
                </FormControlRow>
              )}

              {postSplitAdjustments?.[index]?.feeType ===
                COMMISSION_TYPE.flatFee && (
                <Controller
                  name={`postSplitAdjustments.${index}.flatFee`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <PriceInput value={value} onChange={onChange} />
                  )}
                />
              )}
            </FormControl>

            {postSplitAdjustments?.[index]?.feeType ===
              COMMISSION_TYPE.percentage && (
              <FormControl>
                <FormLabel>Taken</FormLabel>
                <Controller
                  name={`postSplitAdjustments.${index}.taken`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const options = [
                      {
                        value: 'PRE_SPLIT',
                        label: `Pre-split - ${numeral(preSplitGci).format(
                          '$0,0[.]00'
                        )}`,
                      },
                      {
                        value: 'POST_SPLIT',
                        label: `Post-split - ${numeral(
                          postSplitAdjustments?.[index]?.payer === 'AGENT'
                            ? agentSplit
                            : teamSplit
                        ).format('$0,0[.]00')}`,
                      },
                    ]
                    return (
                      <Select<SelectOption>
                        value={options.find(op => op.value === value)}
                        onChange={option => onChange(option?.value ?? '')}
                        options={options}
                        styles={getReactSelectStyles()}
                      />
                    )
                  }}
                />
              </FormControl>
            )}
          </Adjustment>
        ))}

        <AddAdjustmentButton
          onClick={() =>
            append({
              payer: 'AGENT',
              feeType: COMMISSION_TYPE.percentage,
              taken: 'POST_SPLIT',
            })
          }
        >
          Add post-split adjustment
        </AddAdjustmentButton>

        <div className="grid grid-cols-2 mt-6 gap-x-2">
          <Button
            type="button"
            theme="tertiary"
            disabled={!isDirty}
            onClick={() => reset(defaultValues)}
          >
            Reset
          </Button>
          <Button type="submit" loading={isSubmitting} disabled={!isDirty}>
            Save
          </Button>
        </div>
      </form>

      {/** Payments column */}
      <div className="sticky flex flex-col self-start gap-4 top-4">
        <h2 className="text-steel">Payments</h2>
        {agent && (
          <AgentBreakdown
            agent={agent}
            price={price!}
            gci={gci!}
            referralFee={hasReferralAdjustment ? referralFee : 0}
            referralSource={getReferralSource(referralSource)}
            preSplitGci={preSplitGci}
            agentSplit={agentSplit}
            teamSplit={teamSplit}
            postSplitAdjustments={calculatedPostSplitAdjustments.filter(
              adjustment => adjustment.payer === 'AGENT'
            )}
          />
        )}
        {team && (
          <TeamBreakdown
            team={team}
            price={price!}
            gci={gci!}
            referralFee={hasReferralAdjustment ? referralFee : 0}
            referralSource={getReferralSource(referralSource)}
            preSplitGci={preSplitGci}
            agentSplit={agentSplit}
            teamSplit={teamSplit}
            postSplitAdjustments={calculatedPostSplitAdjustments.filter(
              adjustment => adjustment.payer === 'TEAM'
            )}
          />
        )}
        {hasReferralAdjustment && (
          <AdjustmentBreakdown
            amount={referralFee}
            payee={getReferralSource(referralSource)}
          />
        )}
        {calculatedPostSplitAdjustments.map((adjustment, index) => (
          <AdjustmentBreakdown
            key={index}
            amount={adjustment.amount}
            payee={adjustment.payee}
          />
        ))}
      </div>
    </div>
  )
}
