import clsx from 'clsx'
import numeral from 'numeral'
import { FieldError } from 'react-hook-form'

type Props = {
  value?: number | string
  onChange: (value: number | null) => void
  error?: FieldError
}

const PercentageInput = ({ value, onChange, error }: Props) => (
  <div className="relative">
    <input
      type="number"
      aria-invalid={error ? 'true' : 'false'}
      className={clsx(
        'h-10 py-2 pl-4 pr-10 font-normal border rounded-lg outline-none border-steel/35 focus:shadow-sm',
        error && 'border-red'
      )}
      min={0}
      max={100}
      value={value ? numeral(value).format('0.[00]') : ''}
      onChange={e => onChange(numeral(e.target.value).value())}
    />
    <span className="font-medium text-steel absolute right-[8px] top-[50%] transform translate-y-[-50%]">
      %
    </span>
  </div>
)

export default PercentageInput
