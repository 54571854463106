import EditIcon from 'assets/icons/Edit'
import Avatar from 'components/Avatar'
import CommissionModal from 'components/Modal/CommissionModal'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'
import { UpdateCommissionRequest, User } from 'types'

interface IProps {
  user: User
  onUpdateCommission: (
    agentId: string,
    data: UpdateCommissionRequest
  ) => Promise<void>
}

export default function AccountCard({ user, onUpdateCommission }: IProps) {
  const [edittingCommission, setEdittingCommission] = useState(false)

  return (
    <>
      <div className="basis-[29.5%] bg-tan rounded-3xl p-4 pt-10 mr-4 h-full">
        <div className="flex flex-col items-center justify-center mb-6">
          <Avatar user={user} size={64} />
          <div className="font-medium">{user.name}</div>
        </div>
        <div className="mb-4">
          <div className="text-steel">Phone number</div>
          <div>{formatPhoneNumber(user.phone)}</div>
        </div>
        <div className="mb-4">
          <div className="text-steel">Email address</div>
          <div>{user.email}</div>
        </div>
        <div className="mb-4">
          <div className="text-steel">Joined</div>
          <div>
            {user.createdAt
              ? DateTime.fromISO(user.createdAt).toLocaleString(
                  DateTime.DATE_FULL
                )
              : '\u2014'}
          </div>
        </div>
        <div className="flex items-center justify-between mb-3">
          <div className="text-steel">Commission split</div>
          <button onClick={() => setEdittingCommission(true)}>
            <EditIcon />
          </button>
        </div>
        <div className="px-2 py-1 mb-4 text-sm bg-steel/15 rounded-2xl w-fit">
          Fixed split
        </div>
        <div className="flex items-center justify-between">
          <div>{user.commission?.mySplit}%</div>
          <div className="bg-steel/10 h-3 w-full mx-2 rounded flex justify-between items-center p-0.5">
            <div
              className={`h-2 rounded-sm mr-0.5 last:mr-0 bg-green-dark shadow-sm`}
              style={{ flexBasis: `${user.commission?.mySplit}%` }}
            ></div>
            <div
              className={`h-2 rounded-sm bg-green shadow-sm`}
              style={{ flexBasis: `${user.commission?.brokerageSplit}%` }}
            ></div>
          </div>
          <div className="text-off-black/70">
            {user.commission?.brokerageSplit}%
          </div>
        </div>
      </div>

      {edittingCommission && (
        <CommissionModal
          onClose={() => setEdittingCommission(false)}
          user={user}
          onSave={onUpdateCommission}
        />
      )}
    </>
  )
}
