import { useQuery } from '@tanstack/react-query'
import { getDeals } from 'utils/api/deal'

export const useGetDeals = (userId: string) => {
  return useQuery({
    queryKey: ['deals', userId],
    queryFn: () => getDeals(userId),
    enabled: !!userId,
  })
}
