import Screen from 'components/Screen/Screen'
import { Outlet } from 'react-router-dom'

export default function AgentLayout() {
  return (
    <div className="relative py-10 border-t border-t-steel/15">
      <Screen backButton>
        <Outlet />
      </Screen>
    </div>
  )
}
