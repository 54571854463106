import {
  Control,
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form'

export interface BaseInputProps<T extends FieldValues> {
  control: Control<T>
  name: FieldPath<T>
  label?: string
  required?: boolean
  render: ControllerProps<T>['render']
}

const BaseInput = <T extends FieldValues>({
  control,
  name,
  label,
  required,
  render,
}: BaseInputProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={renderProps => {
      const {
        fieldState: { error },
      } = renderProps

      return (
        <div className="flex flex-col gap-y-2">
          {label && (
            <label className="text-sm font-medium">
              {label}
              {required && <span className="text-red">&nbsp;*</span>}
            </label>
          )}

          {render(renderProps)}

          {error && (
            <p className="text-xs text-red">{error.message as string}</p>
          )}
        </div>
      )
    }}
  />
)

export default BaseInput
