interface IProps {
  className?: string
}

const LivePipelineIcon = ({
  className = 'fill-steel stroke-steel',
}: IProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle cx="8" cy="8" r="8" opacity="0.4" />
    <path d="M11.5 7.13397C12.1667 7.51887 12.1667 8.48113 11.5 8.86603L7 11.4641C6.33333 11.849 5.5 11.3679 5.5 10.5981L5.5 5.40192C5.5 4.63212 6.33333 4.151 7 4.5359L11.5 7.13397Z" />
  </svg>
)

export default LivePipelineIcon
