import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'

import { useTeam } from 'hooks/useTeam'
import CloseButton from 'components/Button/CloseButton'

import UpgradeSubscription from './UpgradeSubscription'
import InviteTeamMemberForm from './InviteTeamMemberForm'

export default function InviteTeamMember() {
  const navigate = useNavigate()
  const { inviteTeamMember, team, manageBilling } = useTeam()

  const onClose = () => navigate(-1)

  const userCap = parseInt(
    get(team, [
      'subscription',
      'items',
      'data',
      '0',
      'price',
      'metadata',
      'user_cap',
    ])
  )
  const teamSize = (team?.members?.length ?? 0) + 1

  return (
    <div className="relative w-[64rem] mx-auto pb-20">
      <div className="absolute right-0 top-4">
        <CloseButton onClick={onClose} />
      </div>

      <div className="w-[27rem] mx-auto my-4">
        {teamSize >= userCap ? (
          <UpgradeSubscription manageBilling={manageBilling} />
        ) : (
          <>
            <h2 className="text-[2rem] font-medium">Invite team member</h2>
            <InviteTeamMemberForm inviteTeamMember={inviteTeamMember} />
          </>
        )}
      </div>
    </div>
  )
}
