import clsx from 'clsx'
import Widget from 'components/Widget'
import numeral from 'numeral'
import { User } from 'types'
import { MetricValue } from 'types/metrics'
import VolumePerUserBar from './VolumePerUserBar'

interface IProps {
  volume?: number
  volumePerUser?: MetricValue[]
  users: User[]
  disabled?: boolean
}

export default function TotalVolumeWidget({
  volume,
  volumePerUser,
  users,
  disabled = false,
}: IProps) {
  return (
    <Widget
      title="Total volume"
      disabled={disabled}
      className="basis-0 grow-[3]"
    >
      <div
        className={clsx(
          'mb-4 text-4xl font-medium',
          disabled && 'text-off-black/35'
        )}
      >
        {numeral(volume).format('$0,0')}
      </div>

      <VolumePerUserBar
        volume={volume}
        volumePerUser={volumePerUser}
        users={users}
      />
    </Widget>
  )
}
