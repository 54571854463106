export enum DEAL_TYPE {
  buyer = 'BUYER',
  seller = 'SELLER',
}

export enum DEAL_STAGE {
  inCommunication = 'IN_COMMUNICATION',
  appointmentSet = 'APPOINTMENT_SET',
  showingHomes = 'SHOWING_HOMES',
  listingTaken = 'LISTING_TAKEN',
  listingActive = 'LISTING_ACTIVE',
  offerSubmitted = 'OFFER_SUBMITTED',
  underContract = 'UNDER_CONTRACT',
  saleClosed = 'SALE_CLOSED',
}

export enum COMMISSION_TYPE {
  percentage = 'PERCENTAGE',
  flatFee = 'FLAT_FEE',
}

export enum REFERRAL_SOURCE_TYPE {
  leadSource = 'LEAD_SOURCE',
  contact = 'CONTACT',
}

export interface ReferralSource {
  type?: REFERRAL_SOURCE_TYPE
  lead?: string
  contactId?: string
  contact?: Contact
}

export interface IPostSplitAdjustment {
  payer: 'AGENT' | 'TEAM'
  payee?: {
    id: string
    type: 'USER' | 'CUSTOM'
  }
  feeType: COMMISSION_TYPE
  rate?: number
  flatFee?: number
  taken: 'PRE_SPLIT' | 'POST_SPLIT'
}

export interface Commission {
  rate?: number
  mySplit?: number
  brokerageSplit?: number
  type?: COMMISSION_TYPE
  flatFee?: number
  referralFee?: {
    type?: COMMISSION_TYPE
    rate?: number
    flatFee?: number
  }
  referralSource?: ReferralSource
  postSplitAdjustments?: IPostSplitAdjustment[]
}

export interface IDealStage {
  stage: DEAL_STAGE
  timestamp: number
  address?: string
  price?: number
  commission?: number
}

export interface Deal {
  id: string
  timestamp: number
  type: DEAL_TYPE
  contactId: string
  userId: string
  stages: IDealStage[]
  partners: LeanContact[]
  commission?: Commission
  currentStage?: IDealStage
  contact?: LeanContact
}

export interface LeanDeal {
  id: string
  timestamp: number
  type: DEAL_TYPE
  contactId: string
  userId: string
  stages: IDealStage[]
  contact: LeanContact
}

export interface DealRequest {
  stage: IDealStage
  commission?: Commission
}

export interface UpdateDealResponse {
  updates: {
    deals: Deal[]
  }
}

export interface Contact {
  id: string
  userId: string
  firstName: string
  lastName: string
  initials: string
  name: string
  color: string
  avi?: string
  email?: string
  phone?: string
  address?: string
  type?: string
  leadSource?: string
  deals?: LeanDeal[]
  notes?: string[]
  tasks?: string[]
}

export interface LeanContact {
  id: string
  userId: string
  firstName: string
  lastName: string
  initials: string
  name: string
  color: string
  avi?: string
  email?: string
  phone?: string
  address?: string
  type?: string
  leadSource?: string
}
