import { yupResolver } from '@hookform/resolvers/yup'
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import 'yup-phone-lite'

import Button from 'components/Button/Button'
import TextInput from 'components/Input/TextInput'
import Panel from 'components/Panel/Panel'
import { useAuth } from 'hooks'
import { EnterCodeForm } from 'types'
import { formatPhoneNumber } from 'utils/formatters'
import Screen from 'components/Screen/Screen'

const EnterCodeFormSchema = yup.object().shape({
  code: yup
    .string()
    .required('Confirmation code is required')
    .matches(/^[0-9]{6}$/, 'Confirmation code is invalid'),
})

const EnterCode = () => {
  const [searchParams] = useSearchParams()

  const { enterCode } = useAuth()

  const defaultValues = {
    session: searchParams.get('session') ?? '',
    username: searchParams.get('name') ?? '',
    code: '',
  }

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<EnterCodeForm>({
    resolver: yupResolver(EnterCodeFormSchema),
    defaultValues,
  })

  const onSubmit: SubmitHandler<EnterCodeForm> = async data =>
    await enterCode(data)

  return (
    <Screen backButton>
      <Panel>
        <h2 className="mb-4 text-[2rem] leading-8 font-medium">
          Enter the 6-digit code sent to your phone
        </h2>

        <div>
          We’ve texted you a one time sign in code to{' '}
          <span className="font-medium">
            {formatPhoneNumber(searchParams.get('phone') ?? '')}
          </span>
          .
        </div>

        <form
          noValidate
          className="grid grid-cols-2 gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-span-2 py-6">
            <TextInput
              control={control as unknown as Control<FieldValues>}
              name="code"
              label="Confirmation code"
              placeholder="Confirmation code"
            />
          </div>

          <Button type="submit" loading={isSubmitting} className="col-start-2">
            Submit
          </Button>
        </form>
      </Panel>
    </Screen>
  )
}

export default EnterCode
