import { ReactNode } from 'react'

interface IProps {
  title: string
  value: ReactNode
}

export default function ContactField({ title, value }: IProps) {
  return (
    <div className="mb-4 last:mb-0">
      <div className="text-steel">{title}</div>
      <div>{value}</div>
    </div>
  )
}
