import numeral from 'numeral'

interface IProps {
  amount: number
  payee?: string
}

export default function AdjustmentBreakdown({ amount, payee }: IProps) {
  return (
    <div className="p-2 text-sm shadow-md rounded-2xl">
      <div className="flex items-center justify-between p-4 rounded-lg bg-tan">
        <div className="flex items-center">
          <span className="font-medium">{payee}</span>
        </div>
        <div className="text-2xl font-medium">
          {numeral(amount).format('$0,0.00')}
        </div>
      </div>
    </div>
  )
}
