import clsx from 'clsx'
import Avatar from 'components/Avatar'
import UploadFileInput from 'components/Input/UploadFileInput'
import { User } from 'types'

interface IProps {
  user: Partial<User>
  onUpload: (url: string) => void
  onDelete: () => void
}

export default function UserAviForm({ user, onUpload, onDelete }: IProps) {
  const hasAvi = Boolean(user.avi)

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-center w-20 h-20 rounded-full bg-tan">
        <Avatar user={user} size={64} />
      </div>
      <UploadFileInput
        onUpload={onUpload}
        buttonText={hasAvi ? 'Replace photo' : 'Upload photo'}
        inputProps={{ accept: 'image/png, image/jpeg' }}
      />
      <div
        className={clsx(hasAvi ? 'text-steel cursor-pointer' : 'text-steel/35')}
        {...(hasAvi && { onClick: onDelete })}
      >
        Delete
      </div>
    </div>
  )
}
