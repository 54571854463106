import { User } from './user'

export const ALL_ROLES = ['AGENT', 'ADMIN', 'OWNER'] as const
export const ALL_INVITATION_STATUSES = [
  'PENDING',
  'ACCEPTED',
  'REVOKED',
] as const
export const ALL_SUBSCRIPTION_STATUSES = [
  'active',
  'trialing',
  'canceled',
  'past_due',
  'unpaid',
] as const

export type TeamMemberRole = (typeof ALL_ROLES)[number]
export type InvitationStatus = (typeof ALL_INVITATION_STATUSES)[number]
export type SubscriptionStatus = (typeof ALL_SUBSCRIPTION_STATUSES)[number]

export interface TeamMemberInvitation {
  _id: string
  status: InvitationStatus
  token: string
}

export interface ITeamMember {
  _id: string
  userId: string
  user: User
  role: TeamMemberRole
  invitation: TeamMemberInvitation
}

export interface Subscription {
  status: SubscriptionStatus
}

export interface Payee {
  id: string
  name: string
}

export interface Team {
  id: string
  name: string
  brokerage: string
  color: string
  logoUrl?: string
  owner: User
  members: ITeamMember[]
  subscription?: Subscription
  payees?: Payee[]
}

export interface TeamRequest {
  logoUrl?: string
  color?: string
  name?: string
  brokerage?: string
  payees?: Payee[]
}

export interface TeamBillingResponse {
  url: string
}

export interface InviteTeamMemberRequest
  extends Pick<
    User,
    'firstName' | 'lastName' | 'email' | 'phone' | 'commission'
  > {
  role: TeamMemberRole
  isBetaUser: boolean
}

export type UpdateTeamMemberRequest = Pick<ITeamMember, 'role'>

export type TeamMemberPayload = Partial<Omit<ITeamMember, '_id' | 'userId'>>

export interface AcceptInviteResponse {
  team: Partial<Team>
  role: TeamMemberRole
  phone: string
}

export interface UpdateCommissionRequest {
  mySplit: number
  brokerageSplit: number
}

export interface TeamPipelineResponse {
  _id: {
    dealType: string
    currentStage: string
    userId: string
  }
  count: number
  volume: number
}
