import Avatar from 'components/Avatar'
import { LEAD_SOURCES } from 'constants/deal'
import { useGetContact } from 'hooks/queries/useGetContact'
import { partition } from 'lodash'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useParams } from 'react-router-dom'
import { DEAL_STAGE } from 'types'
import { getCurrentDealStageName } from 'utils/deal'
import ContactField from './ContactField'
import DealSection from './DealSection'

export default function AgentContact() {
  const { agentId, contactId } = useParams()

  const { data, isLoading } = useGetContact(contactId!, agentId!)

  if (isLoading) {
    return null
  }

  const contact = data?.data!

  const [closedDeals, activeDeals] = partition(
    contact.deals!.map(deal => ({ ...deal, contact })),
    deal => getCurrentDealStageName(deal) === DEAL_STAGE.saleClosed
  )

  return (
    <div className="flex px-[20%]">
      <div className="w-1/2 h-full p-10 mr-4 bg-tan rounded-3xl">
        <div className="flex flex-col items-center justify-center mb-6">
          <Avatar user={contact} size={64} />
          <div className="font-medium">
            {contact.firstName} {contact.lastName}
          </div>
          {contact.type && (
            <div className="px-3 mt-2 text-sm rounded text-off-black/70 bg-steel/15">
              {contact.type}
            </div>
          )}
        </div>
        <ContactField
          title="Phone number"
          value={!!contact.phone ? formatPhoneNumber(contact.phone) : '\u2014'}
        />
        <ContactField
          title="Email address"
          value={
            !!contact.email ? (
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            ) : (
              '\u2014'
            )
          }
        />
        <ContactField title="Address" value={contact.address ?? '\u2014'} />
        <ContactField
          title="Lead source"
          value={
            !!contact.leadSource ? LEAD_SOURCES[contact.leadSource] : '\u2014'
          }
        />
      </div>
      <div className="w-1/2">
        <h2 className="mb-2 text-2xl font-medium">Deals</h2>
        <DealSection
          deals={activeDeals}
          title="Active"
          active
          className="mb-4"
        />
        <DealSection deals={closedDeals} title="Closed" active={false} />
      </div>
    </div>
  )
}
