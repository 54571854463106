import BackButton from 'components/Button/BackButton'
import { PropsWithChildren } from 'react'

interface IProps {
  backButton?: boolean
  children: any
}

const Screen = ({
  backButton = false,
  children,
}: PropsWithChildren<IProps>) => {
  return (
    <div>
      {backButton && (
        <div className="absolute top-10 left-[5%] text-off-black/70">
          <BackButton />
        </div>
      )}
      {children}
    </div>
  )
}

export default Screen
