const ArrowLeft = () => (
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none">
    <path
      d="M4.5 7L1.5 4L4.5 1"
      stroke="#A2BFB5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 4L1.5 4"
      stroke="#A2BFB5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeft
