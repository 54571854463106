import CaretDown from 'assets/icons/CaretDown'
import { TableCol } from 'components/Table/Table'
import TeamMember from 'components/TeamMember'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import Contact from 'routes/AgentDeal/Contact'
import { Deal, ITeamMember, Contact as IContact } from 'types'
import { formatCurrency } from 'utils/formatters'

export const makeColumns = (members: ITeamMember[]): TableCol[] => [
  {
    name: 'agent',
    title: 'Agent',
    cellClassName: 'font-medium',
    renderCell: ({ row }) => {
      const member = members.find(m => m.userId === row.userId)
      return <TeamMember member={member!} />
    },
  },
  {
    name: 'contact',
    title: 'Client',
    renderCell: ({ row }) => (
      <Contact contact={row.contact as unknown as IContact} />
    ),
  },
  {
    name: 'address',
    title: 'Address',
    renderCell: ({ row }) => (
      <span>{(row as unknown as Deal).currentStage?.address ?? '\u2014'}</span>
    ),
  },
  {
    name: 'price',
    title: 'Price',
    renderCell: ({ row }) => (
      <span>
        {formatCurrency((row as unknown as Deal).currentStage?.price)}
      </span>
    ),
  },
  {
    name: 'date',
    title: (
      <span className="flex items-center justify-start gap-x-1">
        Date <CaretDown />
      </span>
    ),
    renderCell: ({ row }) => (
      <span>
        {DateTime.fromMillis(
          (row as unknown as Deal).currentStage?.timestamp!
        ).toLocaleString(DateTime.DATE_MED)}
      </span>
    ),
  },
  {
    name: 'action',
    title: '',
    renderCell: ({ row }) => (
      <Link
        className="flex items-center space-x-2 no-underline group"
        to={`/agent/${row.userId}/deal/${row.id}`}
      >
        View deal
      </Link>
    ),
  },
]
