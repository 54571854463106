import { useQuery } from '@tanstack/react-query'
import { getDeal } from 'utils/api/deal'

export const useGetDeal = (dealId: string, userId: string) => {
  return useQuery({
    queryKey: ['deals', userId, { dealId }],
    queryFn: () => getDeal(dealId, userId),
    enabled: !!dealId && !!userId,
  })
}
