import React from 'react'
import { ALL_METRIC_DATE_FILTERS, MetricDateFilter } from 'types/metrics'
import Pill from 'components/Button/Pill'

interface MetricsFilterProps {
  dateFilter: MetricDateFilter
  setDateFilter: (value: MetricDateFilter) => void
}

export const MetricsFilter: React.FC<MetricsFilterProps> = ({
  dateFilter,
  setDateFilter,
}) => (
  <div className="flex items-center gap-x-1">
    {ALL_METRIC_DATE_FILTERS.map(value => (
      <Pill
        key={value}
        isActive={dateFilter === value}
        onClick={() => setDateFilter(value)}
      >
        {value}
      </Pill>
    ))}
  </div>
)

export default MetricsFilter
