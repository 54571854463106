import { useQuery } from '@tanstack/react-query'
import { DEAL_TYPE } from 'types'
import { getTeamPipelineMetrics } from 'utils/api/team'

interface IProps {
  teamId?: string
}

export const usePipeline = ({ teamId }: IProps) => {
  const { data } = useQuery({
    queryKey: ['pipeline', teamId],
    queryFn: () => getTeamPipelineMetrics(teamId!),
    enabled: !!teamId,
  })

  const buyerPipeline =
    data?.data.filter(item => item._id.dealType === DEAL_TYPE.buyer) ?? []
  const sellerPipeline =
    data?.data.filter(item => item._id.dealType === DEAL_TYPE.seller) ?? []

  return { buyerPipeline, sellerPipeline }
}
