const UpgradeSubscriptionIcon = () => (
  <svg
    width="94"
    height="93"
    viewBox="0 0 94 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="47" cy="46.5" r="46" fill="#F5F7EB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 46.5C1.5 21.371 21.871 1 47 1C72.129 1 92.5 21.371 92.5 46.5C92.5 71.629 72.129 92 47 92C21.871 92 1.5 71.629 1.5 46.5ZM47 0C21.3188 0 0.5 20.8188 0.5 46.5C0.5 72.1812 21.3188 93 47 93C72.6812 93 93.5 72.1812 93.5 46.5C93.5 20.8188 72.6812 0 47 0ZM47 17C30.7076 17 17.5 30.2076 17.5 46.5C17.5 62.7924 30.7076 76 47 76C63.2924 76 76.5 62.7924 76.5 46.5C76.5 30.2076 63.2924 17 47 17ZM16.5 46.5C16.5 29.6553 30.1553 16 47 16C63.8447 16 77.5 29.6553 77.5 46.5C77.5 63.3447 63.8447 77 47 77C30.1553 77 16.5 63.3447 16.5 46.5ZM25.5 46.5C25.5 34.6259 35.1259 25 47 25C58.8741 25 68.5 34.6259 68.5 46.5C68.5 58.3741 58.8741 68 47 68C35.1259 68 25.5 58.3741 25.5 46.5ZM47 24C34.5736 24 24.5 34.0736 24.5 46.5C24.5 58.9264 34.5736 69 47 69C59.4264 69 69.5 58.9264 69.5 46.5C69.5 34.0736 59.4264 24 47 24ZM29.5 46.5C29.5 36.835 37.335 29 47 29C56.665 29 64.5 36.835 64.5 46.5C64.5 56.165 56.665 64 47 64C37.335 64 29.5 56.165 29.5 46.5ZM47 28C36.7827 28 28.5 36.2827 28.5 46.5C28.5 56.7173 36.7827 65 47 65C57.2173 65 65.5 56.7173 65.5 46.5C65.5 36.2827 57.2173 28 47 28Z"
      fill="url(#paint0_radial_6262_6068)"
    />
    <g filter="url(#filter0_ddii_6262_6068)">
      <rect x="31" y="30.5" width="32" height="32" rx="16" fill="white" />
    </g>
    <path
      d="M46.923 48.3066C44.0898 48.3066 41.6992 48.8454 41.6992 50.9247C41.6992 53.0049 44.1052 53.5247 46.923 53.5247C49.7563 53.5247 52.1469 52.9859 52.1469 50.9066C52.1469 48.8265 49.7409 48.3066 46.923 48.3066Z"
      fill="#DAFC7E"
    />
    <path
      d="M46.9226 47.264C48.8411 47.264 50.3794 45.3572 50.3794 43.2028C50.3794 41.0475 48.8411 39.5 46.9226 39.5C45.0041 39.5 43.4658 41.0475 43.4658 43.2028C43.4658 45.3572 45.0041 47.264 46.9226 47.264Z"
      fill="#3AC345"
    />
    <path
      d="M54.4294 44.2746C54.9258 42.0742 53.4705 40.0979 51.6175 40.0979C51.416 40.0979 51.2233 40.1229 51.0351 40.1654C51.0101 40.1721 50.9822 40.1863 50.9675 40.2113C50.9505 40.2429 50.963 40.2854 50.9814 40.3129C51.5381 41.1981 51.8579 42.0459 51.8579 43.2028C51.8579 44.3114 51.5645 45.5749 51.0498 46.4326C50.9969 46.521 51.0439 46.6402 51.1373 46.6585C51.2667 46.6843 51.3991 46.6977 51.5344 46.7018C52.8837 46.7419 54.0948 45.7575 54.4294 44.2746Z"
      fill="#3AC345"
    />
    <path
      d="M55.8433 49.2527C55.5962 48.7071 54.9999 48.333 54.0932 48.1494C53.6652 48.0412 52.5071 47.8888 51.4298 47.9094C51.4136 47.9116 51.4048 47.9231 51.4033 47.9307C51.4011 47.9414 51.4055 47.9596 51.4269 47.9711C52.5024 48.3068 53.9594 49.2075 53.6071 51.6781C53.5969 51.7794 53.6755 51.8671 53.7733 51.8518C54.2469 51.7817 55.4653 51.5105 55.8433 50.6654C56.0521 50.2189 56.0521 49.7 55.8433 49.2527Z"
      fill="#DAFC7E"
    />
    <path
      d="M38.1567 49.2527C38.4038 48.7071 39.0001 48.333 39.9068 48.1494C40.3348 48.0412 41.4929 47.8888 42.5702 47.9094C42.5864 47.9116 42.5952 47.9231 42.5967 47.9307C42.5989 47.9414 42.5945 47.9596 42.5731 47.9711C41.4976 48.3068 40.0406 49.2075 40.3929 51.6781C40.4031 51.7794 40.3245 51.8671 40.2267 51.8518C39.7531 51.7817 38.5347 51.5105 38.1567 50.6654C37.9479 50.2189 37.9479 49.7 38.1567 49.2527Z"
      fill="#DAFC7E"
    />
    <path
      d="M42.8977 40.1652C42.7102 40.1218 42.5168 40.0977 42.3154 40.0977C40.4623 40.0977 39.0071 42.0739 39.5042 44.2744C39.838 45.7572 41.0491 46.7416 42.3984 46.7016C42.5337 46.6974 42.6668 46.6833 42.7955 46.6583C42.8889 46.6399 42.936 46.5207 42.883 46.4324C42.3683 45.5739 42.0749 44.3109 42.0749 43.2023C42.0749 42.0446 42.3955 41.1971 42.9522 40.3127C42.9698 40.2852 42.983 40.2427 42.9654 40.211C42.9507 40.1852 42.9235 40.1718 42.8977 40.1652Z"
      fill="#3AC345"
    />
    <defs>
      <filter
        id="filter0_ddii_6262_6068"
        x="23"
        y="26.5"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6262_6068"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_6262_6068"
          result="effect2_dropShadow_6262_6068"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_6262_6068"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect3_innerShadow_6262_6068"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_innerShadow_6262_6068"
          result="effect4_innerShadow_6262_6068"
        />
      </filter>
      <radialGradient
        id="paint0_radial_6262_6068"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47 46.5) rotate(90) scale(46.5)"
      >
        <stop stopColor="#DAFC7E" />
        <stop offset="1" stopColor="#3AC345" />
      </radialGradient>
    </defs>
  </svg>
)

export default UpgradeSubscriptionIcon
