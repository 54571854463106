import clsx from 'clsx'
import { User, Contact } from 'types'

interface IProps {
  className?: string
  user: Partial<User> | Partial<Contact>
  size?: number
  border?: boolean
}

const Avatar = ({ className, user, size = 32, border = false }: IProps) => {
  const hasAvi = Boolean(user.avi)

  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full',
        hasAvi ? 'bg-cover bg-center' : 'text-[#333333] font-medium uppercase',
        className
      )}
      style={{
        width: size,
        height: size,
        ...(hasAvi
          ? { backgroundImage: `url(${user.avi})` }
          : { fontSize: size / 2, background: user.color }),
        ...(border && { border: `2px solid ${user.color}` }),
      }}
    >
      {!hasAvi && `${user.firstName?.[0] ?? ''}${user.lastName?.[0] ?? ''}`}
    </div>
  )
}

export default Avatar
