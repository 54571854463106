import { COMMISSION_TYPE, DEAL_STAGE, REFERRAL_SOURCE_TYPE } from 'types'

export const BUYER_DEAL_STAGES = [
  DEAL_STAGE.inCommunication,
  DEAL_STAGE.appointmentSet,
  DEAL_STAGE.showingHomes,
  DEAL_STAGE.offerSubmitted,
  DEAL_STAGE.underContract,
  DEAL_STAGE.saleClosed,
]

export const SELLER_DEAL_STAGES = [
  DEAL_STAGE.inCommunication,
  DEAL_STAGE.appointmentSet,
  DEAL_STAGE.listingTaken,
  DEAL_STAGE.listingActive,
  DEAL_STAGE.underContract,
  DEAL_STAGE.saleClosed,
]

export const DEAL_STAGE_CONFIG = {
  [DEAL_STAGE.inCommunication]: {
    name: 'In communication',
    isEditable: false,
  },
  [DEAL_STAGE.appointmentSet]: {
    name: 'Appointment set',
    isEditable: false,
  },
  [DEAL_STAGE.showingHomes]: {
    name: 'Showing homes',
    isEditable: true,
  },
  [DEAL_STAGE.offerSubmitted]: {
    name: 'Offer submitted',
    isEditable: true,
  },
  [DEAL_STAGE.listingTaken]: {
    name: 'Listing taken',
    isEditable: true,
  },
  [DEAL_STAGE.listingActive]: {
    name: 'Listing active',
    isEditable: true,
  },
  [DEAL_STAGE.underContract]: {
    name: 'Under contract',
    isEditable: true,
  },
  [DEAL_STAGE.saleClosed]: {
    name: 'Sale closed',
    isEditable: true,
  },
}

export const LEAD_SOURCES = {
  ZILLOW: 'Zillow',
  REALTOR_DOT_COM: 'Realtor.Com',
  OPCITY: 'Opcity',
  SPHERE: 'Sphere',
  AGENT_REFERRAL: 'Agent Referral',
  SOCIAL_MEDIA: 'Social Media',
  OPEN_HOUSE: 'Open House',
  HOME_LIGHT: 'HomeLight',
  GOOGLE_LOCAL_SERVICE_AD: 'Google Local Service Ad',
  FSBO: 'FSBO',
  EXPIRED: 'Expired',
  YLOPO: 'Ylopo',
  WEBSITE: 'Website',
  CLIENT_REFERRAL: 'Client Referral',
  OTHER: 'Other',
} as { [key: string]: string }

export const DEFAULT_COMMISSION = '3'
export const DEFAULT_MY_SPLIT = '100'
export const DEFAULT_BROKERAGE_SPLIT = '0'

export const COMMISSION_TYPE_OPTIONS = [
  { key: COMMISSION_TYPE.percentage, label: 'Percentage' },
  { key: COMMISSION_TYPE.flatFee, label: 'Flat fee' },
]

export const LEAD_SOURCE_OPTIONS = Object.keys(LEAD_SOURCES).map(key => ({
  value: key,
  label: LEAD_SOURCES[key],
}))

export const REFERRAL_SOURCE_TYPE_OPTIONS = [
  {
    key: REFERRAL_SOURCE_TYPE.leadSource,
    label: 'Lead source',
  },
  { key: REFERRAL_SOURCE_TYPE.contact, label: 'Contact' },
]
