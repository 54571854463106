import PlusButton from 'components/Button/PlusButton'

type Props = {
  children: React.ReactNode
  onClick: () => void
}

export default function AddAdjustmentButton({ children, onClick }: Props) {
  return (
    <div
      className="flex items-center p-2 text-sm text-left rounded-lg cursor-pointer bg-tan text-green gap-x-2"
      onClick={onClick}
    >
      <PlusButton />
      {children}
    </div>
  )
}
