import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import getStartedTeamImg from 'assets/images/get-started-team.png'
import Button from 'components/Button/Button'
import PlusButton from 'components/Button/PlusButton'
import Table from 'components/Table/Table'
import { useProfile, useTeam } from 'hooks'
import { useConfirm } from 'contexts/ConfirmContext'

import { makeColumns } from './utils'

export default function ManageTeam() {
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { members, updateTeamMember, deleteTeamMember } = useTeam()
  const { me } = useProfile()

  const inviteTeamMember = () => {
    navigate('/invite-team-member')
  }

  const confirmTeamMemberRemove = useCallback(
    async (memberId: string) => {
      const isConfirmed = await confirm(
        'Are you sure?',
        'Removing a team member cannot be undone, but you can always invite them again.'
      )

      if (isConfirmed) {
        deleteTeamMember(memberId)
      }
    },
    [confirm, deleteTeamMember]
  )

  const columns = useMemo(
    () => makeColumns(me, updateTeamMember, confirmTeamMemberRemove),
    [me, updateTeamMember, confirmTeamMemberRemove]
  )

  return (
    <>
      <div className="flex justify-between w-full mb-8">
        <h2 className="text-3xl font-medium">Manage team</h2>

        <Button onClick={inviteTeamMember} className="w-56">
          Invite team member
        </Button>
      </div>

      {members.length > 0 ? (
        <Table columns={columns} rows={members} />
      ) : (
        <div className="flex flex-col items-center justify-center border-2 border-dashed h-80 border-steel/35 rounded-3xl">
          <img
            src={getStartedTeamImg}
            alt="Invite to team"
            className="h-[94px] ml-4"
          />
          <h3 className="mt-10 text-2xl font-medium">Get started</h3>
          <div className="flex items-center mt-2 gap-x-2">
            <PlusButton />
            <span className="text-sm text-green">Invite your team</span>
          </div>
        </div>
      )}
    </>
  )
}
