import ArrowLeft from 'assets/icons/ArrowLeft'
import ArrowRight from 'assets/icons/ArrowRight'
import { DEAL_TYPE } from 'types'

interface IProps {
  dealType: DEAL_TYPE
}

export default function DealTypeChip({ dealType }: IProps) {
  return (
    <div className="flex items-center justify-between w-16 p-1 pl-2 text-xs rounded bg-steel/10">
      <div>{dealType === DEAL_TYPE.buyer ? 'Buying' : 'Selling'}</div>
      <div className="bg-steel/40 p-0.5 rounded">
        {dealType === DEAL_TYPE.buyer ? <ArrowLeft /> : <ArrowRight />}
      </div>
    </div>
  )
}
