const AdminIcon = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.21463 8.79102C2.38637 8.79102 0 9.32883 0 11.4045C0 13.481 2.40178 13.9999 5.21463 13.9999C8.04289 13.9999 10.4293 13.4621 10.4293 11.3864C10.4293 9.30993 8.02748 8.79102 5.21463 8.79102Z"
      fill="#A2BFB5"
      fillOpacity="0.4"
    />
    <path
      d="M5.2144 7.75035C7.12951 7.75035 8.66513 5.84693 8.66513 3.69625C8.66513 1.54475 7.12951 0 5.2144 0C3.29929 0 1.76367 1.54475 1.76367 3.69625C1.76367 5.84693 3.29929 7.75035 5.2144 7.75035Z"
      fill="#A2BFB5"
    />
    <path
      d="M16.0766 6.79002C16.0119 6.69002 15.9199 6.59002 15.8007 6.52669C15.7053 6.48002 15.644 6.40335 15.5895 6.31335C15.4158 6.02669 15.518 5.65002 15.8075 5.48002C16.1482 5.29002 16.2572 4.86669 16.0596 4.53669L15.8314 4.14335C15.6372 3.81335 15.2114 3.69669 14.8741 3.89002C14.5744 4.05002 14.1894 3.94335 14.0157 3.66002C13.9612 3.56669 13.9305 3.46669 13.9373 3.36669C13.9476 3.23669 13.9067 3.11335 13.8454 3.01335C13.7193 2.80669 13.4911 2.66669 13.239 2.66669H12.7587C12.51 2.67335 12.2818 2.80669 12.1557 3.01335C12.091 3.11335 12.0535 3.23669 12.0603 3.36669C12.0671 3.46669 12.0365 3.56669 11.982 3.66002C11.8083 3.94335 11.4233 4.05002 11.1269 3.89002C10.7863 3.69669 10.3639 3.81335 10.1663 4.14335L9.93807 4.53669C9.7439 4.86669 9.85291 5.29002 10.1902 5.48002C10.4797 5.65002 10.5819 6.02669 10.4116 6.31335C10.3537 6.40335 10.2924 6.48002 10.197 6.52669C10.0811 6.59002 9.97895 6.69002 9.92445 6.79002C9.7984 6.99669 9.80522 7.25669 9.93126 7.47335L10.1663 7.87335C10.2924 8.08669 10.5274 8.22002 10.7727 8.22002C10.8885 8.22002 11.0248 8.18669 11.1338 8.12002C11.2189 8.06335 11.3211 8.04335 11.4335 8.04335C11.7708 8.04335 12.0535 8.32002 12.0603 8.65002C12.0603 9.03335 12.3737 9.33335 12.7689 9.33335H13.2322C13.6239 9.33335 13.9373 9.03335 13.9373 8.65002C13.9476 8.32002 14.2303 8.04335 14.5675 8.04335C14.6765 8.04335 14.7787 8.06335 14.8673 8.12002C14.9763 8.18669 15.1092 8.22002 15.2284 8.22002C15.4703 8.22002 15.7053 8.08669 15.8314 7.87335L16.0698 7.47335C16.1925 7.25002 16.2027 6.99669 16.0766 6.79002Z"
      fill="#A2BFB5"
    />
    <path
      d="M13.0041 6.94333C12.4692 6.94333 12.0366 6.52666 12.0366 6.00333C12.0366 5.48 12.4692 5.06 13.0041 5.06C13.5389 5.06 13.9613 5.48 13.9613 6.00333C13.9613 6.52666 13.5389 6.94333 13.0041 6.94333Z"
      fill="white"
    />
  </svg>
)

export default AdminIcon
