import {
  Association,
  UpdateCommissionRequest,
  UpdateUserRequest,
  User,
} from 'types'
import { MetricsRequest, MetricsResponse } from 'types/metrics'
import { StripeCheckoutResponse } from 'types/stripe'
import { get, post, put } from './index'

export const fetchMe = async () => get<User>('/user/me')

export const updateMe = async (data: UpdateUserRequest) => put('/user/me', data)

export const getUserMetrics = async (userId: string, data: MetricsRequest) =>
  post<MetricsResponse>('/user/me/metrics', data, { params: { userId } })

export const searchAssociations = async (searchTerm: string) => {
  const res = await post<Association[]>('/association/search', { searchTerm })
  return res.data
}

export const checkout = async (priceId: string) =>
  get<StripeCheckoutResponse>(`/user/checkout`, {
    priceId,
    successUrl: `${process.env.REACT_APP_BASE_URL}/onboarding/agent/complete`,
    cancelUrl: `${process.env.REACT_APP_BASE_URL}/onboarding/agent/pricing`,
    trialPeriodDays: process.env.REACT_APP_TRIAL_PERIOD_DAYS,
  })

export const updateCommission = async (
  agentId: string,
  data: UpdateCommissionRequest
) => put(`/user/me/commission`, data, { params: { userId: agentId } })
