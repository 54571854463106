import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions } from 'reducers/profile'
import { EnterCodeForm, ISignInForm, SignUpForm } from 'types'
import * as authApi from 'utils/api/auth'
import Cognito from 'utils/cognito'
import { clearToken, saveToken } from 'utils/token'
import { useProfile } from './useProfile'

interface IHook {
  signUp: (data: SignUpForm) => Promise<void>
  signIn: (data: ISignInForm) => Promise<void>
  enterCode: (data: EnterCodeForm) => Promise<void>
  logOut: () => void
}

export const useAuth = (): IHook => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loadProfile } = useProfile()

  const signUp = async (data: SignUpForm) => {
    try {
      await authApi.signUp(data)
      await signIn({ phone: data.phone })
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err.response?.data.message ?? err.message)
      }
    }
  }

  const signIn: IHook['signIn'] = async data => {
    try {
      const cognito = new Cognito()
      const res = await cognito.initiateAuth(data.phone)
      const searchParams = new URLSearchParams({
        phone: data.phone,
        session: res.Session as string,
        name: res.ChallengeParameters?.USERNAME as string,
      })
      navigate({ pathname: '/signin/code', search: searchParams.toString() })
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message)
      }
    }
  }

  const enterCode: IHook['enterCode'] = async data => {
    const cognito = new Cognito()
    const response = await cognito.respondToAuthChallenge(
      data.session,
      data.username,
      data.code
    )

    const token = response.AuthenticationResult?.IdToken
    const refreshToken = response.AuthenticationResult?.RefreshToken
    const expiration =
      Date.now() + (response.AuthenticationResult?.ExpiresIn as number) * 1000

    saveToken(token!, refreshToken!, expiration)

    await loadProfile()

    navigate('/dashboard', { replace: true })
  }

  const logOut = () => {
    dispatch(actions.clear())
    clearToken()
    navigate('/signin', { replace: true })
  }

  return {
    signUp,
    signIn,
    enterCode,
    logOut,
  }
}
