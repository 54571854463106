import clsx from 'clsx'

interface ISideMenuItemProps {
  label: string
  Icon: any
  active?: boolean
  onClick?: () => void
}

const SideMenuItem: React.FC<ISideMenuItemProps> = ({
  label,
  active,
  Icon,
  onClick,
}) => {
  return (
    <div
      className={clsx(
        'flex items-center h-10 px-2 py-2.5 gap-x-2 text-off-black/70 rounded-lg cursor-pointer',
        active ? 'shadow-md bg-white text-green' : 'hover:bg-steel/10'
      )}
      onClick={onClick}
    >
      <Icon
        className={
          active ? 'stroke-green fill-green' : 'stroke-steel fill-steel'
        }
      />
      <span>{label}</span>
    </div>
  )
}

export default SideMenuItem
