import { useQuery } from '@tanstack/react-query'
import { DEAL_TYPE } from 'types'
import { MetricsRequest } from 'types/metrics'
import { getTeamMetrics } from 'utils/api/team'
import { getMetricDateRange } from 'utils/metric'

interface IProps {
  teamId?: string
  dateFilter: 'YTD' | 'MTD'
}

export const useDashboard = ({ teamId, dateFilter }: IProps) => {
  const { startDate, endDate } = getMetricDateRange(dateFilter)
  const filters: MetricsRequest = {
    metrics: ['volume', 'volumePerUser', 'dealStageCount'],
    startDate,
    endDate,
  }

  const { data: allMetrics } = useQuery({
    queryKey: ['dashboard', teamId, { dealType: 'ALL', dateFilter }],
    queryFn: () =>
      getTeamMetrics(teamId!, {
        ...filters,
        metrics: [
          ...filters.metrics,
          'avgCommissionPercentage',
          'leadSourceCount',
        ],
      }),
    enabled: !!teamId,
  })

  const { data: buyerMetrics } = useQuery({
    queryKey: ['dashboard', teamId, { dealType: DEAL_TYPE.buyer, dateFilter }],
    queryFn: () =>
      getTeamMetrics(teamId!, { ...filters, dealType: DEAL_TYPE.buyer }),
    enabled: !!teamId,
  })

  const { data: sellerMetrics } = useQuery({
    queryKey: ['dashboard', teamId, { dealType: DEAL_TYPE.seller, dateFilter }],
    queryFn: () =>
      getTeamMetrics(teamId!, { ...filters, dealType: DEAL_TYPE.seller }),
    enabled: !!teamId,
  })

  return { allMetrics, buyerMetrics, sellerMetrics }
}
