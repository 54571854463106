import { Menu, MenuHandler, MenuList } from '@material-tailwind/react'
import { Link, useNavigate } from 'react-router-dom'

import AccountIcon from 'assets/icons/Account'
import BillingIcon from 'assets/icons/Billing'
import LogoutIcon from 'assets/icons/Logout'
import ProfileIcon from 'assets/icons/Profile'
import TeamIcon from 'assets/icons/Team'
import Avatar from 'components/Avatar'
import { Team, User } from 'types'

import { userIsTeamAgent } from 'utils/team'
import Divider from './Divider'
import ProfileMenuItem from './ProfileMenuItem'
import CaretDown from 'assets/icons/CaretDown'

interface IProps {
  profile: User
  team?: Team
  teams: Team[]
  onSetTeam: (id: string) => void
  onManageBilling: () => void
  onLogOut: () => void
}

const ProfileMenu = ({
  profile,
  team,
  teams,
  onSetTeam,
  onManageBilling,
  onLogOut,
}: IProps) => {
  const navigate = useNavigate()

  return (
    <Menu placement="bottom-end">
      <MenuHandler>
        <button className="relative flex items-center gap-2 p-0 bg-transparent">
          <Avatar user={profile} />
          <CaretDown />
        </button>
      </MenuHandler>
      <MenuList className="flex flex-col w-48 p-2 border-0 rounded shadow-md">
        <div className="flex items-center w-full h-10 px-2">
          <Link
            to={`/agent/${profile.id}`}
            className="flex items-center w-full no-underline text-off-black/70 hover:text-green visited:text-off-black/70"
          >
            <Avatar user={profile} size={20} />
            <span className="ml-2">Profile</span>
          </Link>
        </div>
        <Divider />
        <ProfileMenuItem
          text="Edit profile"
          Icon={ProfileIcon}
          onClick={() => navigate('/settings/profile')}
        />
        <ProfileMenuItem
          text="Edit account"
          Icon={AccountIcon}
          onClick={() => navigate('/settings/account')}
        />
        <ProfileMenuItem
          text="Manage team"
          Icon={TeamIcon}
          onClick={() => navigate('/settings/team')}
        />
        <ProfileMenuItem
          text="Billing"
          Icon={BillingIcon}
          onClick={onManageBilling}
        />
        {(teams.length ?? 0) > 1 && (
          <>
            <Divider />
            <div className="flex items-end px-2 h-7 text-steel">
              Use Symba as
            </div>
            {teams
              .filter(item => !userIsTeamAgent(profile, item))
              .map(item => (
                <ProfileMenuItem
                  key={item.id}
                  imageUrl={item.logoUrl}
                  text={item.name}
                  checked={team?.id === item.id}
                  onClick={() => onSetTeam(item.id)}
                />
              ))}
          </>
        )}
        <Divider />
        <ProfileMenuItem text="Log out" Icon={LogoutIcon} onClick={onLogOut} />
      </MenuList>
    </Menu>
  )
}

export default ProfileMenu
