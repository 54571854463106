import DealTypeChip from 'components/DealTypeChip/DealTypeChip'
import Screen from 'components/Screen/Screen'
import Table from 'components/Table/Table'
import { DEAL_STAGE_CONFIG } from 'constants/deal'
import { useTeam } from 'hooks'
import { usePipelineStage } from 'hooks/queries/usePipelineStage'
import { useParams } from 'react-router-dom'
import { DEAL_STAGE, DEAL_TYPE } from 'types'
import { makeColumns } from './utils'

export default function PipelineStage() {
  const { dealType, stage } = useParams()
  const { members, team } = useTeam()

  const { status, data } = usePipelineStage({
    teamId: team?.id,
    dealType: dealType!.toUpperCase(),
    stage: stage!.toUpperCase(),
  })

  const columns = makeColumns(members)

  return (
    <div className="relative py-10 border-t border-t-steel/15">
      <Screen backButton>
        <div className="px-[12.15%]">
          <DealTypeChip dealType={dealType as DEAL_TYPE} />
          <div className="flex items-center justify-start gap-2 mt-2 mb-6">
            <h2 className="font-medium text-[2rem]">
              {DEAL_STAGE_CONFIG[stage as DEAL_STAGE].name}
            </h2>
            {status === 'success' && (
              <div className="bg-steel/25 py-0.5 px-2 rounded-3xl leading-none text-sm">
                {data?.data.length}
              </div>
            )}
          </div>
          <Table columns={columns} rows={data?.data ?? []} />
        </div>
      </Screen>
    </div>
  )
}
