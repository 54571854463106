import type { StylesConfig, GroupBase } from 'react-select'

export const getReactSelectStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(): StylesConfig<Option, IsMulti, Group> => ({
  container: provided => ({
    ...provided,
    borderRadius: '8px',
  }),
  control: styles => ({
    ...styles,
    borderColor: 'rgba(162, 191, 181, 0.35)',
    '&:hover': {
      borderColor: 'rgba(162, 191, 181, 0.35)',
    },
    borderRadius: '8px',
    boxShadow: 'none',
  }),
  multiValue: styles => ({
    ...styles,
    borderRadius: '4px',
    backgroundColor: '#F7F7F7',
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: '16px',
  }),
  menu: provided => ({
    ...provided,
    padding: '4px 8px',
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused || isSelected ? '#F5F7EB' : undefined,
    color: isFocused || isSelected ? '#3AC345' : '#262626',
    cursor: 'pointer',
    borderRadius: '4px',
    height: '40px',
    '&:active': {
      backgroundColor: '#F5F7EB',
    },
  }),
  placeholder: provided => ({ ...provided, color: '#3AC345' }),
  valueContainer: provided => ({ ...provided, paddingLeft: 14 }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'rgb(162 191 181 / .35)',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#A2BFB5',
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#A2BFB5',
  }),
})
