import Avatar from 'components/Avatar'
import { Link } from 'react-router-dom'
import { Contact as IContact } from 'types'

interface IProps {
  contact: IContact
}

export default function Contact({ contact }: IProps) {
  return (
    <Link
      className="flex items-center no-underline group"
      to={`/agent/${contact.userId}/contact/${contact.id}`}
    >
      <Avatar user={contact} size={24} className="mr-2" />
      <div className="font-medium text-off-black group-hover:text-green">
        {contact.firstName} {contact.lastName}
      </div>
    </Link>
  )
}
