import { PropsWithChildren } from 'react'
import clsx from 'clsx'

interface WidgetProps {
  title: string
  subtitle?: string
  className?: string
  disabled?: boolean
  titleClassName?: string
}

export const Widget: React.FC<PropsWithChildren<WidgetProps>> = ({
  children,
  className,
  title,
  subtitle,
  disabled = false,
  titleClassName,
}) => (
  <div
    className={clsx(
      'bg-white rounded-lg p-4 shadow-md flex flex-col',
      disabled ? 'text-steel' : 'text-off-black',
      className
    )}
  >
    <div className="mb-4 grow">
      {subtitle && <h5 className="text-xs text-steel">{subtitle}</h5>}
      <h4 className={clsx('font-medium', titleClassName)}>{title}</h4>
    </div>

    {children}
  </div>
)

export default Widget
