import { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'

interface PillProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean
}

const Pill: FC<PillProps> = ({ children, className, isActive, ...props }) => (
  <button
    className={clsx(
      'text-sm rounded-2xl px-2 h-6',
      isActive ? 'bg-green-light' : 'text-off-black/70',
      className
    )}
    {...props}
  >
    {children}
  </button>
)

export default Pill
