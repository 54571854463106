import { Link } from 'react-router-dom'

import { ITeamMember, User } from 'types'
import { LeaderboardMetric } from 'types/metrics'
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from 'utils/formatters'
import { TableCol } from 'components/Table/Table'
import TeamMember from 'components/TeamMember'

import { MetricTitle } from './MetricTitle'

export const makeColumns = (
  sort: LeaderboardMetric,
  setSort: (sort: LeaderboardMetric) => void
): TableCol[] => [
  {
    name: 'name',
    title: 'Name',
    headerClassName: 'pl-9',
    cellClassName: 'pl-0 flex items-center gap-x-1 font-medium',
    renderCell: ({ row }) => (
      <TeamMember member={row as unknown as ITeamMember} />
    ),
  },
  {
    name: 'volumePerUser.value',
    title: (
      <MetricTitle
        title="Volume"
        metric="volumePerUser"
        sort={sort}
        setSort={setSort}
      />
    ),
    renderCell: ({ value }) => formatCurrency(value as number),
  },
  {
    name: 'closedDealCountPerUser.value',
    title: (
      <MetricTitle
        title="Total deals closed"
        metric="closedDealCountPerUser"
        sort={sort}
        setSort={setSort}
      />
    ),
    renderCell: ({ value }) => formatNumber(value as number),
  },
  {
    name: 'avgCommissionPercentagePerUser.value',
    title: (
      <MetricTitle
        title="Commission %"
        metric="avgCommissionPercentagePerUser"
        sort={sort}
        setSort={setSort}
      />
    ),
    renderCell: ({ value }) => formatPercentage(value as number),
  },
  {
    name: 'action',
    title: '',
    renderCell: ({ row }) => (
      <Link
        className="flex items-center space-x-2 no-underline group"
        to={`/agent/${(row.user as User).id}`}
      >
        View profile
      </Link>
    ),
  },
]
