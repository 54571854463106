import { BUYER_DEAL_STAGES, SELLER_DEAL_STAGES } from 'constants/deal'
import { useTeam } from 'hooks'
import { useGetDeals } from 'hooks/queries/useGetDeals'
import { groupBy, isEmpty, keys } from 'lodash'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { useParams } from 'react-router-dom'
import { DEAL_STAGE, DEAL_TYPE } from 'types'
import { getCurrentDealStage, getCurrentDealStageName } from 'utils/deal'
import AccountCard from './AccountCard'
import DealCard from './DealCard'
import DealStageCard from './DealStageCard'
import MetricsCard from './MetricsCard'

export default function AgentProfile() {
  const { agentId } = useParams()
  const { updateCommission, activeUsers } = useTeam()

  const { data } = useGetDeals(agentId!)
  const agent = activeUsers.find(user => user.id === agentId)

  if (!agent) {
    return null
  }

  const buyerDeals = groupBy(
    data?.data.filter(deal => deal.type === DEAL_TYPE.buyer),
    getCurrentDealStageName
  )
  const sellerDeals = groupBy(
    data?.data.filter(deal => deal.type === DEAL_TYPE.seller),
    getCurrentDealStageName
  )
  const closedDeals = groupBy(
    data?.data.filter(
      deal => getCurrentDealStageName(deal) === DEAL_STAGE.saleClosed
    ),
    deal => {
      const currentStage = getCurrentDealStage(deal)
      return DateTime.fromMillis(currentStage!.timestamp).year
    }
  )

  return (
    <div className="flex items-start justify-center px-44">
      <div className="flex justify-between w-[1440px]">
        <AccountCard user={agent} onUpdateCommission={updateCommission} />
        <div className="basis-[70.5%] w-full">
          <MetricsCard user={agent} />

          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="mb-4 text-2xl font-medium">Buyer</h3>
              {BUYER_DEAL_STAGES.filter(
                stageName => stageName !== DEAL_STAGE.saleClosed
              ).map(stageName => (
                <DealStageCard
                  key={stageName}
                  stageName={stageName}
                  deals={buyerDeals[stageName]}
                />
              ))}
            </div>
            <div>
              <h3 className="mb-4 text-2xl font-medium">Seller</h3>
              {SELLER_DEAL_STAGES.filter(
                stageName => stageName !== DEAL_STAGE.saleClosed
              ).map(stageName => (
                <DealStageCard
                  key={stageName}
                  stageName={stageName}
                  deals={sellerDeals[stageName]}
                />
              ))}
            </div>
          </div>

          {!isEmpty(closedDeals) && (
            <div className="mt-10">
              <div className="text-2xl font-medium">Closed deals</div>
              {keys(closedDeals)
                .sort((a, b) => Number(b) - Number(a))
                .map(year => (
                  <div key={year} className="mt-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="text-steel">{year}</div>
                      <div className="text-sm text-off-black/60">
                        {numeral(closedDeals[year].length).format('00')}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                      {closedDeals[year].map(deal => (
                        <DealCard key={deal.id} deal={deal} />
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
