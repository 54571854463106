import { BUYER_DEAL_STAGES, SELLER_DEAL_STAGES } from 'constants/deal'
import { useTeam } from 'hooks'
import { usePipeline } from 'hooks/queries/usePipeline'
import { useNavigate } from 'react-router-dom'
import { DEAL_STAGE, DEAL_TYPE } from 'types'
import PipelineStageCard from './PipelineStageCard'

export default function Pipeline() {
  const navigate = useNavigate()
  const { team, activeUsers } = useTeam()
  const { buyerPipeline, sellerPipeline } = usePipeline({ teamId: team?.id })

  return (
    <div
      className="px-[12.15%] py-10"
      style={{
        background: 'linear-gradient(180deg, #F5F7EB 6.2%, #FFFFFF 52.61%)',
      }}
    >
      <div className="max-w-[1440px] mx-auto">
        <h2 className="font-medium text-[2rem] mb-10">Live pipeline</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="mb-4 text-2xl font-medium">Buyer</h3>
            {BUYER_DEAL_STAGES.filter(
              stage => stage !== DEAL_STAGE.saleClosed
            ).map(stage => (
              <PipelineStageCard
                onClick={() =>
                  navigate(`/pipeline/${DEAL_TYPE.buyer}/${stage}`)
                }
                stage={stage}
                data={buyerPipeline.filter(
                  item => item._id.currentStage === stage
                )}
                users={activeUsers}
              />
            ))}
          </div>
          <div>
            <h3 className="mb-4 text-2xl font-medium">Seller</h3>
            {SELLER_DEAL_STAGES.filter(
              stage => stage !== DEAL_STAGE.saleClosed
            ).map(stage => (
              <PipelineStageCard
                onClick={() =>
                  navigate(`/pipeline/${DEAL_TYPE.seller}/${stage}`)
                }
                stage={stage}
                data={sellerPipeline.filter(
                  item => item._id.currentStage === stage
                )}
                users={activeUsers}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
