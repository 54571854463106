const MobileIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
    <rect
      x="2.60547"
      y="16.3418"
      width="15.8421"
      height="11.6316"
      rx="2.02632"
      transform="rotate(-90 2.60547 16.3418)"
      fill="#D5E0DC"
      stroke="#A2BFB5"
    />
    <rect x="7" y="2" width="3" height="1" rx="0.5" fill="#A2BFB5" />
  </svg>
)

export default MobileIcon
