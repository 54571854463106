import { useQuery } from '@tanstack/react-query'
import { getTeamPipelineStage } from 'utils/api/team'

interface IProps {
  teamId?: string
  dealType: string
  stage: string
}

export const usePipelineStage = ({ teamId, dealType, stage }: IProps) => {
  return useQuery({
    queryKey: ['pipelineStage', teamId, { dealType, stage }],
    queryFn: () => getTeamPipelineStage(teamId!, dealType, stage),
    enabled: !!teamId,
  })
}
