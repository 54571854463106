import { yupResolver } from '@hookform/resolvers/yup'
import ArrowRight from 'assets/icons/ArrowRight'
import Button from 'components/Button/Button'
import DeleteButton from 'components/Button/DeleteButton'
import AddressInput from 'components/Input/AddressInput'
import DateInput from 'components/Input/DateInput'
import OptionSelect from 'components/Input/OptionSelect'
import {
  COMMISSION_TYPE_OPTIONS,
  LEAD_SOURCE_OPTIONS,
  REFERRAL_SOURCE_TYPE_OPTIONS,
} from 'constants/deal'
import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import {
  Control,
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form'
import {
  Commission,
  COMMISSION_TYPE,
  DealRequest,
  IDealStage,
  REFERRAL_SOURCE_TYPE,
  Contact,
  SelectOption,
} from 'types'
import * as yup from 'yup'
import Select from 'react-select'
import FormControl from '../FormControl'
import FormLabel from '../FormLabel'
import PriceInput from 'components/Input/PriceInput'
import FormError from '../FormError'
import PercentageInput from 'components/Input/PercentageInput'
import FormControlRow from '../FormControlRow'
import { getReactSelectStyles } from 'components/Input/styles'

interface IDealStageForm {
  timestamp: number
  address?: string
  price?: number
  commissionType: COMMISSION_TYPE
  rate?: number
  flatFee?: number
  mySplit: number | string
  brokerageSplit: number | string
  referralFeeType: COMMISSION_TYPE
  referralFeeRate?: number
  referralFeeFlatFee?: number
  referralSourceType: REFERRAL_SOURCE_TYPE
  referralSourceLead?: string
  referralSourceContactId?: string
}

interface IProps {
  contacts: Contact[]
  isSimple?: boolean
  stage: IDealStage
  commission?: Commission
  onSubmit: (data: DealRequest) => void
  isSubmitting?: boolean
}

const SimpleDealStageFormSchema = yup.object().shape({
  timestamp: yup.number().required('Date is required'),
})

const DealStageFormSchema = SimpleDealStageFormSchema.shape({
  address: yup.string().required('Address is required'),
  price: yup
    .number()
    .required('Price is required')
    .positive('Price must be greater than 0'),
})

export default function DealStageForm({
  contacts,
  isSimple,
  stage,
  commission,
  onSubmit,
  isSubmitting,
}: IProps) {
  const [hasReferralFee, setHasRefrralFee] = useState(
    Boolean(commission?.referralFee)
  )
  const [hasReferralSource, setHasRefrralSource] = useState(
    Boolean(commission?.referralSource)
  )

  const { control, setValue, handleSubmit } = useForm<IDealStageForm>({
    resolver: yupResolver(
      isSimple ? SimpleDealStageFormSchema : DealStageFormSchema
    ),
    defaultValues: {
      timestamp: stage.timestamp,
      address: stage.address,
      price: stage.price,
      commissionType: commission?.type ?? COMMISSION_TYPE.percentage,
      rate: commission?.rate ?? 3,
      flatFee: commission?.flatFee,
      mySplit: commission?.mySplit ?? 100,
      brokerageSplit: commission?.brokerageSplit ?? 0,
      referralFeeType:
        commission?.referralFee?.type ?? COMMISSION_TYPE.percentage,
      referralFeeRate: commission?.referralFee?.rate,
      referralFeeFlatFee: commission?.referralFee?.flatFee,
      referralSourceType:
        commission?.referralSource?.type ?? REFERRAL_SOURCE_TYPE.leadSource,
      referralSourceLead: commission?.referralSource?.lead,
      referralSourceContactId: commission?.referralSource?.contactId,
    },
  })

  const commissionType = useWatch({ control, name: 'commissionType' })
  const referralFeeType = useWatch({ control, name: 'referralFeeType' })
  const referralSourceType = useWatch({ control, name: 'referralSourceType' })

  const sortedContacts = useMemo(() => {
    const cleaned = contacts
      .map(c => ({
        ...c,
        firstName: c.firstName.trim(),
        lastName: c.lastName.trim(),
        name: c.name.trim(),
      }))
      .filter(c => !!c.name)
    return sortBy(cleaned, ['lastName', 'firstName'])
  }, [contacts])

  const contactOptions = sortedContacts.map(contact => ({
    value: contact.id,
    label: contact.name,
  }))

  const save: SubmitHandler<IDealStageForm> = async (data: IDealStageForm) => {
    const payload = {
      stage: {
        stage: stage.stage,
        timestamp: data.timestamp!,
        address: data.address,
        price: data.price,
      },
      commission: {
        type: data.commissionType,
        ...(data.commissionType === COMMISSION_TYPE.percentage
          ? { rate: data.rate }
          : { flatFee: data.flatFee }),
        mySplit: parseInt(data.mySplit as string),
        brokerageSplit: parseInt(data.brokerageSplit as string),
        referralFee: !hasReferralFee
          ? undefined
          : {
              type: data.referralFeeType,
              ...(data.referralFeeType === COMMISSION_TYPE.percentage
                ? { rate: data.referralFeeRate }
                : { flatFee: data.referralFeeFlatFee }),
            },
        referralSource: !hasReferralSource
          ? undefined
          : {
              type: data.referralSourceType,
              ...(data.referralSourceType === REFERRAL_SOURCE_TYPE.leadSource
                ? { lead: data.referralSourceLead }
                : { contactId: data.referralSourceContactId }),
            },
      },
    }

    onSubmit(payload)
  }

  return (
    <form
      noValidate
      className="flex flex-col gap-y-4"
      onSubmit={handleSubmit(save)}
    >
      <DateInput
        control={control as unknown as Control<FieldValues>}
        name="timestamp"
        label="Date"
        required
      />
      {!isSimple && (
        <>
          <AddressInput
            control={control as unknown as Control<FieldValues>}
            name="address"
            label="Address"
            placeholder="Address"
            required
          />
          <Controller
            name="price"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl>
                <FormLabel>Price</FormLabel>
                <PriceInput value={value} onChange={onChange} error={error} />
                {error && <FormError>{error.message}</FormError>}
              </FormControl>
            )}
          />
          <FormControl>
            <FormLabel>Comission</FormLabel>
            <Controller
              name="commissionType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <OptionSelect
                  value={value}
                  onChange={onChange}
                  options={COMMISSION_TYPE_OPTIONS}
                />
              )}
            />
          </FormControl>

          <div className="flex items-center">
            {commissionType === COMMISSION_TYPE.percentage && (
              <Controller
                name="rate"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PercentageInput value={value} onChange={onChange} />
                )}
              />
            )}

            {commissionType === COMMISSION_TYPE.flatFee && (
              <Controller
                name="flatFee"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PriceInput value={value} onChange={onChange} />
                )}
              />
            )}
          </div>

          <FormControlRow>
            <FormControl>
              <FormLabel>Agent split</FormLabel>
              <Controller
                name="mySplit"
                control={control}
                render={({ field: { value } }) => (
                  <PercentageInput
                    value={value}
                    onChange={value => {
                      setValue('mySplit', value ?? '', { shouldDirty: true })
                      setValue('brokerageSplit', 100 - (value ?? 0))
                    }}
                  />
                )}
              />
            </FormControl>

            <div className="mt-[30px]">
              <ArrowRight />
            </div>

            <FormControl>
              <FormLabel>Team split</FormLabel>
              <Controller
                name="brokerageSplit"
                control={control}
                render={({ field: { value } }) => (
                  <PercentageInput
                    value={value}
                    onChange={value => {
                      setValue('brokerageSplit', value ?? '', {
                        shouldDirty: true,
                      })
                      setValue('mySplit', 100 - (value ?? 0))
                    }}
                  />
                )}
              />
            </FormControl>
          </FormControlRow>

          <FormControl>
            <div className="flex items-center justify-between">
              <FormLabel>Referral fee</FormLabel>
              {hasReferralFee && (
                <DeleteButton onClick={() => setHasRefrralFee(false)} />
              )}
            </div>

            {!hasReferralFee && (
              <Button
                theme="secondary"
                onClick={() => setHasRefrralFee(true)}
                className="w-1/2"
              >
                Add Referral fee
              </Button>
            )}

            {hasReferralFee && (
              <Controller
                name="referralFeeType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <OptionSelect
                    value={value}
                    onChange={onChange}
                    options={COMMISSION_TYPE_OPTIONS}
                  />
                )}
              />
            )}
          </FormControl>

          {hasReferralFee && (
            <div className="flex items-center">
              {referralFeeType === COMMISSION_TYPE.percentage && (
                <Controller
                  name="referralFeeRate"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <PercentageInput value={value} onChange={onChange} />
                  )}
                />
              )}
              {referralFeeType === COMMISSION_TYPE.flatFee && (
                <Controller
                  name="referralFeeFlatFee"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <PriceInput value={value} onChange={onChange} />
                  )}
                />
              )}
            </div>
          )}

          <FormControl>
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium">Referral source</label>
              {hasReferralSource && (
                <DeleteButton onClick={() => setHasRefrralSource(false)} />
              )}
            </div>

            {!hasReferralSource && (
              <Button
                theme="secondary"
                onClick={() => setHasRefrralSource(true)}
                className="w-1/2"
              >
                Add Referral source
              </Button>
            )}

            {hasReferralSource && (
              <Controller
                name="referralSourceType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <OptionSelect
                    value={value}
                    onChange={onChange}
                    options={REFERRAL_SOURCE_TYPE_OPTIONS}
                  />
                )}
              />
            )}
          </FormControl>

          {hasReferralSource && (
            <div>
              {referralSourceType === REFERRAL_SOURCE_TYPE.leadSource && (
                <Controller
                  name="referralSourceLead"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select<SelectOption>
                        value={LEAD_SOURCE_OPTIONS.find(
                          op => op.value === value
                        )}
                        onChange={option => onChange(option?.value ?? '')}
                        options={LEAD_SOURCE_OPTIONS}
                        isClearable
                        isSearchable={false}
                        placeholder="Select lead source"
                        styles={getReactSelectStyles()}
                      />
                    )
                  }}
                />
              )}
              {referralSourceType === REFERRAL_SOURCE_TYPE.contact && (
                <Controller
                  name="referralSourceContactId"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select<SelectOption>
                        value={contactOptions.find(op => op.value === value)}
                        onChange={option => onChange(option?.value ?? '')}
                        options={contactOptions}
                        isClearable
                        placeholder="Search contacts"
                        styles={getReactSelectStyles()}
                      />
                    )
                  }}
                />
              )}
            </div>
          )}
        </>
      )}
      <div className="flex justify-end mt-8">
        <Button type="submit" loading={isSubmitting} className="w-1/2">
          Save
        </Button>
      </div>
    </form>
  )
}
