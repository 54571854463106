import { FC, ReactNode } from 'react'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import Button from 'components/Button/Button'

export interface ConfirmDialogProps {
  open: boolean
  title: ReactNode
  description: ReactNode
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  title,
  description,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
  onCancel,
}) => (
  <Dialog
    open={open}
    size="sm"
    dismiss={{ enabled: true, escapeKey: true }}
    handler={onCancel}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogBody className="py-0 leading-tight text-off-black/70">
      {description}
    </DialogBody>
    <DialogFooter>
      <Button theme="tertiary" onClick={onCancel} className="w-40 mr-2">
        {cancelButtonText}
      </Button>
      <Button theme="primary" onClick={onConfirm} className="w-40">
        {confirmButtonText}
      </Button>
    </DialogFooter>
  </Dialog>
)

export default ConfirmDialog
