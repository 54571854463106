import CheckIcon from 'assets/icons/Check'
import clsx from 'clsx'
import Button from 'components/Button/Button'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Screen from 'components/Screen/Screen'
import { capitalize } from 'lodash'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { StripePrice } from 'types/stripe'
import { getPricing } from 'utils/api/billing'
import * as userApi from 'utils/api/user'

const BILLING_PLANS = ['monthly', 'yearly'] as const
type BillingPlan = (typeof BILLING_PLANS)[number]

export default function AgentPricing() {
  const [selectedPlan, setSelectedPlan] = useState<BillingPlan>('yearly')
  const [prices, setPrices] = useState<StripePrice[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const getPrices = async () => {
      const lookupKeys = ['agent_monthly', 'agent_yearly']
      const res = await getPricing(lookupKeys)
      setPrices(res.data.data)
    }
    getPrices()
  }, [])

  const getDisplayPrice = (price: StripePrice, plan: BillingPlan) => {
    return plan === 'yearly'
      ? price.unit_amount / 100 / 12
      : price.unit_amount / 100
  }

  const getDiscount = () => {
    const monthlyPrice = prices.find(
      price => price.lookup_key === 'agent_monthly'
    )
    const yearlyPrice = prices.find(
      price => price.lookup_key === 'agent_yearly'
    )
    const original = monthlyPrice?.unit_amount! * 12
    const discounted = yearlyPrice?.unit_amount!

    return (original - discounted) / original
  }

  const handleSubmit = async () => {
    const price = prices.find(
      price => price.lookup_key === `agent_${selectedPlan}`
    )
    if (price) {
      setIsSubmitting(true)
      const res = await userApi.checkout(price.id)
      window.location.href = res.data.url
    }
  }

  return (
    <Screen backButton>
      <div className="flex flex-col items-center">
        <div className="w-[426px]">
          <ProgressBar value={75} />
          <h1 className="font-medium text-[2rem] leading-8 mb-10">
            Select a pricing plan to start your free trial
          </h1>

          <div className="grid grid-cols-2 mb-4 gap-x-2">
            {BILLING_PLANS.map(plan => {
              const price = prices.find(
                price => price.lookup_key === `agent_${plan}`
              )
              if (!price) {
                return null
              }
              const isSelected = plan === selectedPlan
              return (
                <div
                  key={plan}
                  className={clsx(
                    'flex flex-col justify-between h-[135px] p-4 border border-steel/25 rounded-2xl',
                    isSelected ? 'shadow-md' : 'cursor-pointer hover:bg-tan/50'
                  )}
                  onClick={() => setSelectedPlan(plan)}
                >
                  <div className="flex items-center justify-between">
                    <div className="text-sm text-steel">{capitalize(plan)}</div>
                    {plan === 'yearly' && (
                      <div
                        className={clsx(
                          'px-2 text-sm text-white bg-blue rounded-2xl',
                          selectedPlan !== 'yearly' && 'opacity-50'
                        )}
                      >
                        {`SAVE ${numeral(getDiscount()).format('0%')}`}
                      </div>
                    )}
                    {isSelected && <CheckIcon />}
                  </div>
                  <div>
                    <div className="font-medium">
                      {numeral(getDisplayPrice(price, plan)).format('$0,0.00')}{' '}
                      <span className="text-sm font-normal text-off-black/70">
                        / mo
                      </span>
                    </div>
                    <div className="text-xs text-off-black/70">
                      Billed {plan}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="mb-10 text-sm text-off-black/70">
            No commitments, cancel any time.
          </div>

          <div className="flex justify-end">
            <Button
              loading={isSubmitting}
              disabled={!selectedPlan}
              onClick={handleSubmit}
              className="w-1/2"
            >
              Start {process.env.REACT_APP_TRIAL_PERIOD_DAYS} day free trial
            </Button>
          </div>
        </div>
      </div>
    </Screen>
  )
}
