import Button from 'components/Button/Button'
import { useProfile, useTeam } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import successImage from 'assets/images/success.png'

const NUM_ATTEMPTS = 5

export default function TeamComplete() {
  const navigate = useNavigate()
  const { loadProfile, me } = useProfile()
  const { team } = useTeam()
  const [attempts, setAttempts] = useState(0)

  useEffect(() => {
    if (team && !team.subscription && attempts < NUM_ATTEMPTS) {
      loadProfile()
      setAttempts(attempts + 1)
    }
  }, [team, loadProfile, attempts])

  const handleClick = () => {
    navigate('/dashboard', { replace: true })
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-[426px]">
        <div className="flex items-center justify-center mb-10">
          <img src={successImage} className="w-[88px]" alt="success" />
        </div>
        <div className="text-sm text-steel">Welcome {me?.firstName}</div>
        <div className="font-medium text-[2rem] leading-8 mb-6">
          Your Symba free trial has started
        </div>
        <div className="mb-10 text-off-black/70">
          You will not be charged until after your free trial has ended.
        </div>
        <div className="flex justify-end">
          <Button
            disabled={!team?.subscription}
            onClick={handleClick}
            className="w-1/2"
          >
            Go to my dashboard
          </Button>
        </div>
      </div>
    </div>
  )
}
