import React from 'react'
import { Link } from 'react-router-dom'

import { ITeamMember } from 'types'
import Avatar from 'components/Avatar'
import Chip from 'components/Chip'

interface TeamMemberProps {
  member: ITeamMember
}

const TeamMember: React.FC<TeamMemberProps> = ({ member }) => {
  const name = `${member.user.firstName} ${member.user.lastName}`
  const isSignedUp = member.invitation.status === 'ACCEPTED'

  const content = (
    <>
      {<Avatar user={member.user} />}

      <span
        className={
          isSignedUp
            ? 'text-off-black group-hover:text-green'
            : 'text-off-black/50'
        }
      >
        {name}
      </span>
    </>
  )

  return isSignedUp ? (
    <Link
      className="flex items-center no-underline gap-x-2 group"
      to={`/agent/${member.user.id}`}
    >
      {content}
    </Link>
  ) : (
    <div className="flex items-center gap-x-2">
      {content}
      <Chip>Pending</Chip>
    </div>
  )
}

export default TeamMember
