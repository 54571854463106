const DesktopIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <rect
      x="0.5"
      y="0.5"
      width="15.8421"
      height="11.6316"
      rx="2.02632"
      fill="#D5E0DC"
      stroke="#A2BFB5"
    />
    <path
      d="M6.4001 12.6318H10.4422L11.7896 14.316H5.05273L6.4001 12.6318Z"
      fill="#D5E0DC"
    />
    <rect
      x="4.21045"
      y="14.3154"
      width="8.42105"
      height="1.68421"
      rx="0.842105"
      fill="#A2BFB5"
    />
  </svg>
)

export default DesktopIcon
