import numeral from 'numeral'
import { isUndefined } from 'lodash'

export const formatPhoneNumber = (phone: string) => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const formatNumber = (value?: number) =>
  isUndefined(value) ? '\u2014' : numeral(value).format('0,0')

export const formatCurrency = (value?: number) =>
  isUndefined(value) ? '\u2014' : numeral(value).format('$0,0')

export const formatPercentage = (value?: number) =>
  isUndefined(value) ? '\u2014' : `${numeral(value).format('0.[00]')}%`
