type Props = {
  children: string
}

export default function DollarAmount({ children }: Props) {
  return (
    <div className="flex items-center py-2 pl-2 pr-4 rounded-lg bg-steel/15 gap-x-2">
      <span className="font-medium text-steel">$</span>
      <span>{children}</span>
    </div>
  )
}
