import { useQuery } from '@tanstack/react-query'
import { MetricsRequest } from 'types/metrics'
import { getUserMetrics } from 'utils/api/user'
import { getMetricDateRange } from 'utils/metric'

interface IProps {
  userId: string
  dateFilter: 'YTD' | 'MTD'
}

export const useAgentMetrics = ({ userId, dateFilter }: IProps) => {
  const { startDate, endDate } = getMetricDateRange(dateFilter)
  const filters: MetricsRequest = {
    metrics: [
      'volume',
      'commission',
      'dealStageCount',
      'avgCommissionPercentage',
    ],
    startDate,
    endDate,
  }

  return useQuery({
    queryKey: ['agentMetrics', userId, { dateFilter }],
    queryFn: () => getUserMetrics(userId, filters),
  })
}
