import clsx from 'clsx'
import { InputHTMLAttributes } from 'react'
import { FieldValues } from 'react-hook-form'

import BaseInput, { BaseInputProps } from './BaseInput'

type TextInputProps = Omit<BaseInputProps<FieldValues>, 'render'> &
  InputHTMLAttributes<HTMLInputElement>

const TextInput = ({
  control,
  name,
  label,
  required,
  className,
  ...inputProps
}: TextInputProps) => (
  <BaseInput
    control={control}
    name={name}
    label={label}
    required={required}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <input
        {...inputProps}
        aria-invalid={error ? 'true' : 'false'}
        className={clsx(
          'h-10 px-4 py-2 font-normal border rounded-lg outline-none border-steel/35 focus:shadow-sm',
          className
        )}
        value={value}
        onChange={onChange}
      />
    )}
  />
)

export default TextInput
