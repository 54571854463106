interface IProps {
  className?: string
}

const DashboardIcon = ({ className = 'fill-steel' }: IProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.67027 3.36028C6.70694 3.43491 6.73116 3.51484 6.742 3.597L6.94246 6.57749L7.04196 8.07556C7.04299 8.22961 7.06716 8.38268 7.11369 8.52981C7.23387 8.81528 7.52297 8.99672 7.83751 8.98407L12.6303 8.67057C12.8379 8.66714 13.0383 8.74477 13.1875 8.88636C13.3118 9.00435 13.3921 9.1587 13.4174 9.32471L13.4259 9.42552C13.2276 12.1719 11.2105 14.4626 8.46983 15.0539C5.72917 15.6452 2.91875 14.3961 1.56443 11.9847C1.17399 11.2841 0.930119 10.5141 0.847132 9.71983C0.812465 9.4847 0.7972 9.2472 0.801486 9.00966C0.797206 6.06524 2.89398 3.51969 5.82908 2.90602C6.18234 2.85102 6.52864 3.03803 6.67027 3.36028Z"
      stroke="transparent"
    />
    <path
      d="M8.63082 0.800394C11.9137 0.883913 14.6728 3.24457 15.2039 6.42418L15.1988 6.44764L15.1843 6.48177L15.1863 6.57542C15.1788 6.6995 15.1309 6.81889 15.0483 6.91532C14.9623 7.01577 14.8448 7.08418 14.7154 7.11073L14.6365 7.12156L9.10684 7.47984C8.92291 7.49798 8.73977 7.43867 8.60298 7.31667C8.48899 7.21499 8.41612 7.07775 8.39553 6.92986L8.02438 1.4082C8.01792 1.38953 8.01792 1.36929 8.02438 1.35062C8.02945 1.19842 8.09644 1.05455 8.2104 0.951157C8.32435 0.847767 8.47577 0.793469 8.63082 0.800394Z"
      fillOpacity="0.4"
      stroke="transparent"
    />
  </svg>
)

export default DashboardIcon
