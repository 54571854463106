interface IProps {
  className?: string
}

const ProfileIcon = ({ className }: IProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect opacity="0.35" x="2" y="2" width="16" height="16" rx="4" />
    <path d="M6 10.5L14 10.5" strokeLinecap="round" />
    <path d="M6 13.5L14 13.5" strokeLinecap="round" />
    <circle cx="7.5" cy="6.5" r="2" fill="white" stroke="transparent" />
  </svg>
)

export default ProfileIcon
