import { last } from 'lodash'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import { COMMISSION_TYPE, Deal, DEAL_STAGE, LeanDeal } from 'types'
import { MetricValue } from 'types/metrics'

export const getDealStageCount = (
  stageName: DEAL_STAGE,
  stageCounts?: MetricValue[]
) => stageCounts?.find(stage => stage._id === stageName)?.value ?? 0

export const getCurrentDealStage = (deal: Deal | LeanDeal) => last(deal.stages)

export const getCurrentDealStageName = (deal: Deal | LeanDeal) =>
  getCurrentDealStage(deal)?.stage

export const getStartDate = (
  deal: Deal,
  format: Intl.DateTimeFormatOptions = DateTime.DATE_MED
) => {
  const appointmentSetStage = deal.stages.find(
    stage => stage.stage === DEAL_STAGE.appointmentSet
  )
  return !!appointmentSetStage
    ? DateTime.fromMillis(appointmentSetStage.timestamp).toLocaleString(format)
    : '\u2014'
}

export const getGrossCommission = (deal: Deal) => {
  if (deal.commission?.type === COMMISSION_TYPE.flatFee) {
    return deal.commission?.flatFee
  } else {
    const currentStage = getCurrentDealStage(deal)
    const { price } = currentStage!
    if (price && deal.commission?.rate) {
      return price * (deal.commission?.rate / 100)
    }
  }
  return 0
}

export const getReferralFee = (deal: Deal) => {
  if (deal.commission?.referralFee?.type === COMMISSION_TYPE.flatFee) {
    return numeral(deal.commission.referralFee.flatFee).format('$0,0')
  } else if (
    deal.commission?.referralFee?.type === COMMISSION_TYPE.percentage
  ) {
    return `${deal.commission.referralFee.rate}%`
  }
  return '\u2014'
}

export const getCommissionPercentage = (deal: Deal) => {
  if (deal.commission?.type === COMMISSION_TYPE.percentage) {
    return `${deal.commission.rate}%`
  }
  if (deal.commission?.type === COMMISSION_TYPE.flatFee) {
    const currentStage = getCurrentDealStage(deal)
    return `${numeral(deal.commission?.flatFee! / currentStage?.price!).format(
      '0[.]00%'
    )}`
  }
  return '\u2014'
}

export const stageHasPrice = (stage: DEAL_STAGE) =>
  ![DEAL_STAGE.inCommunication, DEAL_STAGE.appointmentSet].includes(stage)
