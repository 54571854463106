import DeleteButton from 'components/Button/DeleteButton'

type Props = {
  children: React.ReactNode
  title: string
  onDelete: () => void
}

export default function Adjustment({ children, title, onDelete }: Props) {
  return (
    <div className="flex flex-col p-4 bg-tan rounded-2xl gap-y-4">
      <div className="flex items-center justify-between px-4 pb-4 -mx-4 border-b-2 border-b-white">
        <label className="text-sm font-medium">{title}</label>
        <DeleteButton onClick={onDelete} />
      </div>
      {children}
    </div>
  )
}
