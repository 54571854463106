import clsx from 'clsx'
import Widget from 'components/Widget'
import numeral from 'numeral'

interface IProps {
  avgCommissionPercentage?: number
  disabled?: boolean
}

export default function AverageCommissionWidget({
  avgCommissionPercentage,
  disabled = false,
}: IProps) {
  return (
    <Widget
      title="Commission percentage"
      subtitle="Average"
      disabled={disabled}
      className="basis-0 grow-[2]"
    >
      <div
        className={clsx(
          'text-4xl font-medium',
          disabled && 'text-off-black/35'
        )}
      >
        {numeral(avgCommissionPercentage).format('0.[00]')}%
      </div>
    </Widget>
  )
}
