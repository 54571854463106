import { keyBy } from 'lodash'
import { Team, ITeamMember, TeamMemberPayload, User } from 'types'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IState {
  teams: { [key: string]: Team }
  activeTeamId: string | null
}

export const { reducer, actions } = createSlice({
  name: 'teams',
  initialState: {
    teams: {},
    activeTeamId: window.localStorage.getItem('activeTeamId'),
  } as IState,
  reducers: {
    setTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = keyBy(action.payload, 'id')
    },

    setActiveTeamId: (state, action: PayloadAction<string>) => {
      window.localStorage.setItem('activeTeamId', action.payload)
      state.activeTeamId = action.payload
    },

    addTeam: (state, action: PayloadAction<Team>) => {
      const team = action.payload
      state.teams[team.id] = team
    },

    updateTeam: (state, action: PayloadAction<{ data: Partial<Team> }>) => {
      const { data } = action.payload
      state.teams[state.activeTeamId!] = {
        ...state.teams[state.activeTeamId!],
        ...data,
      }
    },

    inviteMember: (state, action: PayloadAction<ITeamMember>) => {
      const invitedMember = action.payload
      const team = state.teams[state.activeTeamId!]
      team.members.push(invitedMember)
    },

    updateMember: (
      state,
      action: PayloadAction<{
        memberId: string
        data: TeamMemberPayload
        teamId?: string
      }>
    ) => {
      const { memberId, data, teamId } = action.payload
      const team = state.teams[teamId ?? state.activeTeamId!]

      team.members = team.members.map(m =>
        m._id === memberId ? { ...m, ...data } : m
      )
    },

    updateMemberUser: (
      state,
      action: PayloadAction<{ userId: string; data: Partial<User> }>
    ) => {
      const { userId, data } = action.payload
      const team = state.teams[state.activeTeamId!]

      team.members = team.members.map(m =>
        m.userId === userId ? { ...m, user: { ...m.user, ...data } } : m
      )
    },

    deleteMember: (state, action: PayloadAction<{ memberId: string }>) => {
      const { memberId } = action.payload
      const team = state.teams[state.activeTeamId!]

      team.members = team.members.filter(m => m._id !== memberId)
    },
  },
})

export default reducer
