import { CognitoIdentityServiceProvider } from 'aws-sdk'

const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID as string
const AWS_REGION = process.env.REACT_APP_AWS_REGION as string

class Cognito {
  cognito: CognitoIdentityServiceProvider

  constructor() {
    this.cognito = new CognitoIdentityServiceProvider({
      region: AWS_REGION,
    })
  }

  async initiateAuth(phone: string) {
    return await this.cognito
      .initiateAuth({
        AuthFlow: 'CUSTOM_AUTH',
        ClientId: COGNITO_CLIENT_ID,
        AuthParameters: {
          USERNAME: phone,
        },
      })
      .promise()
  }

  async respondToAuthChallenge(
    session: string,
    username: string,
    code: string
  ) {
    return await this.cognito
      .respondToAuthChallenge({
        ChallengeName: 'CUSTOM_CHALLENGE',
        ClientId: COGNITO_CLIENT_ID,
        Session: session,
        ChallengeResponses: {
          USERNAME: username,
          ANSWER: code,
        },
      })
      .promise()
  }

  async refreshToken(refreshToken: string) {
    return await this.cognito
      .initiateAuth({
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        ClientId: COGNITO_CLIENT_ID,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      })
      .promise()
  }
}

export default Cognito
