import Button from 'components/Button/Button'
import TeamAvatar from 'components/TeamAvatar/TeamAvatar'
import { Team, User } from 'types'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useTeam } from 'hooks'
import { useState } from 'react'

interface IProps {
  me: User
  team: Team
  teams: Team[]
}

export default function AcceptInviteModal({ me, team, teams }: IProps) {
  const navigate = useNavigate()
  const { acceptInvite } = useTeam()
  const [loading, setLoading] = useState(false)

  const handleAcceptInvite = async () => {
    setLoading(true)
    try {
      const member = team.members.find(m => m.userId === me.id)
      if (member) {
        const { _id, token } = member.invitation
        await acceptInvite(_id, token, member)
        navigate('/dashboard', { replace: true })
        toast.success('Invite accepted!')
      }
    } catch (err) {
      toast.error('There was an error accepting your invitation.')
    }
    setLoading(false)
  }

  return (
    <Modal>
      <div className="w-[425px] mt-32">
        <div className="flex flex-col items-center justify-center mb-4">
          <TeamAvatar team={team} size={80} />
          <div className="mt-2 font-medium">{team.name}</div>
        </div>
        <div className="text-sm text-steel">New invite</div>
        <div className="font-medium text-[2rem] leading-8">
          You have been invited to join {team.name}
        </div>
        <div className="flex items-center justify-end mt-10">
          <Button
            onClick={handleAcceptInvite}
            loading={loading}
            className="w-1/2"
          >
            Accept invite
          </Button>
        </div>
      </div>
    </Modal>
  )
}
