import clsx from 'clsx'
import numeral from 'numeral'
import DealCard from 'routes/AgentProfile/DealCard'
import { LeanDeal } from 'types'

interface IProps {
  deals: LeanDeal[]
  title: string
  active: boolean
  className?: string
}

function EmptyStage({ active }: { active: boolean }) {
  return (
    <div
      className={clsx(
        'rounded-3xl h-10 flex justify-center items-center text-off-black/70',
        active && 'bg-tan'
      )}
    >
      No deals in this stage
    </div>
  )
}

export default function DealSection({
  deals,
  title,
  active,
  className,
}: IProps) {
  return (
    <div className={className}>
      <div className="flex items-center justify-between mb-2">
        <div className="text-steel">{title}</div>
        <div className="text-sm text-off-black/60">
          {numeral(deals.length).format('00')}
        </div>
      </div>
      {deals.length === 0 && <EmptyStage active={active} />}
      {deals.length > 0 && (
        <div
          className={clsx(
            'grid grid-cols-2 gap-2',
            active && 'bg-tan p-2 rounded-3xl'
          )}
        >
          {deals.map(deal => (
            <DealCard key={deal.id} deal={deal} />
          ))}
        </div>
      )}
    </div>
  )
}
