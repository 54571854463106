import { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'

import Spinner from '../Spinner/Spinner'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  theme?: 'primary' | 'secondary' | 'tertiary'
  height?: number
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  loading,
  theme = 'primary',
  height = 40,
  ...props
}) => {
  const themeStyles = {
    primary: {
      default: 'bg-green-light shadow-md',
      hover: 'hover:shadow-lg',
      active: 'active:shadow-sm',
    },
    secondary: {
      default: 'bg-sky',
      hover: 'hover:bg-sky-light',
      active: '',
    },
    tertiary: {
      default: 'bg-white border border-steel/35',
      hover: 'hover:bg-tan/50 hover:border hover:border-steel/35',
      active: 'active:bg-tan/50 active:border active:border-steel/35',
    },
  }
  return (
    <button
      className={clsx(
        `text-center rounded-lg text-sm font-normal transition ${themeStyles[theme].default}`,
        `hover:text-green ${themeStyles[theme].hover}`,
        'disabled:text-grey-dark disabled:bg-grey-light disabled:shadow-none',
        `active:text-off-black ${themeStyles[theme].active}`,
        className
      )}
      style={{ height }}
      disabled={disabled || loading}
      {...props}
    >
      {loading && <Spinner size="xs" className="inline-block mr-2" />}
      {children}
    </button>
  )
}

export default Button
