import ProfileForm from 'components/Forms/ProfileForm'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { useProfile, useTeam } from 'hooks'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

export default function OnboardingProfile() {
  const { me, updateMe } = useProfile()
  const { team } = useTeam()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-center">
      <div className="w-[426px]">
        {isEmpty(team) && <ProgressBar value={25} />}
        <h2 className="text-[2rem] font-medium mb-[16px]">Profile info</h2>
        <ProfileForm
          me={me}
          updateMe={updateMe}
          onSuccess={() =>
            navigate(isEmpty(team) ? '/onboarding/team' : '/dashboard')
          }
        />
      </div>
    </div>
  )
}
