import { useQuery } from '@tanstack/react-query'
import { getContacts } from 'utils/api/contact'

export const useGetContacts = (userId: string) => {
  return useQuery({
    queryKey: ['contacts', userId],
    queryFn: () => getContacts(userId),
    enabled: !!userId,
    staleTime: 1000 * 60 * 30, // 30 minutes
    cacheTime: 1000 * 60 * 60, // 1 hour
  })
}
