import { InputHTMLAttributes, useState } from 'react'
import { FieldValues } from 'react-hook-form'

import LocationIcon from 'assets/icons/Location'
import clsx from 'clsx'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import BaseInput, { BaseInputProps } from './BaseInput'

const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY

type TextInputProps = Omit<BaseInputProps<FieldValues>, 'render'> &
  InputHTMLAttributes<HTMLInputElement>

const AddressInput = ({
  control,
  name,
  label,
  required,
  className,
  placeholder,
}: TextInputProps) => {
  const [focusing, setFocusing] = useState(false)

  return (
    <BaseInput
      control={control}
      name={name}
      label={label}
      required={required}
      render={({ field: { value, onChange } }) => (
        <div
          className={clsx(
            className,
            'flex flex-col justify-center relative w-full',
            'h-10 font-normal border rounded-lg border-steel/35',
            {
              'shadow-sm': focusing,
            }
          )}
        >
          <div className="flex absolute top-[12px] left-[17px]">
            <LocationIcon />
          </div>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_PLACES_API_KEY}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['us'],
              },
            }}
            selectProps={{
              placeholder,
              defaultInputValue: value ?? '',
              value: { label: value, value: {} },
              onChange: v => onChange(v?.label),
              onFocus: () => setFocusing(true),
              onBlur: () => setFocusing(false),
              styles: {
                control: provided => ({
                  ...provided,
                  backgroundColor: 'transparent',
                  paddingLeft: 36,
                  outline: 'none',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  borderWidth: 0,
                }),
                menu: provided => ({
                  ...provided,
                  padding: '4px 8px',
                }),
                option: (provided, { isFocused, isSelected }) => ({
                  ...provided,
                  backgroundColor:
                    isFocused || isSelected ? '#F5F7EB' : undefined,
                  color: isFocused || isSelected ? '#3AC345' : '#262626',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  height: '40px',
                }),
                placeholder: provided => ({
                  ...provided,
                  color: 'rgba(38, 38, 38, 0.35)',
                }),
                indicatorsContainer: provided => ({
                  ...provided,
                  display: 'none',
                }),
                indicatorSeparator: provided => ({
                  ...provided,
                  display: 'none',
                }),
              },
            }}
          />
        </div>
      )}
    />
  )
}
export default AddressInput
