interface IProps {
  className?: string
}

const BillingIcon = ({ className }: IProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect
      x="1"
      y="3"
      width="18"
      height="14"
      rx="2"
      stroke="transparent"
      fillOpacity="0.4"
    />
    <path d="M4 14H8" stroke="white" strokeLinecap="round" />
    <rect x="1" y="6" width="18" height="2" stroke="transparent" />
  </svg>
)

export default BillingIcon
