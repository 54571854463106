import numeral from 'numeral'

import { User } from 'types'
import { MetricValue } from 'types/metrics'
import UserMetricBar from './UserMetricBar'

interface IProps {
  volume?: number
  volumePerUser?: MetricValue[]
  users: User[]
}

export default function VolumePerUserBar({
  volume,
  volumePerUser,
  users,
}: IProps) {
  return (
    <div className="bg-steel/10 h-3 rounded flex flex-row justify-between items-center p-0.5">
      {users.map(user => {
        const userVolume = volumePerUser?.find(v => v._id === user.id)
        if (userVolume && volume) {
          const percentage = (userVolume.value / volume) * 100
          return (
            <UserMetricBar
              key={user.id}
              user={user}
              value={numeral(userVolume.value).format('$0,0')}
              percentage={percentage}
            />
          )
        }
        return null
      })}
    </div>
  )
}
