export default function DateIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M3 7H13V11C13 12.1046 12.1046 13 11 13H5C3.89543 13 3 12.1046 3 11V7Z"
        fill="#A2BFB5"
      />
      <path
        opacity="0.4"
        d="M3 5C3 3.89543 3.89543 3 5 3H11C12.1046 3 13 3.89543 13 5V7H3V5Z"
        fill="#A2BFB5"
      />
      <rect x="10" y="2" width="1" height="3" rx="0.5" fill="#A2BFB5" />
      <rect x="5" y="2" width="1" height="3" rx="0.5" fill="#A2BFB5" />
    </svg>
  )
}
