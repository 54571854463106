import { Association } from 'types'
import { searchAssociations } from './api/user'

export const makeSelectOption = (association: Association) => ({
  label: association.name,
  value: association._id,
})

export const loadOptions = async (search: string) => {
  const associations = await searchAssociations(search)
  return associations.map(makeSelectOption)
}
