interface IProps {
  className?: string
}

const LogoutIcon = ({ className }: IProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        opacity="0.35"
        x="2"
        y="2"
        width="10"
        height="16"
        rx="3"
        stroke="transparent"
      />
      <path d="M7.5 10L17.5 10" strokeLinecap="round" />
      <path
        d="M16 7.5L18.5 10L16 12.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </svg>
)

export default LogoutIcon
