import numeral from 'numeral'

import {
  BUYER_DEAL_STAGES,
  DEAL_STAGE_CONFIG,
  SELLER_DEAL_STAGES,
} from 'constants/deal'

import { User } from 'types'
import { DEAL_STAGE } from 'types/deal'
import { MetricsResponse } from 'types/metrics'
import { getDealStageCount } from 'utils/deal'
import Widget from 'components/Widget'

import VolumePerUserBar from './VolumePerUserBar'
import clsx from 'clsx'

interface IProps {
  metrics: MetricsResponse | undefined
  dealType: 'BUYER' | 'SELLER'
  users: User[]
  disabled?: boolean
}

export default function DealTypeSummaryWidget({
  metrics,
  dealType,
  users,
  disabled = false,
}: IProps) {
  const dealStageList =
    dealType === 'BUYER' ? BUYER_DEAL_STAGES : SELLER_DEAL_STAGES

  const fillColor = dealType === 'BUYER' ? 'bg-green-light/20' : 'bg-green/10'

  const dealCount = getDealStageCount(
    DEAL_STAGE.inCommunication,
    metrics?.dealStageCount
  )

  const volume = metrics?.volume[0]?.value

  return (
    <Widget title="Total volume closed" disabled={disabled}>
      <div
        className={clsx(
          'mb-4 text-3xl font-medium',
          disabled && 'text-off-black/35'
        )}
      >
        {numeral(volume).format('$0,0')}
      </div>

      <VolumePerUserBar
        volume={volume}
        volumePerUser={metrics?.volumePerUser}
        users={users}
      />

      <div className="mt-4 -mx-4 -mb-4">
        {dealStageList.map(stageName => {
          const stageCount = getDealStageCount(
            stageName,
            metrics?.dealStageCount
          )
          const percentage = dealCount > 0 ? (stageCount / dealCount) * 100 : 0

          return (
            <div
              key={stageName}
              className="relative flex flex-row items-center justify-between px-4 py-2 border-b border-b-steel/25 last:border-none last:rounded-b-lg group"
            >
              <div
                className={`absolute inset-y-0 left-0 ${fillColor} group-last:rounded-bl-lg`}
                style={{ width: `${percentage}%` }}
              ></div>
              <div className="text-steel">
                {DEAL_STAGE_CONFIG[stageName].name}
              </div>
              <div
                className={clsx(
                  'text-2xl font-medium',
                  disabled && 'text-off-black/35'
                )}
              >
                {stageCount}
              </div>
            </div>
          )
        })}
      </div>
    </Widget>
  )
}
