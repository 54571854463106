import { USER_COLOR_ORDER } from 'constants/user'
import { Subscription, Team, TeamMemberInvitation, User } from 'types'

export const getAcceptedInviteTeams = (me: User, teams: Team[]) =>
  teams.filter(team => userHasInviteWithStatus(me, team, 'ACCEPTED'))

export const getPendingInviteTeams = (me: User, teams: Team[]) =>
  teams.filter(team => userHasInviteWithStatus(me, team, 'PENDING'))

export const userHasInviteWithStatus = (
  me: User,
  team: Team,
  status: TeamMemberInvitation['status']
) =>
  team.members.some(
    member => member.userId === me.id && member.invitation.status === status
  )

export const userIsTeamAgent = (me: User, team: Team) =>
  team.members.some(
    member => member.userId === me.id && member.role === 'AGENT'
  )

export const subscriptionIsActive = (subscription: Subscription) =>
  subscription.status === 'active' || subscription.status === 'trialing'

export const setUserColors = (team: Team) => ({
  ...team,
  owner: { ...team.owner, color: USER_COLOR_ORDER[0] },
  members: team.members.map((member, idx) => ({
    ...member,
    user: {
      ...member.user,
      color: USER_COLOR_ORDER[(idx + 1) % USER_COLOR_ORDER.length],
    },
  })),
})
