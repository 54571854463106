import ProfileForm from 'components/Forms/ProfileForm'
import { useProfile } from 'hooks'
import { toast } from 'react-hot-toast'

function EditProfile() {
  const { me, updateMe } = useProfile()

  const handleSuccess = () => toast.success('Profile updated!')

  return (
    <div className="max-w-[426px] mx-auto">
      <h2 className="text-[2rem] font-medium  mb-[16px]">Edit profile</h2>
      <ProfileForm me={me} updateMe={updateMe} onSuccess={handleSuccess} />
    </div>
  )
}

export default EditProfile
