import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button/Button'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import TextInput from 'components/Input/TextInput'
import {
  Control,
  FieldValues,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form'
import { Team, TeamRequest } from 'types'
import * as yup from 'yup'
import TeamLogoForm from './TeamLogoForm'

interface IProps {
  team?: Team
  onSubmit: (data: TeamRequest) => Promise<void>
  onSuccess?: () => void
}

const TeamFormSchema = yup.object().shape({
  color: yup.string().required('Team color is required'),
  name: yup.string().required('Team name is required'),
})

export default function TeamForm({ team, onSubmit, onSuccess }: IProps) {
  const {
    control,
    formState: { isSubmitting },
    setValue,
    handleSubmit,
  } = useForm<TeamRequest>({
    resolver: yupResolver(TeamFormSchema),
    defaultValues: {
      logoUrl: team?.logoUrl ?? '',
      color: team?.color ?? '#99D4FF',
      name: team?.name ?? '',
      brokerage: team?.brokerage ?? '',
    },
  })

  const logoUrl = useWatch({ control, name: 'logoUrl' })
  const color = useWatch({ control, name: 'color' })

  const save: SubmitHandler<TeamRequest> = async (data: TeamRequest) => {
    await onSubmit(data)
    onSuccess && onSuccess()
  }

  return (
    <form
      noValidate
      className="grid grid-cols-2 gap-4"
      onSubmit={handleSubmit(save)}
    >
      <div className="col-span-2">
        <TeamLogoForm
          team={{ logoUrl, color }}
          onUpload={url => setValue('logoUrl', url)}
          onDelete={() => setValue('logoUrl', undefined)}
        />
      </div>

      {!logoUrl && (
        <div className="col-span-2 mb-2">
          <ColorPicker
            selected={color!}
            onSelect={color => setValue('color', color)}
            className="ml-32"
          />
        </div>
      )}

      <div className="col-span-2">
        <TextInput
          control={control as unknown as Control<FieldValues>}
          name="name"
          label="Team name"
          placeholder="Team name"
          required
        />
      </div>

      <div className="col-span-2">
        <TextInput
          control={control as unknown as Control<FieldValues>}
          name="brokerage"
          label="Brokerage"
          placeholder="Brokerage"
        />
      </div>

      <Button
        type="submit"
        loading={isSubmitting}
        className="col-span-1 col-start-2 mt-8"
      >
        Save
      </Button>
    </form>
  )
}
