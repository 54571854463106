import DealStageForm from 'components/Forms/DealStageForm'
import { DEAL_STAGE_CONFIG } from 'constants/deal'
import { useNavigate, useParams } from 'react-router-dom'
import { DealRequest, DEAL_STAGE } from 'types'
import toast from 'react-hot-toast'
import { useGetDeal } from 'hooks/queries/useGetDeal'
import { useGetContacts } from 'hooks/queries/useGetContacts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateDeal } from 'utils/api/deal'

export default function EditDealStage() {
  const { agentId, dealId, stageId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (payload: DealRequest) =>
      updateDeal(agentId!, dealId!, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deals', agentId] })
      navigate(-1)
      toast.success('Deal stage updated')
    },
  })

  const dealQuery = useGetDeal(dealId!, agentId!)
  const contactQuery = useGetContacts(agentId!)

  if (dealQuery.isLoading || contactQuery.isLoading) {
    return null
  }

  const deal = dealQuery.data?.data!
  const contacts = contactQuery.data?.data!

  const stage = deal.stages.find(s => s.stage === stageId)

  if (!stage) {
    return null
  }

  const stageConfig = DEAL_STAGE_CONFIG[stageId as DEAL_STAGE]

  return (
    <div className="flex flex-col items-center">
      <div className="w-[426px]">
        <h2 className="mb-6 text-[2rem] font-medium text-left">
          Edit {stageConfig.name}
        </h2>
        <DealStageForm
          isSimple={!stageConfig.isEditable}
          stage={stage}
          contacts={contacts}
          commission={deal.commission}
          onSubmit={mutation.mutate}
          isSubmitting={mutation.isLoading}
        />
      </div>
    </div>
  )
}
