import React, { ReactNode } from 'react'

import AgentIcon from 'assets/icons/Agent'
import AdminIcon from 'assets/icons/Admin'

type RoleOption = 'AGENT' | 'ADMIN'

interface MemberRoleIconProps {
  role: RoleOption
}

const roleIcons: Record<RoleOption, ReactNode> = {
  AGENT: <AgentIcon />,
  ADMIN: <AdminIcon />,
}

const MemberRoleIcon: React.FC<MemberRoleIconProps> = ({ role }) => {
  const roleIcon = roleIcons[role]

  return (
    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-tan">
      {roleIcon}
    </div>
  )
}

export default MemberRoleIcon
