import { FieldValues } from 'react-hook-form'
import { capitalize } from 'lodash'
import clsx from 'clsx'

import { TeamMemberRole } from 'types'
import CheckIcon from 'assets/icons/Check'
import DesktopIcon from 'assets/icons/Desktop'
import MobileIcon from 'assets/icons/Mobile'
import MemberRoleIcon from 'components/MemberRoleIcon'

import BaseInput, { BaseInputProps } from './BaseInput'

type TeamMemberRoleSelectProps = Omit<BaseInputProps<FieldValues>, 'render'>

type RoleOption = 'AGENT' | 'ADMIN'

interface RoleRadioProps {
  description: string
  mobileAccess?: boolean
  desktopAccess?: boolean
}

const RoleRadios: Record<RoleOption, RoleRadioProps> = {
  AGENT: {
    description:
      "Agent's have access to the Symba mobile app, with CRM, leaderboard, and finance features.",
    mobileAccess: true,
  },
  ADMIN: {
    description:
      "Admin's have the same capabilities as an agent plus acces to Symba web, and the ability to view the teams output and manage settings.",
    mobileAccess: true,
    desktopAccess: true,
  },
}

const TeamMemberRoleSelect = (props: TeamMemberRoleSelectProps) => (
  <BaseInput
    {...props}
    render={({ field: { value, onChange } }) => (
      <div className="flex flex-col gap-y-3">
        {Object.entries(RoleRadios).map(
          ([role, { description, mobileAccess, desktopAccess }]) => (
            <div
              key={role}
              className={clsx(
                'relative flex p-4 border border-steel/25 rounded-2xl gap-x-4',
                value === role ? 'shadow-md' : 'cursor-pointer hover:bg-tan/50'
              )}
              onClick={() => {
                onChange(role as TeamMemberRole)
              }}
            >
              {value === role && (
                <div className="absolute top-4 right-4">
                  <CheckIcon />
                </div>
              )}

              <MemberRoleIcon role={role as RoleOption} />
              <div className="flex-1">
                <h4 className="text-green">{capitalize(role)}</h4>
                <p className="text-sm leading-4 text-off-black/70">
                  {description}
                </p>
                <div className="flex mt-4 gap-x-4">
                  {mobileAccess && <MobileIcon />}
                  {desktopAccess && <DesktopIcon />}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    )}
  />
)

export default TeamMemberRoleSelect
