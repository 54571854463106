import { LEAD_SOURCES } from 'constants/deal'
import numeral from 'numeral'
import { COMMISSION_TYPE, IPostSplitAdjustment, ReferralSource } from 'types'

export const getReferralSource = (
  referralSource: ReferralSource,
  defaultValue = 'Referral fee'
) => {
  const { lead, contact } = referralSource

  if (lead && contact) {
    return `${contact.firstName} ${contact.lastName} (${LEAD_SOURCES[lead]})`
  }

  if (lead) {
    return LEAD_SOURCES[lead]
  }

  if (contact) {
    return `${contact.firstName} ${contact.lastName}`
  }

  return defaultValue
}

export const calculatePostSplitAdjustment = (
  adjustment: IPostSplitAdjustment,
  preSplitGci: number,
  postSplitGci: number
) => {
  if (adjustment.feeType === COMMISSION_TYPE.percentage) {
    const rate = adjustment.rate ?? 0
    if (adjustment.taken === 'PRE_SPLIT') {
      return preSplitGci * (rate / 100)
    }
    return postSplitGci * (rate / 100)
  } else {
    return adjustment.flatFee ?? 0
  }
}

export const getAdjustmentPercentage = (
  adjustment: IPostSplitAdjustment,
  preSplitGci: number,
  postSplitGci: number
) => {
  if (adjustment.taken === 'PRE_SPLIT') {
    return `${adjustment.rate || 0}% of ${numeral(preSplitGci).format(
      '$0,0.00'
    )}`
  }
  return `${adjustment.rate || 0}% of ${numeral(postSplitGci).format(
    '$0,0.00'
  )}`
}
