import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import { noop } from 'lodash'

import ConfirmDialog, { ConfirmDialogProps } from 'components/ConfirmDialog'

interface ConfirmProviderProps {
  children: ReactNode
}

interface ConfirmContextValue {
  confirm: (
    title: ConfirmDialogProps['title'],
    description: ConfirmDialogProps['description']
  ) => Promise<boolean>
}

type ConfirmDialogState = Pick<
  ConfirmDialogProps,
  'open' | 'title' | 'description'
>

const ConfirmContext = createContext<ConfirmContextValue>({
  confirm: () => Promise.resolve(true),
})

export const useConfirm = (): ConfirmContextValue => useContext(ConfirmContext)

export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }) => {
  const resolveConfirm = useRef<Function>(noop)
  const [state, setState] = useState<ConfirmDialogState>({
    open: false,
    title: '',
    description: '',
  })

  const close = () => {
    setState({
      open: false,
      title: '',
      description: '',
    })
  }

  const handleConfirm = () => {
    close()
    resolveConfirm.current(true)
  }

  const handleCancel = () => {
    close()
    resolveConfirm.current(false)
  }

  const confirm = useCallback(
    (
      title: ConfirmDialogProps['title'],
      description: ConfirmDialogProps['description']
    ) => {
      setState({
        open: true,
        title,
        description,
      })

      return new Promise<boolean>(resolve => {
        resolveConfirm.current = resolve
      })
    },
    []
  )

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      <ConfirmDialog
        {...state}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      {children}
    </ConfirmContext.Provider>
  )
}
