import Button, { ButtonProps } from 'components/Button/Button'
import { ChangeEventHandler, InputHTMLAttributes, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { uploadFile } from 'utils/api/file'

interface IProps {
  onUpload: (url: string) => void
  buttonText: string
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  buttonProps?: ButtonProps
}

export default function UploadFileInput({
  onUpload,
  buttonText,
  inputProps,
  buttonProps,
}: IProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    inputRef?.current?.click()
  }

  const handleFileChange: ChangeEventHandler<
    HTMLInputElement
  > = async event => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }

    if (fileObj.size > 4_000_000) {
      toast.error('File size must be smaller than 4MB.')
    }

    // reset file input
    event.target.value = ''

    try {
      const res = await uploadFile(fileObj)
      onUpload(res.data.url)
    } catch (err) {
      toast.error('Error uploading file.')
    }
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        {...inputProps}
      />
      <Button
        onClick={handleClick}
        theme="secondary"
        type="button"
        className="w-52"
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </>
  )
}
