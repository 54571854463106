import clsx from 'clsx'

interface IProps {
  size?: number
  className?: string
}

const TrashIcon = ({ size = 20, className }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    className={clsx('fill-steel', className)}
  >
    <path
      opacity="0.4"
      d="M16.3706 8.23481C16.3706 8.29147 15.9265 13.9088 15.6728 16.2729C15.514 17.7236 14.5787 18.6036 13.1758 18.6286C12.0979 18.6528 11.0426 18.6611 10.0044 18.6611C8.90222 18.6611 7.82431 18.6528 6.778 18.6286C5.4221 18.5961 4.48602 17.6986 4.33528 16.2729C4.07431 13.9004 3.63828 8.29147 3.63018 8.23481C3.62207 8.06398 3.67718 7.90148 3.78903 7.76982C3.89925 7.64816 4.0581 7.57483 4.22505 7.57483H15.7838C15.95 7.57483 16.1007 7.64816 16.2199 7.76982C16.3309 7.90148 16.3868 8.06398 16.3706 8.23481Z"
    />
    <path d="M17.5 4.98068C17.5 4.63819 17.2301 4.36986 16.9059 4.36986H14.4762C13.9818 4.36986 13.5522 4.01821 13.442 3.52239L13.3059 2.91492C13.1154 2.18077 12.4581 1.66663 11.7206 1.66663H8.2802C7.53458 1.66663 6.88378 2.18077 6.68603 2.95491L6.55879 3.52323C6.44775 4.01821 6.01821 4.36986 5.52464 4.36986H3.09488C2.76988 4.36986 2.5 4.63819 2.5 4.98068V5.29733C2.5 5.63149 2.76988 5.90814 3.09488 5.90814H16.9059C17.2301 5.90814 17.5 5.63149 17.5 5.29733V4.98068Z" />
  </svg>
)

export default TrashIcon
