import { DateTime, DateTimeUnit } from 'luxon'
import { MetricDateFilter, MetricDateRange } from 'types/metrics'

const MetricDateFilterUnit: Record<MetricDateFilter, DateTimeUnit> = {
  YTD: 'year',
  MTD: 'month',
}

export const getMetricDateRange = (
  filter: MetricDateFilter
): MetricDateRange => {
  const unit = MetricDateFilterUnit[filter]

  return {
    startDate: DateTime.utc().startOf(unit).toISODate(),
    endDate: DateTime.utc().endOf(unit).toISODate(),
  }
}
