import TrashIcon from 'assets/icons/Trash'
import { ButtonHTMLAttributes } from 'react'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export default function DeleteButton({ ...props }: IProps) {
  return (
    <button type="button" {...props}>
      <TrashIcon />
    </button>
  )
}
