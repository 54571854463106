import { Deal, DealRequest, LeanDeal, UpdateDealResponse } from 'types'
import { get, post, put } from './index'

export const getDeal = async (dealId: string, userId: string) =>
  get<Deal>(`/deal/${dealId}`, { userId })

export const getDeals = async (userId: string) =>
  get<LeanDeal[]>('/v2/deal/all', { userId })

export const updateDeal = async (
  userId: string,
  dealId: string,
  payload: DealRequest
) => put(`/v2/deal/${dealId}/stage`, payload, { params: { userId } })

export const setDealStage = async (
  userId: string,
  dealId: string,
  payload: DealRequest
) =>
  post<UpdateDealResponse>(`/v2/deal/${dealId}/stage`, payload, {
    params: { userId },
  })
