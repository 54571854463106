import Cognito from './cognito'

const LS_TOKEN = 'symba-token'
const LS_TOKEN_RFR = 'symba-token-rfr'
const LS_TOKEN_EXP = 'symba-token-exp'

export const saveToken = (
  token: string,
  refreshToken: string,
  expiration: number
) => {
  window.localStorage.setItem(LS_TOKEN, token)
  window.localStorage.setItem(LS_TOKEN_RFR, refreshToken)
  window.localStorage.setItem(LS_TOKEN_EXP, String(expiration))
}

export const clearToken = () => {
  window.localStorage.removeItem(LS_TOKEN)
  window.localStorage.removeItem(LS_TOKEN_RFR)
  window.localStorage.removeItem(LS_TOKEN_EXP)
}

const shouldRefresh = (): boolean => {
  const exp = Number(window.localStorage.getItem(LS_TOKEN_EXP))

  if (!exp) {
    throw new Error('No token expiration value set in local storage.')
  }

  const fiveMinuteBuffer = Date.now() + 1000 * 60 * 5
  return exp < fiveMinuteBuffer
}

const refreshToken = async () => {
  const refreshToken = window.localStorage.getItem(LS_TOKEN_RFR)

  if (!refreshToken) {
    throw new Error('No refresh token value set in local storage.')
  }

  const cognito = new Cognito()
  const response = await cognito.refreshToken(refreshToken)

  const token = response.AuthenticationResult?.IdToken
  const expiration =
    Date.now() + (response.AuthenticationResult?.ExpiresIn as number) * 1000

  saveToken(token as string, refreshToken, expiration)
}

export const authToken = async (): Promise<string> => {
  if (shouldRefresh()) {
    await refreshToken()
  }

  const token = window.localStorage.getItem(LS_TOKEN)

  return `Bearer ${token}`
}

export const hasValidToken = async (): Promise<boolean> => {
  if (shouldRefresh()) {
    await refreshToken()
  }

  return !!window.localStorage.getItem(LS_TOKEN)
}
