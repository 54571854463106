import { useTeam } from 'hooks'
import { useGetContacts } from 'hooks/queries/useGetContacts'
import { useGetDeal } from 'hooks/queries/useGetDeal'
import { useNavigate, useParams } from 'react-router-dom'
import { getCurrentDealStage } from 'utils/deal'
import DealCommissionForm from './DealCommissionForm'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DealRequest } from 'types'
import { updateDeal } from 'utils/api/deal'
import { toast } from 'react-hot-toast'

export default function DealCommission() {
  const navigate = useNavigate()
  const { agentId, dealId } = useParams()
  const { activeUsers, team, updateTeam } = useTeam()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (payload: DealRequest) =>
      updateDeal(agentId!, dealId!, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['deals', agentId] })
      navigate(`/agent/${agentId}/deal/${dealId}`)
      toast.success('Commission updated')
    },
  })

  const dealQuery = useGetDeal(dealId!, agentId!)
  const contactQuery = useGetContacts(agentId!)

  if (dealQuery.isLoading || contactQuery.isLoading) {
    return null
  }

  const deal = dealQuery.data?.data!
  const contacts = contactQuery.data?.data!
  const currentStage = getCurrentDealStage(deal)
  const agent = activeUsers.find(user => user.id === deal.userId)

  return (
    <div className="px-[20%]">
      <h1 className="font-medium text-[2rem] leading-8">Commission form</h1>
      <DealCommissionForm
        activeUsers={activeUsers}
        agent={agent!}
        team={team!}
        updateTeam={updateTeam}
        contacts={contacts}
        currentStage={currentStage!}
        commission={deal.commission!}
        onSubmit={mutation.mutate}
        isSubmitting={mutation.isLoading}
      />
    </div>
  )
}
