import AgentIcon from 'assets/icons/Agent'
import CheckIcon from 'assets/icons/Check'
import DesktopIcon from 'assets/icons/Desktop'
import MobileIcon from 'assets/icons/Mobile'
import TeamIcon from 'assets/icons/Team'
import clsx from 'clsx'
import Button from 'components/Button/Button'
import AccountTypesModal from 'components/Modal/AccountTypesModal'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AccountType as IAccountType } from 'types'

export default function AccountType() {
  const navigate = useNavigate()
  const [accountType, setAccountType] = useState<IAccountType | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const isProduction = process.env.REACT_APP_ENV === 'production'

  const handleSubmit = async () => {
    if (accountType === 'team') {
      navigate('/onboarding/profile')
    } else if (accountType === 'individual') {
      navigate('/onboarding/agent/pricing')
    }
  }

  if (modalOpen) {
    return <AccountTypesModal onClose={() => setModalOpen(false)} />
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-[426px]">
        <ProgressBar value={25} />
        <h2 className="mb-4 text-[2rem] leading-8 tracking-tight font-medium">
          What type of account are you creating?
        </h2>
        <div className="mb-6 text-sm text-off-black/70">
          Each plan comes with a{' '}
          <span className="font-medium text-off-black">
            {process.env.REACT_APP_TRIAL_PERIOD_DAYS} day free trial
          </span>
        </div>
        <div
          className={clsx(
            'p-4 border border-steel/25 rounded-2xl flex mb-2',
            accountType === 'individual'
              ? 'shadow-md'
              : 'cursor-pointer hover:bg-tan/50'
          )}
          onClick={() => setAccountType('individual')}
        >
          <div className="w-8 mr-4">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-tan">
              <AgentIcon />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <div className="text-green">Individual</div>
              {accountType === 'individual' && <CheckIcon />}
            </div>
            <div className="mb-4 text-sm">
              Connect your bank accounts. Start closing deals effortlessy.
            </div>
            <MobileIcon />
          </div>
        </div>
        <div
          className={clsx(
            'p-4 border border-steel/25 rounded-2xl flex mb-2',
            accountType === 'team'
              ? 'shadow-md'
              : 'cursor-pointer hover:bg-tan/50',
            isProduction && 'opacity-50 pointer-events-none'
          )}
          onClick={() => setAccountType('team')}
        >
          <div className="w-8 mr-4">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-tan">
              <TeamIcon className="stroke-steel fill-steel" />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <div className="text-green">
                Team {isProduction && <span> - Coming Soon!</span>}
              </div>
              {accountType === 'team' && <CheckIcon />}
            </div>
            <div className="mb-2 text-sm">
              Invite your team. Get all the data for every deal at your
              fingertips.
            </div>
            <div className="mb-4 text-sm text-off-black/70">
              Individual accounts included for each member
            </div>
            <div className="flex">
              <div className="mr-4">
                <MobileIcon />
              </div>
              <DesktopIcon />
            </div>
          </div>
        </div>
        <div className="mb-10 text-sm text-off-black/70">
          <span
            className="cursor-pointer text-green"
            onClick={() => setModalOpen(true)}
          >
            Learn more
          </span>{' '}
          about the different account types
        </div>
        <div className="flex justify-end">
          <Button
            disabled={accountType === null}
            onClick={handleSubmit}
            className="w-1/2"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}
