import { DEAL_STAGE_CONFIG } from 'constants/deal'
import { DEAL_STAGE, TeamPipelineResponse } from 'types'
import { formatCurrency } from 'utils/formatters'
import { User } from 'types/user'
import VolumePerUserBar from 'routes/Dashboard/Widgets/VolumePerUserBar'
import { stageHasPrice } from 'utils/deal'

interface IProps {
  onClick: () => void
  stage: DEAL_STAGE
  data: TeamPipelineResponse[]
  users: User[]
}

export default function PipelineStageCard({
  onClick,
  stage,
  data,
  users,
}: IProps) {
  const count = data.reduce((acc, item) => acc + item.count, 0)
  const volume = data.reduce((acc, item) => acc + item.volume, 0)
  const volumePerUser = data.map(item => ({
    _id: item._id.userId,
    value: item.volume,
  }))

  return (
    <div
      className="p-4 pt-2 mb-4 bg-white rounded-lg shadow-md last:mb-0 group/item hover:cursor-pointer hover:shadow-lg hover:bg-tan/50"
      onClick={onClick}
    >
      <h4 className="mb-4 text-green">{DEAL_STAGE_CONFIG[stage].name}</h4>
      <div className="flex items-center justify-between">
        <h5 className="font-medium text-[2.5rem] leading-[2.5rem] group-hover/item:text-green">
          {count}
        </h5>
        {stageHasPrice(stage) && (
          <div className="flex items-center gap-2 text-sm text-steel">
            <span>Volume</span>
            <div className="px-2 py-1 font-medium rounded text-off-black bg-steel/15">
              {formatCurrency(volume)}
            </div>
          </div>
        )}
      </div>
      {stageHasPrice(stage) && (
        <div className="mt-3">
          <VolumePerUserBar
            volume={volume}
            volumePerUser={volumePerUser}
            users={users}
          />
        </div>
      )}
    </div>
  )
}
