import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import Header from 'components/Header/Header'
import { useAuth, useTeam, useProfile } from 'hooks'

export default function RootLayout() {
  const { logOut } = useAuth()
  const { loadProfile, me, profileLoaded } = useProfile()
  const { team, teams, activeUsers, manageBilling, setActiveTeam } = useTeam()

  useEffect(() => {
    loadProfile()
  }, []) // eslint-disable-line

  if (profileLoaded) {
    return (
      <div className="relative flex flex-col h-screen min-h-0">
        <Header
          profile={me}
          team={team}
          teams={teams}
          activeUsers={activeUsers}
          onManageBilling={manageBilling}
          onSetTeam={setActiveTeam}
          onLogOut={logOut}
        />
        <Outlet />
        <ScrollRestoration />
        <Toaster />
      </div>
    )
  }

  return null
}
