import Panel from 'components/Panel/Panel'
import QrCode from 'components/QrCode/QrCode'
import SignInForm from 'components/SignInForm/SignInForm'
import Spinner from 'components/Spinner/Spinner'
import TeamAvatar from 'components/TeamAvatar/TeamAvatar'
import { useAuth, useTeam } from 'hooks'
import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { AcceptInviteResponse, ISignInForm } from 'types'

export default function AcceptInvite() {
  const { signIn } = useAuth()
  const { acceptInvite } = useTeam()
  const [searchParams] = useSearchParams()
  const [response, setResponse] = useState<AcceptInviteResponse | null>(null)

  const invitationId = searchParams.get('invitation_id')
  const token = searchParams.get('token')

  useEffect(() => {
    const accept = async () => {
      if (invitationId && token) {
        try {
          const res = await acceptInvite(invitationId, token)
          setResponse(res)
        } catch (err) {
          toast.error('There was an error accepting your invitation.')
        }
      }
    }
    accept()
  }, [invitationId, token]) // eslint-disable-line

  const onSubmit: SubmitHandler<ISignInForm> = async data => {
    await signIn(data)
  }

  return (
    <Panel>
      {!response?.team ? (
        <div className="flex items-center justify-center h-[442px]">
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          <h1 className="mb-2 text-sm text-steel">Welcome to the team</h1>
          <div className="flex items-center mb-6 space-x-4">
            <TeamAvatar team={response.team} size={40} />
            <div className="font-medium">{response.team.name}</div>
          </div>
          {response.role === 'ADMIN' ? (
            <>
              <div className="mb-4 text-2xl font-medium leading-6">
                Get the data for every deal at your fingertips. Stay on top of
                every transaction.
              </div>
              <div>
                No password needed, we'll send you a one time sign in code.
              </div>
              <SignInForm onSubmit={onSubmit} phone={response.phone} />
            </>
          ) : (
            <>
              <div className="mb-4 text-2xl font-medium leading-6">
                Finish setting up your account through the symba mobile app
              </div>
              <QrCode />
            </>
          )}
        </>
      )}
    </Panel>
  )
}
