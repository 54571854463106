import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'reducers/profile'
import { UpdateUserRequest, User } from 'types'
import * as userApi from 'utils/api/user'
import { hasValidToken } from 'utils/token'
import { useAppSelector } from './useAppSelector'
import { useTeam } from './useTeam'

interface IHook {
  me: User | null
  profileLoaded: boolean
  loadProfile: () => Promise<void>
  updateMe: (data: UpdateUserRequest) => Promise<void>
}

export const useProfile = (): IHook => {
  const dispatch = useDispatch()
  const { initializeTeams } = useTeam()

  const me = useAppSelector(state => state.profile.me)
  const [profileLoaded, setProfileLoaded] = useState(false)

  const loadProfile = async () => {
    setProfileLoaded(false)

    let isSignedIn = false
    try {
      isSignedIn = await hasValidToken()
    } catch (err) {
      dispatch(actions.clear())
    }

    if (isSignedIn) {
      const { data: me } = await userApi.fetchMe()
      dispatch(actions.set(me))
      await initializeTeams(me.teams)
    }

    setProfileLoaded(true)
  }

  const updateMe = async (data: UpdateUserRequest) => {
    await userApi.updateMe(data)
    const { data: me } = await userApi.fetchMe()
    dispatch(actions.set(me))
  }

  return {
    me,
    profileLoaded,
    loadProfile,
    updateMe,
  }
}
