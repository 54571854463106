import { useProfile } from 'hooks'
import { Navigate, Outlet } from 'react-router-dom'

function AuthLayout() {
  const { me } = useProfile()

  if (me) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="py-20 h-full flex flex-col items-center bg-[url('assets/images/background.svg')] bg-cover p-4 relative">
      <Outlet />
    </div>
  )
}

export default AuthLayout
