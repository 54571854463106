const AgentIcon = () => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none">
    <path
      d="M5.21463 8.79102C2.38637 8.79102 0 9.32883 0 11.4045C0 13.481 2.40178 13.9999 5.21463 13.9999C8.04289 13.9999 10.4293 13.4621 10.4293 11.3864C10.4293 9.30993 8.02748 8.79102 5.21463 8.79102Z"
      fill="#A2BFB5"
      fillOpacity="0.4"
    />
    <path
      d="M5.2144 7.75035C7.12951 7.75035 8.66513 5.84693 8.66513 3.69625C8.66513 1.54475 7.12951 0 5.2144 0C3.29929 0 1.76367 1.54475 1.76367 3.69625C1.76367 5.84693 3.29929 7.75035 5.2144 7.75035Z"
      fill="#A2BFB5"
    />
  </svg>
)

export default AgentIcon
