import { DEAL_STAGE } from 'types'
import { MetricValue } from 'types/metrics'
import { getDealStageCount } from 'utils/deal'
import Widget from 'components/Widget'
import clsx from 'clsx'

interface IProps {
  dealStageCount?: MetricValue[]
  disabled?: boolean
}

export default function PerformanceWidget({
  dealStageCount,
  disabled = false,
}: IProps) {
  const appointmentsSet = getDealStageCount(
    DEAL_STAGE.appointmentSet,
    dealStageCount
  )

  const dealsClosed = getDealStageCount(DEAL_STAGE.saleClosed, dealStageCount)

  return (
    <Widget
      title="Performance"
      disabled={disabled}
      className="basis-0 grow-[2]"
    >
      <div>
        <h6 className="text-sm text-steel">Appointments set</h6>
        <div
          className={clsx(
            'text-2xl font-medium',
            disabled && 'text-off-black/35'
          )}
        >
          {appointmentsSet}
        </div>
      </div>
      <div>
        <h6 className="text-sm text-steel">Deals closed</h6>
        <div
          className={clsx(
            'text-2xl font-medium',
            disabled && 'text-off-black/35'
          )}
        >
          {dealsClosed}
        </div>
      </div>
    </Widget>
  )
}
