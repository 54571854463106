import { ButtonHTMLAttributes } from 'react'

import Plus from 'assets/icons/Plus'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export default function PlusButton({ ...props }: IProps) {
  return (
    <button
      className="bg-green-light rounded w-[18px] h-[18px] flex justify-center items-center shadow-sm"
      type="button"
      {...props}
    >
      <Plus />
    </button>
  )
}
