import logoIcon from 'assets/images/logoIcon.svg'
import QrCode from 'components/QrCode/QrCode'

export default function AgentComplete() {
  return (
    <div className="flex flex-col items-center">
      <div className="w-[426px]">
        <div className="flex justify-center">
          <img
            src={logoIcon}
            alt="logo"
            className="w-16 h-16 p-4 rounded-lg shadow-md"
          />
        </div>

        <div className="my-8">
          <p className="mb-1 text-sm text-steel">Sign up complete</p>
          <h4 className="text-2xl font-medium leading-6">
            Finish setting up your account through the symba mobile app
          </h4>
        </div>

        <QrCode />
      </div>
    </div>
  )
}
