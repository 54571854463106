import qrCode from 'assets/images/qrCode.svg'
import Button from 'components/Button/Button'

export default function QrCode() {
  const isMobile = /android|iphone/i.test(navigator.userAgent)

  const handleDownload = () => {
    window.location.href = 'https://getsymba.app.link/web-sign-up'
  }
  return (
    <div className="flex justify-end h-48">
      {isMobile ? (
        <Button type="button" onClick={handleDownload} className="w-1/2">
          Download
        </Button>
      ) : (
        <div
          className="flex items-center justify-center flex-1 rounded-lg"
          style={{
            background:
              'radial-gradient(100% 173% at 0% 0%, #E1FC97 0%, #B3F261 100%)',
          }}
        >
          <div className="flex items-center justify-center bg-white rounded-lg w-36 h-36">
            <img src={qrCode} alt="qrCode" />
          </div>
        </div>
      )}
    </div>
  )
}
