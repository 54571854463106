import { FieldValues } from 'react-hook-form'
import PhoneNumberInput, { Country } from 'react-phone-number-input/input'

import BaseInput, { BaseInputProps } from './BaseInput'

type PhoneInputProps = Omit<BaseInputProps<FieldValues>, 'render'> & {
  country?: Country
  placeholder?: string
}

const PhoneInput = ({
  control,
  name,
  label,
  required,
  country = 'US',
  ...inputProps
}: PhoneInputProps) => (
  <BaseInput
    control={control}
    name={name}
    label={label}
    required={required}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <PhoneNumberInput
        {...inputProps}
        aria-invalid={error ? 'true' : 'false'}
        className="h-10 px-4 py-2 font-normal border rounded-lg outline-none border-steel/35 focus:shadow-sm"
        country={country}
        value={value}
        onChange={onChange}
      />
    )}
  />
)

export default PhoneInput
