import { useNavigate } from 'react-router-dom'
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import { Switch } from '@material-tailwind/react'
import * as yup from 'yup'
import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'

import {
  ALL_ROLES,
  COMMISSION_TYPE,
  IInviteTeamMemberForm,
  InviteTeamMemberRequest,
} from 'types'
import { SignUpFormSchema } from 'routes/SignUp/SignUp'
import Button from 'components/Button/Button'
import BaseInput from 'components/Input/BaseInput'
import PhoneInput from 'components/Input/PhoneInput'
import TextInput from 'components/Input/TextInput'
import TeamMemberRoleSelect from 'components/Input/TeamMemberRoleSelect'
import ArrowRight from 'assets/icons/ArrowRight'

const InviteTeamMemberFormSchema = SignUpFormSchema.shape({
  role: yup
    .string()
    .oneOf(ALL_ROLES as unknown as string[])
    .required('Role is required'),
  commission: yup.object().shape({
    mySplit: yup
      .number()
      .min(0, 'Should be 0 at min')
      .max(100, 'Should be 100% at max'),
    brokerageSplit: yup
      .number()
      .min(0, 'Should be 0 at min')
      .max(100, 'Should be 100% at max'),
    splitSum: yup
      .number()
      .test('splitSum', 'Total should be 100%', function () {
        const { mySplit, brokerageSplit } = this.parent
        return mySplit + brokerageSplit === 100
      }),
  }),
})

const defaultValues: IInviteTeamMemberForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: 'AGENT',
  isBetaUser: process.env.REACT_APP_ENV === 'beta',
  splitCommission: true,
  commission: {
    type: COMMISSION_TYPE.percentage,
    mySplit: 100,
    brokerageSplit: 0,
  },
}

interface IProps {
  inviteTeamMember: (data: InviteTeamMemberRequest) => Promise<void>
}

export default function InviteTeamMemberForm({ inviteTeamMember }: IProps) {
  const navigate = useNavigate()

  const {
    control,
    formState: { isSubmitting, errors },
    watch,
    handleSubmit,
  } = useForm<IInviteTeamMemberForm>({
    resolver: yupResolver(InviteTeamMemberFormSchema),
    defaultValues,
  })

  const splitCommission = watch('splitCommission')

  const onSubmit: SubmitHandler<IInviteTeamMemberForm> = async (
    data: IInviteTeamMemberForm
  ) => {
    const { splitCommission, commission, ...rest } = data

    try {
      await inviteTeamMember(
        data.splitCommission ? { ...rest, commission } : rest
      )
      navigate('/settings/team')
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err.response?.data.message ?? err.message)
      }
    }
  }

  return (
    <form
      noValidate
      className="grid grid-cols-2 gap-4 my-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        control={control as unknown as Control<FieldValues>}
        name="firstName"
        label="First name"
        placeholder="First name"
      />

      <TextInput
        control={control as unknown as Control<FieldValues>}
        name="lastName"
        label="Last name"
        placeholder="Last name"
      />

      <div className="col-span-full">
        <TextInput
          control={control as unknown as Control<FieldValues>}
          name="email"
          label="Email"
          placeholder="Email"
        />
      </div>

      <div className="col-span-full">
        <PhoneInput
          control={control as unknown as Control<FieldValues>}
          name="phone"
          label="Mobile phone"
          placeholder="Mobile phone"
        />
      </div>

      <div className="col-span-full">
        <TeamMemberRoleSelect
          control={control as unknown as Control<FieldValues>}
          name="role"
          label="Role"
        />
      </div>

      <div className="flex justify-between col-span-full">
        <div className="text-steel">Commission split</div>
        <BaseInput
          control={control}
          name="splitCommission"
          render={({ field: { value, onChange } }) => (
            <Switch
              color="green"
              checked={value as boolean}
              onChange={onChange}
            />
          )}
        />
      </div>

      {splitCommission && (
        <>
          <div className="col-span-full">
            <p className="text-off-black/50">
              Don't worry, this can be changed or added later.
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex items-start gap-x-2">
              <TextInput
                control={control as unknown as Control<FieldValues>}
                name="commission.mySplit"
                label="Agent(%)"
                type="number"
                min={0}
                max={100}
                className="w-20"
              />

              <div className="pt-10">
                <ArrowRight />
              </div>

              <TextInput
                control={control as unknown as Control<FieldValues>}
                name="commission.brokerageSplit"
                label="Brokerage(%)"
                type="number"
                min={0}
                max={100}
                className="w-20"
              />
            </div>

            <ErrorMessage
              errors={errors}
              name="commission.splitSum"
              render={({ message }) => (
                <p className="text-xs text-red">{message}</p>
              )}
            />
          </div>
          <div />
        </>
      )}

      <Button
        type="submit"
        loading={isSubmitting}
        className="col-start-2 mt-10"
      >
        Send invite
      </Button>
    </form>
  )
}
