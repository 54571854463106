import React, { useMemo } from 'react'
import { get, identity } from 'lodash'

import { ITeamMember } from 'types'
import { Metric, MetricsResponse } from 'types/metrics'

import Avatar from 'components/Avatar'
import Widget from 'components/Widget'

export type TeamMemberWithMetrics = ITeamMember & MetricsResponse

interface LeaderboardWidgetProps {
  title: string
  metricName: string
  metric: Metric
  leaderboard: TeamMemberWithMetrics[]
  formatter?: (value?: number) => string
}

const LeaderboardWidget: React.FC<LeaderboardWidgetProps> = ({
  title,
  metricName,
  metric,
  leaderboard,
  formatter = identity,
}) => {
  const [leader, ...runnersUp] = useMemo(
    () => leaderboard.slice(0, 3),
    [leaderboard]
  )

  if (!leader) {
    return null
  }

  return (
    <Widget title={title} titleClassName="text-steel">
      <div className="relative flex items-center mb-4 gap-x-4">
        <Avatar user={leader.user} size={48} border />
        <span className="font-medium">
          {[leader.user.firstName, leader.user.lastName].join(' ')}
        </span>
      </div>

      <div className="grid grid-cols-[1fr_auto]">
        <h6 className="text-sm text-steel">{metricName}</h6>
        <h6 className="text-sm text-steel">Runners up</h6>
        <h2 className="font-medium text-[2rem]">
          {formatter(get(leader, [metric, 'value']))}
        </h2>
        <div className="flex items-center">
          {runnersUp.map(member => (
            <div key={member.user.id} className="w-5 hover:z-50">
              <Avatar user={member.user} size={24} border />
            </div>
          ))}
        </div>
      </div>
    </Widget>
  )
}

export default LeaderboardWidget
