import { useQuery } from '@tanstack/react-query'
import { fromPairs, get, keyBy, orderBy } from 'lodash'
import { useMemo } from 'react'
import { TeamMemberWithMetrics } from 'routes/Leaderboard/LeaderboardWidget'
import { ITeamMember } from 'types'
import { ALL_LEADERBOARD_METRICS, Metric } from 'types/metrics'
import { getTeamMetrics } from 'utils/api/team'
import { getMetricDateRange } from 'utils/metric'

interface IProps {
  teamId?: string
  dateFilter: 'YTD' | 'MTD'
  members: ITeamMember[]
}

const metricGetter = (metric: string) => (member: TeamMemberWithMetrics) =>
  get(member, [metric, 'value'], 0)

export const useLeaderboard = ({ teamId, dateFilter, members }: IProps) => {
  const { startDate, endDate } = getMetricDateRange(dateFilter)
  const filters = {
    metrics: ALL_LEADERBOARD_METRICS as unknown as Metric[],
    startDate,
    endDate,
  }
  const { data } = useQuery({
    queryKey: ['leaderboard', teamId, { dateFilter }],
    queryFn: () => getTeamMetrics(teamId!, filters),
    enabled: !!teamId,
  })

  const metricsByUserId = useMemo(
    () =>
      fromPairs(
        ALL_LEADERBOARD_METRICS.map(metric => [
          metric,
          keyBy(get(data?.data, metric, {}), '_id'),
        ])
      ),
    [data]
  )

  const membersWithMetrics = useMemo(() => {
    return members
      .filter(member => member.invitation.status === 'ACCEPTED')
      .map(member => ({
        ...member,
        ...fromPairs(
          ALL_LEADERBOARD_METRICS.map(metric => [
            metric,
            get(metricsByUserId, [metric, member.user.id]),
          ])
        ),
      })) as TeamMemberWithMetrics[]
  }, [members, metricsByUserId])

  const membersOrderedByMetric = useMemo(
    () =>
      fromPairs(
        ALL_LEADERBOARD_METRICS.map(metric => [
          metric,
          orderBy(membersWithMetrics, [metricGetter(metric)], ['desc']),
        ])
      ),
    [membersWithMetrics]
  )

  const leaderboardByMetric = useMemo(
    () =>
      fromPairs(
        Object.entries(membersOrderedByMetric).map(([metric, members]) => [
          metric,
          members
            .slice(0, 3)
            .filter(member => metricGetter(metric)(member) > 0),
        ])
      ),
    [membersOrderedByMetric]
  )

  return { leaderboardByMetric, membersOrderedByMetric }
}
