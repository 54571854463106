import { yupResolver } from '@hookform/resolvers/yup'
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import 'yup-phone-lite'

import Button from 'components/Button/Button'
import PhoneInput from 'components/Input/PhoneInput'
import { ISignInForm } from 'types'

interface ISignInFormProps {
  onSubmit: SubmitHandler<ISignInForm>
  phone?: string
}

const SignInFormSchema = yup.object().shape({
  phone: yup
    .string()
    .phone('US', 'Phone number is invalid')
    .required('Phone number is required'),
})

export default function SignInForm({ onSubmit, phone }: ISignInFormProps) {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<ISignInForm>({
    resolver: yupResolver(SignInFormSchema),
    defaultValues: { phone },
  })

  return (
    <form
      noValidate
      className="grid grid-cols-2 gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-span-2 py-4">
        <PhoneInput
          control={control as unknown as Control<FieldValues>}
          name="phone"
          label="Mobile phone"
          placeholder="Mobile phone"
          required
        />
      </div>

      <Button type="submit" loading={isSubmitting} className="col-start-2">
        Sign in
      </Button>
    </form>
  )
}
