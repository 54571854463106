import CaretDownIcon from 'assets/icons/CaretDown'
import clsx from 'clsx'
import { LeaderboardMetric } from 'types/metrics'

interface MetricTitleProps {
  title: string
  metric: LeaderboardMetric
  sort: LeaderboardMetric
  setSort: (sort: LeaderboardMetric) => void
}

export const MetricTitle = ({
  title,
  metric,
  sort,
  setSort,
}: MetricTitleProps) => (
  <span
    className={clsx(
      'flex items-center gap-x-1',
      sort !== metric && 'cursor-pointer'
    )}
    onClick={() => setSort(metric)}
  >
    {title}
    {sort === metric ? <CaretDownIcon /> : <div className="w-[10px]" />}
  </span>
)
