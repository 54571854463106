import TeamForm from 'components/Forms/TeamForm'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import Screen from 'components/Screen/Screen'
import { useTeam } from 'hooks'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { TeamRequest } from 'types'

export default function OnboardingTeam() {
  const navigate = useNavigate()
  const { createTeam } = useTeam()

  const onSubmit: SubmitHandler<TeamRequest> = async data => {
    await createTeam(data)
    navigate(`/onboarding/team/pricing`)
  }

  return (
    <Screen backButton>
      <div className="flex flex-col items-center">
        <div className="w-[426px]">
          <ProgressBar value={50} />
          <h2 className="text-[32px] font-medium mb-4">Team info</h2>
          <TeamForm onSubmit={onSubmit} />
        </div>
      </div>
    </Screen>
  )
}
