import { FieldValues } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import type { GroupBase } from 'react-select'
import type { AsyncProps } from 'react-select/async'

import BaseInput, { BaseInputProps } from './BaseInput'
import { getReactSelectStyles } from './styles'

type AsyncSelectInputProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<BaseInputProps<FieldValues>, 'render'> &
  AsyncProps<Option, IsMulti, Group>

export default function AsyncSelectInput<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  control,
  name,
  label,
  required,
  ...props
}: AsyncSelectInputProps<Option, IsMulti, Group>) {
  return (
    <BaseInput
      control={control}
      name={name}
      label={label}
      required={required}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <AsyncSelect
          {...props}
          aria-invalid={error ? 'true' : 'false'}
          onChange={onChange}
          styles={getReactSelectStyles<Option, IsMulti, Group>()}
        />
      )}
    />
  )
}
