import { SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'

import Panel from 'components/Panel/Panel'
import { useAuth } from 'hooks'
import { ISignInForm } from 'types'
import Screen from 'components/Screen/Screen'
import SignInForm from 'components/SignInForm/SignInForm'

function SignIn() {
  const { signIn } = useAuth()

  const onSubmit: SubmitHandler<ISignInForm> = async data => {
    await signIn(data)
  }

  return (
    <Screen>
      <div className="absolute top-4 right-4">
        Don’t have an account?{' '}
        <Link
          className="no-underline cursor-pointer text-green visited:text-green"
          to="/signup"
        >
          Sign up
        </Link>
      </div>
      <Panel>
        <h2 className="mb-4 text-[2rem] font-medium">Sign in</h2>

        <div>No password needed, we'll send you a one time sign in code.</div>

        <SignInForm onSubmit={onSubmit} />
      </Panel>
    </Screen>
  )
}

export default SignIn
