import CaretDown from 'assets/icons/CaretDown'
import CaretUp from 'assets/icons/CaretUp'
import Avatar from 'components/Avatar'
import numeral from 'numeral'
import { useState } from 'react'
import { COMMISSION_TYPE, User } from 'types'
import PercentChip from './PercentChip'

interface IProps {
  agent: User
  price: number
  gci: number
  referralFee: number
  referralSource: string
  preSplitGci: number
  agentSplit: number
  teamSplit: number
  postSplitAdjustments: {
    payer?: 'AGENT' | 'TEAM'
    payee?: string
    feeType?: COMMISSION_TYPE
    amount: number
    percentage?: string
  }[]
}

export default function AgentBreakdown({
  agent,
  price,
  gci,
  referralFee,
  referralSource,
  preSplitGci,
  agentSplit,
  teamSplit,
  postSplitAdjustments,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false)

  const postSplitAdjustmentsTotal = postSplitAdjustments.reduce(
    (acc, adjustment) => acc + adjustment.amount,
    0
  )

  return (
    <div className="px-6 py-2 text-sm shadow-md rounded-2xl">
      <div
        className="flex items-center justify-between px-6 pb-2 -mx-6 border-b cursor-pointer text-green border-b-steel/15"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Breakdown</span>
        {isOpen ? <CaretUp /> : <CaretDown />}
      </div>

      {isOpen && (
        <>
          {/** Sale price */}
          <div className="flex items-center justify-between py-4 border-b border-b-steel/15">
            <div>Sale price</div>
            <div className="text-base font-medium">
              {numeral(price).format('$0,0[.]00')}
            </div>
          </div>

          {/** GCI */}
          <div className="py-4 border-b border-b-steel/15">
            <div className="flex items-center justify-between">
              <div>
                GCI
                <PercentChip>
                  {numeral(gci / price).format('0[.]00%')}
                </PercentChip>
              </div>
              <div className="text-base font-medium">
                {numeral(gci).format('$0,0.00')}
              </div>
            </div>
            {referralFee > 0 && (
              <div className="border-l-4 border-l-steel/35">
                <div className="flex items-center justify-between mt-2">
                  <div className="ml-4">
                    {referralSource}
                    <PercentChip>
                      {numeral(referralFee / gci).format('0[.]00%')}
                    </PercentChip>
                  </div>
                  <div className="text-base text-off-black/70">
                    - {numeral(referralFee).format('$0,0.00')}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/** Pre-split adjusted GCI */}
          <div className="py-4 border-b border-b-steel/15">
            <div className="flex items-center justify-between">
              <div>Pre-split adjusted GCI</div>
              <div className="text-base font-medium">
                {numeral(preSplitGci).format('$0,0.00')}
              </div>
            </div>
            <div className="border-l-4 border-l-steel/35">
              <div className="flex items-center justify-between mt-2">
                <div className="ml-4">
                  Team split
                  <PercentChip>
                    {numeral(teamSplit / preSplitGci).format('0[.]00%')}
                  </PercentChip>
                </div>
                <div className="text-base text-off-black/70">
                  - {numeral(teamSplit).format('$0,0.00')}
                </div>
              </div>
            </div>
          </div>

          {/** Post-split adjusted GCI */}
          <div className="py-4 border-b border-b-steel/15">
            <div className="flex items-center justify-between">
              <div>Post-split adjusted GCI</div>
              <div className="text-base font-medium">
                {numeral(agentSplit).format('$0,0[.]00')}
              </div>
            </div>
            {postSplitAdjustments.length > 0 && (
              <div className="border-l-4 border-l-steel/35">
                {postSplitAdjustments.map((adjustment, idx) => (
                  <div
                    key={idx}
                    className="flex items-center justify-between mt-2"
                  >
                    <div className="ml-4">
                      {adjustment.payee}
                      {adjustment.feeType === COMMISSION_TYPE.percentage && (
                        <PercentChip>{adjustment.percentage}</PercentChip>
                      )}
                    </div>
                    <div className="text-base text-off-black/70">
                      - {numeral(adjustment.amount).format('$0,0.00')}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      <div className="flex items-center justify-between p-4 mt-3 -mx-4 rounded-lg bg-tan">
        <div className="flex items-center">
          <Avatar user={agent} size={24} />
          <span className="ml-2 font-medium">{agent.name}</span>
        </div>
        <div className="text-2xl font-medium">
          {numeral(agentSplit - postSplitAdjustmentsTotal).format('$0,0.00')}
        </div>
      </div>
    </div>
  )
}
