export const USER_COLOR_ORDER = [
  '#DAFC7E', // green-light
  '#3AC345', // green
  '#4D5932', // green-dark
  '#A2BFB5', // steel
  '#D9FFF1', // sky
  '#99D4FF', // blue-light
  '#358FE9', // blue
  '#B19BDE', // purple-light
  '#8D64DE', // purple
  '#FFCCD9', // red-light
  '#EB4662', // red
  '#F7C77C', // orange-light
  '#EC6E2A', // orange
  '#F8FFBF', // yellow-light
  '#FCE54D', // yellow
]
