import Avatar from 'components/Avatar'
import { useNavigate } from 'react-router-dom'

import { User } from 'types'

interface IProps {
  user: User
  value: string
  percentage: number
}

export default function UserMetricBar({ user, value, percentage }: IProps) {
  const navigate = useNavigate()
  return (
    <div
      className="mr-0.5 last:mr-0 relative group"
      style={{ flexBasis: `${percentage}%` }}
    >
      <button
        className="w-full h-2 rounded-sm shadow-md"
        style={{ backgroundColor: user.color }}
        onClick={e => {
          e.stopPropagation()
          navigate(`/agent/${user.id}`)
        }}
      ></button>
      <div className="absolute left-0 z-50 flex items-center justify-between invisible h-12 px-4 py-2 bg-white border-none rounded-lg shadow-md group-hover:visible -bottom-16 whitespace-nowrap">
        <Avatar className="mr-2" user={user} size={24} border />
        <div className="text-off-black">
          <div className="text-xs">{user.name}</div>
          <div className="text-sm font-medium">{value}</div>
        </div>
      </div>
    </div>
  )
}
