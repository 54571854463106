export default function AddressIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6884 1.95752C7.14497 1.11119 8.93534 1.12598 10.3782 1.99627C11.807 2.88428 12.6753 4.46912 12.6672 6.17397C12.634 7.86763 11.7028 9.45967 10.539 10.6904C9.86721 11.4039 9.11573 12.0349 8.29988 12.5704C8.21586 12.6189 8.12382 12.6515 8.02831 12.6663C7.93638 12.6624 7.84686 12.6353 7.76782 12.5873C6.52227 11.7827 5.42954 10.7557 4.54221 9.55565C3.79971 8.55392 3.37787 7.34368 3.33399 6.08929C3.33302 4.38117 4.23183 2.80385 5.6884 1.95752ZM6.53033 6.79668C6.77534 7.40072 7.35368 7.79472 7.99529 7.79472C8.41562 7.79774 8.81968 7.62938 9.11743 7.32714C9.41517 7.02491 9.58188 6.61392 9.58039 6.18575C9.58263 5.53218 9.19786 4.9417 8.60573 4.69001C8.0136 4.43832 7.33092 4.57506 6.87643 5.03639C6.42195 5.49772 6.28532 6.19264 6.53033 6.79668Z"
        fill="#A2BFB5"
      />
      <ellipse
        opacity="0.4"
        cx="8.0013"
        cy="13.9997"
        rx="3.33333"
        ry="0.666667"
        fill="#A2BFB5"
      />
    </svg>
  )
}
