import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { store } from 'reducers'
import router from 'routes/router'
import { ConfirmProvider } from 'contexts/ConfirmContext'

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 3 } },
})

function App() {
  return (
    <Provider store={store}>
      <ConfirmProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ConfirmProvider>
    </Provider>
  )
}

export default App
