import CloseButton from 'components/Button/CloseButton'
import { PropsWithChildren } from 'react'

interface IModalProps {
  onClose?: () => void
}

export default function Modal({
  onClose,
  children,
}: PropsWithChildren<IModalProps>) {
  return (
    <div className="absolute top-0 left-0 flex flex-col items-center w-full h-full bg-white">
      {!!onClose && (
        <CloseButton onClick={onClose} className="absolute right-44 top-20" />
      )}
      {children}
    </div>
  )
}
