import { yupResolver } from '@hookform/resolvers/yup'
import ArrowRight from 'assets/icons/ArrowRight'
import Avatar from 'components/Avatar'
import Button from 'components/Button/Button'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { UpdateCommissionRequest, User } from 'types'
import * as yup from 'yup'
import Modal from './Modal'
import FormControlRow from 'components/Forms/FormControlRow'
import FormControl from 'components/Forms/FormControl'
import FormLabel from 'components/Forms/FormLabel'
import PercentageInput from 'components/Input/PercentageInput'

interface ICommissionForm {
  mySplit: number | string
  brokerageSplit: number | string
}

const CommissionFormSchema = yup.object().shape({
  mySplit: yup.number().required('Agent is required'),
  brokerageSplit: yup.number().required('Brokerage is required'),
})

interface IProps {
  user: User
  onClose: () => void
  onSave: (agentId: string, data: UpdateCommissionRequest) => Promise<void>
}

export default function CommissionModal({ onClose, user, onSave }: IProps) {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<ICommissionForm>({
    resolver: yupResolver(CommissionFormSchema),
    defaultValues: {
      mySplit: user.commission?.mySplit ?? 100,
      brokerageSplit: user.commission?.brokerageSplit ?? 0,
    },
  })

  const onSubmit: SubmitHandler<ICommissionForm> = async (
    data: ICommissionForm
  ) => {
    const payload = {
      mySplit: parseInt(data.mySplit as string),
      brokerageSplit: parseInt(data.brokerageSplit as string),
    }
    await onSave(user.id, payload)
    onClose()
  }

  return (
    <Modal onClose={onClose}>
      <div className="mt-20 w-[425px] flex flex-col">
        <div className="flex items-center p-4 bg-tan rounded-2xl">
          <Avatar user={user} />
          <span className="ml-4 font-medium">{user.name}</span>
        </div>

        <h2 className="my-6 text-[2rem] font-medium">
          Default commission split
        </h2>

        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <FormControlRow>
            <FormControl>
              <FormLabel>Agent split</FormLabel>
              <Controller
                name="mySplit"
                control={control}
                render={({ field: { value } }) => (
                  <PercentageInput
                    value={value}
                    onChange={value => {
                      setValue('mySplit', value ?? '', { shouldDirty: true })
                      setValue('brokerageSplit', 100 - (value ?? 0))
                    }}
                  />
                )}
              />
            </FormControl>

            <div className="mt-[30px]">
              <ArrowRight />
            </div>

            <FormControl>
              <FormLabel>Team split</FormLabel>
              <Controller
                name="brokerageSplit"
                control={control}
                render={({ field: { value } }) => (
                  <PercentageInput
                    value={value}
                    onChange={value => {
                      setValue('brokerageSplit', value ?? '', {
                        shouldDirty: true,
                      })
                      setValue('mySplit', 100 - (value ?? 0))
                    }}
                  />
                )}
              />
            </FormControl>
          </FormControlRow>

          <div className="flex justify-end mt-10">
            <Button type="submit" loading={isSubmitting} className="w-1/2">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
