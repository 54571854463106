import { DEAL_STAGE_CONFIG } from 'constants/deal'
import numeral from 'numeral'
import { DEAL_STAGE, LeanDeal } from 'types'
import DealCard from './DealCard'

interface IProps {
  stageName: DEAL_STAGE
  deals?: LeanDeal[]
}

function EmptyStage() {
  return (
    <div className="flex items-center justify-center h-10 bg-tan rounded-3xl text-off-black/70">
      No deals in this stage
    </div>
  )
}

export default function DealStageCard({ stageName, deals }: IProps) {
  return (
    <div className="mb-6 last:mb-0">
      <div className="flex items-center justify-between mb-2">
        <div className="text-steel">{DEAL_STAGE_CONFIG[stageName].name}</div>
        <div>{numeral(deals?.length).format('00')}</div>
      </div>
      {!deals ? (
        <EmptyStage />
      ) : (
        <div className="grid grid-cols-2 gap-2 p-2 bg-tan rounded-3xl">
          {deals.map(deal => (
            <DealCard key={deal.id} deal={deal} />
          ))}
        </div>
      )}
    </div>
  )
}
