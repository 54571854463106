import CheckIcon from 'assets/icons/Check'
import DesktopIcon from 'assets/icons/Desktop'
import MobileIcon from 'assets/icons/Mobile'
import Button from 'components/Button/Button'
import Modal from './Modal'

interface IProps {
  onClose: () => void
}

function FeatureItem({ title }: { title: string }) {
  return (
    <div className="flex items-center px-4 py-3 border-b border-b-steel/15">
      <div>
        <CheckIcon />
      </div>
      <div className="ml-4">{title}</div>
    </div>
  )
}

function AddOnItem({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <div className="px-4 py-3 border-b border-b-steel/15">
      <div className="flex items-center">
        <div className="flex items-center justify-center w-4 h-4 leading-none border rounded-full text-blue-light border-blue-light">
          +
        </div>
        <div className="ml-4">{title}</div>
      </div>
      <div className="ml-8 text-sm text-steel">{subtitle}</div>
    </div>
  )
}

export default function AccountTypesModal({ onClose }: IProps) {
  return (
    <Modal onClose={onClose}>
      <div className="w-[45%] min-w-[648px] py-20">
        <div className="flex items-center justify-between mb-10">
          <h1 className="text-[2rem] font-medium">Symba account types</h1>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h2 className="px-4 mb-4 text-2xl font-medium">
              Individual (Agent)
            </h2>
            <div className="flex items-center px-4 py-3 bg-tan rounded-2xl">
              <MobileIcon />
              <div className="ml-4">Mobile account</div>
            </div>
            <FeatureItem title="Pipeline management" />
            <FeatureItem title="Deal tracking" />
            <FeatureItem title="Automated tasks" />
            <FeatureItem title="Contact organization" />
            <FeatureItem title="Expense tracking" />
            <FeatureItem title="Commissions earnings" />
            <FeatureItem title="Tax form creation" />
            <AddOnItem
              title="Tax filing"
              subtitle="Available to agents as an add on"
            />
            <AddOnItem
              title="LLC filing"
              subtitle="Available to agents as an add on"
            />
          </div>
          <div>
            <h2 className="px-4 mb-4 text-2xl font-medium">Team (Admin)</h2>
            <div className="px-4 bg-tan rounded-2xl">
              <div className="flex items-center py-3 border-b border-b-steel/15">
                <MobileIcon />
                <div className="ml-4">Mobile account</div>
              </div>
              <div className="flex items-center py-3">
                <DesktopIcon />
                <div className="ml-4">Desktop account</div>
              </div>
            </div>
            <FeatureItem title="Everything included with an individual account" />
            <FeatureItem title="Team metrics" />
            <FeatureItem title="Team leaderboards" />
            <FeatureItem title="Access to agent deal data" />
            <FeatureItem title="Account management" />
            <FeatureItem title="Team management" />
            <FeatureItem title="Billing" />
          </div>
        </div>

        <div className="flex justify-end mt-10">
          <Button onClick={onClose} className="w-56">
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  )
}
