import clsx from 'clsx'
import { InputHTMLAttributes, useState } from 'react'
import { FieldValues } from 'react-hook-form'

import CalendarIcon from 'assets/icons/Calendar'
import ReactDatePicker from 'react-date-picker'
import BaseInput, { BaseInputProps } from './BaseInput'

type DateInputProps = Omit<BaseInputProps<FieldValues>, 'render'> &
  InputHTMLAttributes<HTMLInputElement> & { clearable?: boolean }

const DateInput = ({
  control,
  name,
  label,
  required,
  className,
  clearable,
}: DateInputProps) => {
  const [focusing, setFocusing] = useState(false)

  return (
    <BaseInput
      control={control}
      name={name}
      label={label}
      required={required}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const date = value ? new Date(value) : undefined

        const otherProps = {
          calendarIcon: <CalendarIcon />,
          ...(!clearable && { clearIcon: null }),
          onCalendarOpen: () => setFocusing(true),
          onCalendarClose: () => setFocusing(false),
        }

        return (
          <ReactDatePicker
            className={clsx(
              'h-10 px-4 py-2 font-normal border rounded-lg border-steel/35 w-[204px]',
              {
                'shadow-sm': focusing,
              },
              className
            )}
            value={date}
            onChange={(date: any) => {
              if (!date) {
                onChange(null)
              } else {
                onChange(date.getTime())
              }
            }}
            {...otherProps}
          />
        )
      }}
    />
  )
}
export default DateInput
