import { AxiosError } from 'axios'
import { values } from 'lodash'
import { useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions } from 'reducers/team'
import {
  AcceptInviteResponse,
  InviteTeamMemberRequest,
  Team,
  ITeamMember,
  TeamRequest,
  UpdateCommissionRequest,
  UpdateTeamMemberRequest,
  User,
} from 'types'
import * as teamApi from 'utils/api/team'
import * as userApi from 'utils/api/user'
import { setUserColors } from 'utils/team'
import { useAppSelector } from './useAppSelector'

interface IHook {
  team?: Team
  teams: Team[]
  activeUsers: User[]
  members: ITeamMember[]
  initializeTeams: (teams?: Team[]) => Promise<void>
  setActiveTeam: (teamId: string) => Promise<void>
  createTeam: (data: TeamRequest) => Promise<void>
  updateTeam: (data: TeamRequest) => Promise<void>
  manageBilling: () => Promise<void>
  acceptInvite: (
    invitationId: string,
    token: string,
    member?: ITeamMember
  ) => Promise<AcceptInviteResponse>
  updateCommission: (
    agentId: string,
    data: UpdateCommissionRequest
  ) => Promise<void>
  inviteTeamMember: (data: InviteTeamMemberRequest) => Promise<void>
  updateTeamMember: (
    memberId: string,
    data: UpdateTeamMemberRequest
  ) => Promise<void>
  deleteTeamMember: (memberId: string) => Promise<void>
}

export const useTeam = (): IHook => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const activeTeamId = useAppSelector(state => state.teams.activeTeamId)
  const team = useAppSelector(state =>
    state.teams.activeTeamId
      ? state.teams.teams[state.teams.activeTeamId]
      : undefined
  )
  const teams = useAppSelector(state => values(state.teams.teams))
  const activeUsers = useMemo(
    () =>
      team
        ? [
            team.owner,
            ...team.members
              .filter(m => m.invitation.status === 'ACCEPTED')
              .map(m => m.user),
          ]
        : [],
    [team]
  )
  const members = useMemo(
    () =>
      team
        ? [
            {
              _id: 'owner',
              user: team.owner,
              userId: team.owner.id,
              role: 'OWNER',
              invitation: {
                status: 'ACCEPTED',
              },
            } as ITeamMember,
            ...team.members,
          ]
        : [],
    [team]
  )

  const initializeTeams = async (teams: Team[] = []) => {
    dispatch(actions.setTeams(teams.map(setUserColors)))

    const teamId =
      activeTeamId && teams.some(team => team.id === activeTeamId)
        ? activeTeamId
        : teams[0]?.id

    if (teamId) {
      dispatch(actions.setActiveTeamId(teamId))
    }
  }

  const setActiveTeam = async (teamId: string) => {
    dispatch(actions.setActiveTeamId(teamId))
    navigate('/dashboard', { replace: true })
  }

  const createTeam = async (data: TeamRequest) => {
    const res = await teamApi.createTeam(data)
    dispatch(actions.addTeam(res.data))
    dispatch(actions.setActiveTeamId(res.data.id))
  }

  const updateTeam = async (data: TeamRequest) => {
    await teamApi.updateTeam(activeTeamId!, data)
    dispatch(actions.updateTeam({ data }))
  }

  const acceptInvite = async (
    invitationId: string,
    token: string,
    member?: ITeamMember
  ) => {
    const res = await teamApi.acceptInvite(invitationId, token)
    const teamId = res.data.team.id!

    if (res.data.role === 'ADMIN') {
      dispatch(actions.setActiveTeamId(teamId))
    }

    if (member) {
      dispatch(
        actions.updateMember({
          memberId: member._id,
          data: { invitation: { ...member.invitation, status: 'ACCEPTED' } },
          teamId,
        })
      )
    }

    return res.data
  }

  const manageBilling = async () => {
    const res = await teamApi.billing(activeTeamId!)
    window.location.href = res.data.url
  }

  const updateCommission = async (
    agentId: string,
    data: UpdateCommissionRequest
  ) => {
    try {
      await userApi.updateCommission(agentId, data)
      dispatch(
        actions.updateMemberUser({
          userId: agentId,
          data: { commission: data },
        })
      )
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(err.response?.data.message ?? err.message)
      }
    }
  }

  const inviteTeamMember = useCallback(
    async (data: InviteTeamMemberRequest) => {
      const response = await teamApi.inviteTeamMember(activeTeamId!, data)
      dispatch(actions.inviteMember(response.data))
    },
    [activeTeamId] // eslint-disable-line
  )

  const updateTeamMember = useCallback(
    async (memberId: string, data: UpdateTeamMemberRequest) => {
      await teamApi.updateTeamMember(activeTeamId!, memberId, data)
      dispatch(actions.updateMember({ memberId, data }))
    },
    [activeTeamId] // eslint-disable-line
  )

  const deleteTeamMember = useCallback(
    async (memberId: string) => {
      await teamApi.deleteTeamMember(activeTeamId!, memberId)
      dispatch(actions.deleteMember({ memberId }))
    },
    [activeTeamId] // eslint-disable-line
  )

  return {
    team,
    teams,
    activeUsers,
    members,
    initializeTeams,
    setActiveTeam,
    createTeam,
    updateTeam,
    inviteTeamMember,
    updateTeamMember,
    manageBilling,
    acceptInvite,
    updateCommission,
    deleteTeamMember,
  }
}
