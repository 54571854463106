import { useState } from 'react'

import UpgradeSubscriptionIcon from 'assets/icons/UpgradeSubscription'
import Button from 'components/Button/Button'

interface IProps {
  manageBilling: () => void
}

export default function UpgradeSubscription({ manageBilling }: IProps) {
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    manageBilling()
  }

  return (
    <>
      <div className="flex items-center justify-center mb-10">
        <UpgradeSubscriptionIcon />
      </div>
      <div className="text-sm text-steel">You're growing</div>
      <p className="text-[2rem] font-medium leading-8 mb-6">
        Upgrade your subscription to invite more team members.
      </p>
      <p className="mb-10 text-off-black/50">
        Don't miss out on the opportunity to improve your team's collaboration
        and productivity. Upgrade your membership today and start inviting more
        team members to join your account.
      </p>
      <div className="flex justify-end">
        <Button loading={loading} onClick={handleClick} className="w-1/2">
          Manage billing
        </Button>
      </div>
    </>
  )
}
