const Location = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.68645 1.95752C7.14302 1.11119 8.93338 1.12598 10.3763 1.99627C11.805 2.88428 12.6733 4.46912 12.6653 6.17397C12.632 7.86763 11.7009 9.45967 10.537 10.6904C9.86525 11.4039 9.11377 12.0349 8.29793 12.5704C8.2139 12.6189 8.12187 12.6515 8.02636 12.6663C7.93443 12.6624 7.84491 12.6353 7.76587 12.5873C6.52032 11.7827 5.42759 10.7557 4.54025 9.55565C3.79776 8.55392 3.37592 7.34368 3.33203 6.08929C3.33107 4.38117 4.22988 2.80385 5.68645 1.95752ZM6.52799 6.79622C6.773 7.40025 7.35133 7.79426 7.99295 7.79426C8.41328 7.79728 8.81734 7.62891 9.11508 7.32668C9.41283 7.02445 9.57953 6.61345 9.57804 6.18528C9.58029 5.53172 9.19552 4.94124 8.60339 4.68955C8.01126 4.43785 7.32857 4.5746 6.87409 5.03593C6.41961 5.49726 6.28297 6.19218 6.52799 6.79622Z"
      fill="#A2BFB5"
    />
    <ellipse
      opacity="0.4"
      cx="7.9974"
      cy="13.9997"
      rx="3.33333"
      ry="0.666667"
      fill="#A2BFB5"
    />
  </svg>
)

export default Location
