import SettingsSideMenu from 'components/SettingsSideMenu'
import { useAuth, useTeam } from 'hooks'
import { Outlet } from 'react-router-dom'

export default function SettingLayout() {
  const { logOut } = useAuth()
  const { manageBilling } = useTeam()

  return (
    <div className="flex justify-center flex-1 h-full py-10 bg-white border-t px-44 border-t-steel/15">
      <div className="flex items-start space-x-8 w-[1440px]">
        <SettingsSideMenu onLogOut={logOut} onManageBilling={manageBilling} />
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
