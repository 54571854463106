const EditIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path opacity="0.4" d="M9 15.5H15" stroke="#A2BFB5" strokeLinecap="round" />
    <path
      d="M6.49695 3.47139L11.2933 7.34706C11.409 7.43974 11.4288 7.60983 11.3385 7.72832L5.65226 15.1376C5.29481 15.5953 4.76805 15.8542 4.20366 15.8638L1.09953 15.902C0.933974 15.9039 0.789115 15.7892 0.751489 15.6249L0.0460045 12.5576C-0.0762794 11.9938 0.0460045 11.411 0.40345 10.9619L6.11788 3.51726C6.21006 3.39782 6.38031 3.37679 6.49695 3.47139Z"
      fill="#A2BFB5"
    />
    <path
      opacity="0.4"
      d="M13.4399 5.03598L12.5152 6.19027C12.4221 6.3078 12.2547 6.32691 12.139 6.23327C11.0149 5.3236 8.13651 2.98922 7.3379 2.34232C7.22126 2.24676 7.20527 2.07668 7.29933 1.95819L8.19107 0.850724C9.00002 -0.190813 10.411 -0.286367 11.5492 0.621394L12.8567 1.66293C13.3928 2.08337 13.7503 2.63758 13.8726 3.22046C14.0137 3.86162 13.8632 4.49132 13.4399 5.03598Z"
      fill="#A2BFB5"
    />
  </svg>
)

export default EditIcon
